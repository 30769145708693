import {Component, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl, FormArray, Validators} from '@angular/forms';
import {Company} from '../../../issue/issue.interface';
import {startWith, debounceTime} from 'rxjs/operators';
import {AjaxService} from "../../../ajax/ajax.service";
import {UserService} from '../../user.service';
import {User} from '../../user.interface'
import {merge, Observable, Subscription} from 'rxjs';
import { SnackBarService } from '../../../snackbar/snackbar.service';
import { UserRole } from '../../user-role.interface';
import { CompanyRole } from './company-role-selection/company-role.interface';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnChanges {
  private eventSubscription: Subscription;

  @Input() formTitle: string;
  @Input() user: any;
  @Output() onSuccessfulSubmit = new EventEmitter<User>();
  @Output() onUserExistsAlready = new EventEmitter<any>()

  companies: Array<Company>;
  selectableCompanies: Array<Array<Company>> = [];
  selectedCompanyIds: Array<number> = [];
  selectedCompanyRoles: Array<CompanyRole> = [];
  userRoles: Array<UserRole>;
  form: FormGroup;
  passwordControl: FormControl;
  passwordConfirmControl: FormControl;
  isSubmitPending = false;
  isUserActive: boolean;

  get firstName() {
    return this.form.get('firstName') as FormControl;
  }
  get lastName() {
    return this.form.get('lastName') as FormControl;
  }
  get title() {
    return this.form.get('title') as FormControl;
  }
  get phone() {
    return this.form.get('title') as FormControl;
  }
  get mobile() {
    return this.form.get('title') as FormControl;
  }
  get userCompanies() {
    return this.form.get('userCompanies') as FormArray;
  }
  get notes() {
    return this.form.get('title') as FormControl;
  }

  constructor(private ajaxService: AjaxService,
              private userService: UserService,
              private snackBarService: SnackBarService
              ) {}

  ngOnInit() {
    this.initForm();

    this.ajaxService.findCompanies().pipe(debounceTime(400)).subscribe(companies => {
      this.companies = companies;
      this.selectableCompanies[0] = [...companies];
    });

    this.userService.findUserRoles().pipe(debounceTime(400)).subscribe(roles => {
      this.userRoles = roles;
    })
  }

  private initForm() {
    this.form = new FormGroup({
      firstName: new FormControl(null, {validators: Validators.required}),
      lastName: new FormControl(null, {validators: Validators.required}),
      password: new FormControl(null, {validators: Validators.required}),
      email: new FormControl(null, {validators: Validators.required, updateOn: 'change'}),
      extId: new FormControl(null), // Email is copied here
      username: new FormControl(null), // Email is copied here
      title: new FormControl(null),
      //address1: new FormControl(null),
      //address2: new FormControl(null),
      //address3: new FormControl(null),
      //postalCode: new FormControl(null),
      //city: new FormControl(null),
      //state: new FormControl(null),
      //countryCodeId: new FormControl(null),
      phone: new FormControl(null),
      mobile: new FormControl(null),
      userCompanies: new FormArray([
        new FormGroup({
          companyId: new FormControl(null, {validators: Validators.required}),
          roleId: new FormControl(null, {validators: Validators.required})
        })
      ]),
      notes: new FormControl(),
    });

    this.passwordControl = new FormControl(null, {validators: Validators.required});
    this.passwordConfirmControl = new FormControl(null, {validators: Validators.required});

    this.form.controls.email.valueChanges.pipe(
      startWith(null))
      .subscribe(email => {
        // Copy the email to extId and username fields as they are required by the backend.
        this.form.controls.extId.setValue(email);
        this.form.controls.username.setValue(email);
      }
    )
  }

  ngOnChanges() {
    if (this.user) {
      this.form.controls.firstName.setValue(this.user.firstName);
      this.form.controls.lastName.setValue(this.user.lastName);
      this.form.controls.email.setValue(this.user.email);
      this.form.controls.email.disable();
      this.form.controls.email.setValidators(null);
      this.passwordControl.setValue('**********************');
      this.passwordControl.disable();
      this.passwordControl.setValidators(null);
      this.passwordConfirmControl.setValue('**********************');
      this.passwordConfirmControl.disable();
      this.passwordConfirmControl.setValidators(null);
      this.form.controls.title.setValue(this.user.title);
      this.form.controls.phone.setValue(this.user.phone);
      this.form.controls.mobile.setValue(this.user.mobile);
      this.form.controls.notes.setValue(this.user.notes);
      this.isUserActive = this.user.isActive;

      this.form.controls.password.setErrors(null);

      if (this.user.userCompanies && this.userCompanies) {
        this.selectedCompanyRoles = [];
        this.userCompanies.clear();
        for(let i = 0; i < this.user.userCompanies.length; i++) {
          const userCompanyRole: CompanyRole = {
            companyId: this.user.userCompanies[i].company ? this.user.userCompanies[i].company.id : '',
            roleId: this.user.userCompanies[i].roles ? this.user.userCompanies[i].roles[0].id : ''
          }
          this.selectedCompanyRoles.push(userCompanyRole);
          this.selectedCompanyRoles = this.selectedCompanyRoles.slice();
          this.addCompanyRole(userCompanyRole);
        }
      } else {
        this.resetCompanyRoles();
      }
    }
  }

  private resetCompanyRoles() {
    this.selectedCompanyRoles = [];
    this.userCompanies.clear();
    this.userCompanies.push(
      new FormGroup({
        companyId: new FormControl(null, {validators: Validators.required}),
        roleId: new FormControl(null, {validators: Validators.required})
      }));
  }

  toggleUserActivity() {
    this.userService.patchUserActivity(this.user.id, this.isUserActive).subscribe(
        (res: any) => {},
        (err) => this.snackBarService.info("Couldn't change user activation status.", true, 400)
    );
  }

  checkPassword() {
    const password = this.passwordControl.value || "";
    const confirm = this.passwordConfirmControl.value || "";

    if (this.passwordConfirmControl.pristine) return;
    // Password restrictions:
    // 8-30 characters
    // special character: + . - _ # ? ! @ $ % ^
    // lowercase
    // uppercase
    // digit
    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\+\._#?!@$%^&*-]).{8,30}$/.test(password) && password === confirm) {
      this.form.controls["password"].setValue(password);
      this.passwordControl.setErrors(null);
      this.passwordConfirmControl.setErrors(null);
    } else {
      this.form.controls["password"].setValue(null);
      this.passwordControl.setErrors({"invalid": true})
      this.passwordConfirmControl.setErrors({"invalid": true})
    }
  }

  clearForm() {
    this.resetCompanyRoles();
    this.initForm();
    this.isFormValid();
  }

  submitForm() {
    this.isSubmitPending = true;
    if (this.user && this.user?.id) {
      const formValue = this.form.value;
      let params = {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        title: formValue.title,
        phone: formValue.phone,
        mobile: formValue.mobile,
        userCompanies: formValue.userCompanies,
        notes: formValue.notes,
      }
      this.userService.patch(this.user.id, params).subscribe(
        (res: User) => {
          this.clearForm();
          this.onSuccessfulSubmit.emit(res)
        },
        err => {
          console.error(err)
        },
        () => {this.isSubmitPending = false;}
      );
    } else {
      this.userService.create(this.form.value).subscribe(
        (res: User) => {
          this.clearForm();
          this.onSuccessfulSubmit.emit(res)
        },
        err => {
          console.error(err)
          if (err.status === 409) {
            this.onUserExistsAlready.emit(err)
            this.snackBarService.error(err, 'User exists already –')
          }
        },
        () => {this.isSubmitPending = false;}
      );
    }
  }

  isFormValid() {
    return this.form.valid;
  }

  addCompanyRole(companyRole: CompanyRole = null) {
    const userCompanyRole = new FormGroup({
      companyId: new FormControl(companyRole && companyRole.companyId, {validators: Validators.required}),
      roleId: new FormControl(companyRole && companyRole.roleId, {validators: Validators.required}),
    })

    this.userCompanies.push(userCompanyRole);
    this.updateSelectableCompanies();
  }

  removeCompanyRole(index: number) {
    this.userCompanies.removeAt(index);
    this.selectedCompanyRoles.splice(index, 1);
    this.updateSelectableCompanies();
  }

  companyRoleChanged(value: CompanyRole, index: number) {
    this.userCompanies.at(index).patchValue(value)
    this.selectedCompanyRoles[index] = value;
    this.updateSelectableCompanies();
  }

  private updateSelectableCompanies() {
    if(!this.companies) return;
    this.selectedCompanyIds = this.userCompanies.controls.map(c => c.value.companyId).filter(c => c != undefined);
    for(let i = 0; i < this.userCompanies.length; i++) {
      this.selectableCompanies[i] = this.companies.filter((c: Company) => {
        const currentCompanyId = this.userCompanies.controls[i].value.companyId;
        return this.selectedCompanyIds.indexOf(c.id) === -1 || (currentCompanyId && c.id === currentCompanyId);
      })
    }
  }
}
