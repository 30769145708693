import {Component} from "@angular/core";
import {AuthService} from "../modules/auth/auth.service";
import {Router} from "@angular/router";
import {roleType} from "../modules/auth/roles";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private router: Router,
              private authService: AuthService) {
    if (this.authService.isAuthenticated()) {
      if (this.authService.hasAuthority(roleType.CUSTOMER_INTEGRATION)) {
        this.router.navigate(['integration'])
      } else {
        this.router.navigate(['dashboard'])
      }
    }
  }
}
