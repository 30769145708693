
import {from as observableFrom, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, distinctUntilChanged, startWith, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, Input} from "@angular/core";
import {Issue, Company, CountryCode} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormControl} from "@angular/forms";
import {AjaxService} from "../../../ajax/ajax.service";
import {IssueFormComponent} from "../../issue-form.component";
import {AddressService} from "../../../address/address.service";

@Component({
  selector: 'app-issue-receiver-form',
  templateUrl: 'issue-receiver-form.component.html',
  styleUrls: ['issue-receiver-form.component.scss']
})
export class IssueReceiverFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  receivers: Array<Company>;
  filteredReceivers: Array<Company>;
  countries: Array<CountryCode>;
  filteredCountries: Array<CountryCode>;
  form: FormGroup;

  postalControl: FormControl;
  cityControl: FormControl;
  streetControl: FormControl;
  stateControl: FormControl;
  companyControl: FormControl;
  countryControl: FormControl;

  postalCodes: Observable<any>;
  cities: Observable<any>;
  streetAddresses: Observable<any>;
  states: Observable<any>;

  constructor(
    private ajaxService: AjaxService,
    private issueService: IssueService,
    private addressService: AddressService
  ) {
    super();
  }

  ngOnInit() {
    this.ajaxService.findReceivers().subscribe(receivers => {
      this.receivers = [...receivers];
      this.filteredReceivers = [...receivers];
    });
    observableForkJoin([this.ajaxService.findCountries().pipe(map(countries => {
      this.countries = [...countries];
      this.filteredCountries = [...countries];
    }))]).subscribe(() => {});
    this.initForm();
    this.initControls();
  }

  selectAddress(option, fields) {
    this.addressService.selectAddress(option, fields, this.form, this.streetControl, this.cityControl, this.postalControl, this.stateControl, this.countries);
  }

  initForm() {
    let issue = this.issue;
    this.form = new FormGroup({
      companyId: new FormControl({value: issue.receiver && issue.receiver.company && issue.receiver.company.id || null, disabled: this.editDisabled}),
      companyName: new FormControl({value: issue.receiver && issue.receiver.companyName || null, disabled: this.editDisabled}),
      countryCodeId: new FormControl({value: issue.receiver && issue.receiver.countryCode && issue.receiver.countryCode.id || null, disabled: this.editDisabled}),
      streetAddress: new FormControl({value: issue.receiver && issue.receiver.streetAddress || null, disabled: this.editDisabled}),
      postalCode: new FormControl({value: issue.receiver && issue.receiver.postalCode || null, disabled: this.editDisabled}),
      city: new FormControl({value: issue.receiver && issue.receiver.city || null, disabled: this.editDisabled}),
      state: new FormControl({value: issue.receiver && issue.receiver.state || null, disabled: this.editDisabled}),
      notes: new FormControl({value: issue.receiver && issue.receiver.notes || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  initControls() {
    this.streetControl = new FormControl({value: this.form.value.streetAddress, disabled: this.editDisabled}, { updateOn: 'change' });
    this.postalControl = new FormControl({value: this.form.value.postalCode, disabled: this.editDisabled}, { updateOn: 'change' });
    this.cityControl = new FormControl({value: this.form.value.city, disabled: this.editDisabled}, { updateOn: 'change' });
    this.stateControl = new FormControl({value: this.form.value.state, disabled: this.editDisabled}, { updateOn: 'change' });
    this.companyControl = new FormControl({value: this.issue.receiver && this.issue.receiver.company, disabled: this.editDisabled}, { updateOn: 'change' });
    this.countryControl = new FormControl({value: this.issue.receiver && this.issue.receiver.countryCode, disabled: this.editDisabled}, { updateOn: 'change' });

    this.form.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),)
      .subscribe(any => this.update());

    this.streetControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findAddresses(val, this.form, this.countries);
          this.streetAddresses = suggestions;
        }
        else
          this.streetAddresses = observableFrom([]);
      });

    this.postalControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findPostals(val, this.form, this.countries);
          this.postalCodes = suggestions;
        }
        else
          this.postalCodes = observableFrom([]);
      });

    this.cityControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findCities(val, this.form, this.countries);
          this.cities = suggestions;
        }
        else
          this.cities = observableFrom([]);
      });

    this.stateControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findStates(val, this.form, this.countries);
          this.states = suggestions;
        }
        else
          this.states = observableFrom([]);
      });

      this.companyControl.valueChanges.pipe(
        startWith(null))
        .subscribe((val) => {
          let searchTerm = "";
          if(!!val && val.name) {
            searchTerm = val.name;
          } else if(!!val) {
            searchTerm = val.toString();
          }
          if(this.receivers) {
            this.filteredReceivers = this.receivers.filter(c => {
              let companyName = "";
              if(!!c.name) {
                if(!!c.code) {
                  companyName += `(${c.code}) ${c.name}`;
                }
                companyName += c.name.toString();
              }
              return companyName.toLowerCase().includes(searchTerm.toLowerCase());
            });
          }
        });

      this.countryControl.valueChanges.pipe(
        startWith(null))
        .subscribe((val) => {
          let searchTerm = "";
          if(!!val && val.name) {
            searchTerm = val.name;
          } else if(!!val) {
            searchTerm = val.toString();
          }
          if(this.countries) {
            this.filteredCountries = this.countries.filter(c => {
              const countryName = c.code + " " + c.name;;
              return countryName.toLowerCase().includes(searchTerm.toLowerCase());
            });
          }
        })
  }

  copy() {
    let company = this.receivers.filter(any => any.id == this.form.value.companyId)[0];
    this.form.setValue({
      companyId: company && company.id || null,
      companyName: company && company.name || null,
      countryCodeId: company && company.countryCode && company.countryCode.id || null,
      streetAddress: company && company.streetAddress || null,
      postalCode: company && company.postalCode || null,
      city: company && company.city || null,
      state: company && company.state || null,
      notes: company && company.notes || null
    }, {emitEvent: true});
    this.streetControl.setValue(this.form.value.streetAddress, {emitEvent: false});
    this.cityControl.setValue(this.form.value.city, {emitEvent: false});
    this.postalControl.setValue(this.form.value.postalCode, {emitEvent: false});
    this.stateControl.setValue(this.form.value.state, {emitEvent: false});
    this.companyControl.setValue(company, {emitEvent: false});
    this.countryControl.setValue(company && company.countryCode && company.countryCode, {emitEvent: false});
  }

  update() {
    if (isNaN(this.form.value.companyId)) {
      this.form.value.companyId = null;
    }
    this.issueService.patch(this.issue.referenceNo, {receiver: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
    this.initControls();
  }

  selectCompany(company: Company): void {
    this.form.patchValue({companyId: company.id});
    this.companyControl.setValue(company);
  }

  displayReceiver(company: Company): string {
    if(!company) return "";
    return !!company.code ? `${company.name} (${company.code})` : company.name;
  }

  selectCountry(country: CountryCode): void {
    this.form.patchValue({countryCodeId: country.id});
    this.countryControl.setValue(country);
  }

  displayCountry(country: CountryCode): string {
    if (!country) return "";
    return country.code + " " + country.name;
  }

  checkCountryField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.countryControl.value) {
      this.form.patchValue({countryCodeId: null});
      this.countryControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedCountry = this.countries.find(c => c.id === this.form.controls["countryCodeId"].value);
      this.countryControl.setValue(selectedCountry);
    }
  }

  checkCompanyField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.companyControl.value) {
      this.form.patchValue({companyId: null});
      this.companyControl.setValue(null);
    } else {
      // Set the input field text to match the selected company
      const selectedCompany = this.receivers.find(r => r.id === this.form.controls["companyId"].value);
      this.companyControl.setValue(selectedCompany);
    }
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }
}
