import {Injectable} from "@angular/core";
import {GPlaces} from "../gplaces/gplaces";
import {SnackBarService} from "../snackbar/snackbar.service";
import {Observable} from "rxjs";

@Injectable()
export class AddressService {

  constructor(private gplaces: GPlaces,
              private snackbar: SnackBarService) {
  }

  public findAddresses(val, form, countries) {
    return this.gplaces.autoComplete(val, ['address'], [this.resolveCountryCode(form, countries)])
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        this.snackbar.info('Location query failed, daily quota limit exceeded!');
        return [];
      });
  }

  public findPostals(val, form, countries) {
    return this.gplaces.autoComplete(val, ['(regions)'], [this.resolveCountryCode(form, countries)]);
  }

  public findCities(val, form, countries) {
    return this.gplaces.autoComplete(val, ['(cities)'], [this.resolveCountryCode(form, countries)]);
  }

  public findStates(val, form, countries) {
    return this.gplaces.autoComplete(val, ['(regions)'], [this.resolveCountryCode(form, countries)]);
  }

  public selectAddress(option, fields, form, streetControl?, cityControl?, postalControl?, stateControl?, countries?: Array<any> | Observable<any>) {
    this.gplaces.details(option.place_id)
      .subscribe((result) => {
        if (fields.includes('streetAddress') && streetControl) {
          let streetAddress = this.parseComponent(result.address_components, 'route');
          let streetNumber = this.parseComponent(result.address_components, 'street_number');
          form.patchValue({streetAddress: (streetAddress ? streetAddress : '') + (streetAddress ? ' ' : '') + (streetNumber ? streetNumber : '')});
          streetControl.setValue(form.value.streetAddress);
        }

        if (fields.includes('city') && cityControl) {
          let city = this.parseComponent(result.address_components, 'locality');
          let cityAlt = this.parseComponent(result.address_components, 'postal_town');
          let cityAlt2 = this.parseComponent(result.address_components, 'administrative_area_level_3');
          let cityAlt3 = this.parseComponent(result.address_components, 'administrative_area_level_2', true);
          form.patchValue({city: city || cityAlt || cityAlt2 || cityAlt3});
          cityControl.setValue(form.value.city);
        }

        if (fields.includes('postalCode') && postalControl) {
          let postalCode = this.parseComponent(result.address_components, 'postal_code');
          form.patchValue({postalCode: postalCode});
          postalControl.setValue(form.value.postalCode);
        }

        if (fields.includes('state') && stateControl) {
          let state = this.parseComponent(result.address_components, 'administrative_area_level_1');
          form.patchValue({state: state});
          stateControl.setValue(form.value.state);
        }

        if (fields.includes('country') && countries) {
          let country = this.parseComponent(result.address_components, 'country', true);

          if (country && countries instanceof Observable) {
            countries.subscribe(cs => {
              this.updateCountryCode(form, country, cs);
            });
          } else if (country && Array.isArray(countries)) {
            this.updateCountryCode(form, country, countries);
          }
        }
      }, (err) => {
        console.log(err);
      });
  }

  private parseComponent(address, component, short = false): string {
    if (address && address.length) {
      let match = address.find((i) => {
        return i.types.includes(component);
      });
      if (match) {
        return short ? match.short_name : match.long_name;
      }
    }
    return undefined;
  }

  private resolveCountryCode(form, countries): string {
    let country = countries.find((c) => {
      return c.id === form.value.countryCodeId;
    });
    let countryCode = country ? country.code : null;
    return countryCode ? countryCode.toLowerCase() : null;
  }

  private updateCountryCode(form, country, countries) {
    let countryCode = countries.find((c) => {
      return c.code === country;
    });
    if (countryCode) {
      form.patchValue({countryCodeId: countryCode.id});
    }
  }
}
