<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="column">

      <input type="hidden" formControlName="id"/>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="pickupId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of pickups; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Pickup place</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="deliveryId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of deliveries; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Delivery place</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <input type="text"
            placeholder="Customs number"
            matInput
            [formControl]="customsNumberControl"
            autocomplete="off"
            (blur)="delayedBlur(checkCustomsNumberField.bind(this), 300)"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomsNumber.bind(this)" (optionSelected)="selectCustomsNumber($event.option.value)">
            <mat-option *ngFor="let option of filteredCustomsNumbers; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="50px">
          <mat-placeholder i18n>Line</mat-placeholder>
          <input matInput type="text" formControlName="line">
        </mat-form-field>

        <div>
          <mat-form-field fxFlex="250px">
            <mat-placeholder i18n>Product Description</mat-placeholder>
            <input type="text" matInput [formControl]="descriptionControl" [matAutocomplete]="description" (blur)="updateDescription()">
          </mat-form-field>
          <mat-autocomplete #description="matAutocomplete">
            <mat-option *ngFor="let option of descriptions | async; trackBy: trackById" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>

        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>Item number</mat-placeholder>
          <input matInput type="text" formControlName="itemNumber">
        </mat-form-field>
      </span>

      <strong i18n>Size and weight</strong>

      <span fxLayout="row" fxLayoutGap="20px">

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.length"
          (valueChange)="form.patchValue({ length: $event })"
          placeholder="Length"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.width"
          (valueChange)="form.patchValue({ width: $event })"
          placeholder="Width"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.height"
          (valueChange)="form.patchValue({ height: $event })"
          placeholder="Height"
          [disabled]="editDisabled">
        </decimalinput>

        <mat-form-field fxFlex="130px">
          <mat-select formControlName="lengthMeasureId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of lengthMeasures; trackBy: trackById" [value]="option.id">{{option.unit}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Length measure</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <decimalinput
          fxFlex="150px"
          [(value)]="form.value.unitWeight"
          (valueChange)="form.patchValue({ unitWeight: $event })"
          placeholder="Weight per unit"
          [disabled]="editDisabled">
        </decimalinput>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="massMeasureId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of massMeasures; trackBy: trackById" [value]="option.id">{{option.unit}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Mass measure</mat-placeholder>
        </mat-form-field>

      </span>

      <strong i18n>Amount and value</strong>

      <span fxLayout="row" fxLayoutGap="20px">

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.amount"
          (valueChange)="form.patchValue({ amount: $event })"
          placeholder="Amount"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.unitValue"
          (valueChange)="form.patchValue({ unitValue: $event })"
          placeholder="Unit value"
          [disabled]="editDisabled">
        </decimalinput>

        <mat-form-field fxFlex="80px">
          <mat-select formControlName="unitValueCurrencyId">
            <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id">{{option.code}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Currency</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="100px">
          <input matInput type="text" [value]="form.value.totalValue ? (form.value.totalValue | decimal:2) : null" disabled/>
          <mat-placeholder i18n>Total value</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="80px">
          <mat-select formControlName="totalValueCurrencyId">
            <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id">{{option.code}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Currency</mat-placeholder>
        </mat-form-field>

      </span>

    </mat-card-content>
  </mat-card>
</form>
