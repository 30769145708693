
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from "@angular/core";
import {Issue, IssueUpload} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import {saveAs} from "file-saver";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {IssueFormComponent} from "../../issue-form.component";
import {AuthService} from "../../../../modules/auth/auth.service";
import {AuthGuardService} from "../../../../modules/auth/auth-guard.service";
import {superRoles} from "../../../../modules/auth/roles";

@Component({
  selector: 'app-issue-uploads-form',
  templateUrl: 'issue-uploads-form.component.html',
  styleUrls: ['issue-uploads-form.component.scss']
})
export class IssueUploadsFormComponent extends IssueFormComponent implements OnInit {
  @Input() allowDelete: boolean;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @ViewChild('fileInput') fileInput: ElementRef;
  form: FormGroup;
  superRoles = superRoles;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private issueService: IssueService,
              private authService: AuthService,
              private authGuard: AuthGuardService,
              private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.issueService.onUpdated.subscribe((issue: Issue) => {
      this.issue = Object.assign(this.issue, issue);
      this.initForm();
    });
  }

  initForm() {
    this.form = new FormGroup({
      uploads: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });
    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms(issue) {
    let subForms = [];
    if (issue.uploads && issue.uploads.length > 0) {
      issue.uploads.forEach(upload => subForms.push(this.initSubForm(upload)));
    } else {
      //subForms.push(this.initSubForm(<IssueUpload>{}));
    }
    return subForms;
  }

  initSubForm(upload: IssueUpload) {
    return new FormGroup({
      id: new FormControl(upload && upload.id || null),
      filename: new FormControl(upload && upload.filename || null),
      fileType: new FormControl(upload && upload.fileType || null),
      filePath: new FormControl(upload && upload.filePath || null),
      description: new FormControl(upload && upload.description || null)
    }, { updateOn: 'blur' });
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['uploads'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  upload(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.issueService.upload(this.issue.referenceNo, 'UPLOADS', file).subscribe((issue: Issue) => {
        this.issue = Object.assign(this.issue, issue);
        this.initForm();
        this.fileInput.nativeElement.value = '';
        this.changeEmitter.emit(issue);
      });
    }
  }

  download(filename: string, fileType: string, filePath: string) {
    this.issueService.download(this.issue.referenceNo, filename, fileType, filePath).subscribe((file: File) => {
      saveAs(file, filename);
    });
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      this.changeEmitter.emit(issue)
    });
  }

  refreshStatus(issue) {
    if (!issue.uploads) {
      issue.uploads = [];
    }
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  isOwner(id) {
    return this.issue && this.issue.uploads && this.issue.uploads.filter(upload => upload.id == id && upload.createdBy.email == this.authService.userEmail()).length > 0;
  }

  trackById = (index, item) => {
    return item.id;
  }
}
