import {Injectable} from "@angular/core";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import {isNumeric} from "rxjs/internal-compatibility";

@Injectable()
export class Formatter {

  public placeholderChar: string;

  public statisticalPeriod: {pipe?: any, mask?: any};

  public date: {pipe?: any, mask?: any};

  public time: {pipe?: any, mask?: any};

  public decimal: {pipe?: any, mask?: any};

  public integer: {pipe?: any, mask?: any};

  public constructor() {
    this.placeholderChar = '\u2000';

    this.statisticalPeriod = {
      pipe: createAutoCorrectedDatePipe('yyyy-mm'),
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]
    };

    this.date = {
        pipe: createAutoCorrectedDatePipe('dd.mm.yyyy'),
        mask: [/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    };

    this.time = {
        pipe: createAutoCorrectedDatePipe('HH:MM'),
        mask: [/[0-2]/, /[0-9]/, ':', /[0-6]/, /[0-9]/]
    };

    this.decimal = {
      mask: createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: '',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: null,
        requireDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: true
      })
    };

    this.integer = {
      mask: createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: '',
        allowDecimal: false,
        decimalSymbol: '',
        decimalLimit: 0,
        integerLimit: null,
        requireDecimal: false,
        allowNegative: true,
        allowLeadingZeroes: false
      })
    };
  }
}
