import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input} from "@angular/core";
import {
  Issue,
  Currency,
  CustomsNumber,
  IssueProduct,
  Measure,
  IntrastatTransactionNature,
  IntrastatTransportMean,
  IntrastatSupplementaryUnit,
  CountryCode,
  PaymentTerm,
  UnClassification
} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormArray, Validators, FormControl} from "@angular/forms";
import * as _ from "lodash";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-products-form',
  templateUrl: 'issue-products-form.component.html',
  styleUrls: ['issue-products-form.component.scss']
})
export class IssueProductsFormComponent extends IssueFormComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  customsNumbers: Array<CustomsNumber>;
  lengthMeasures: Array<Measure>;
  massMeasures: Array<Measure>;
  countries: Array<CountryCode>;
  currencies: Array<Currency>;
  transactionNatures: Array<IntrastatTransactionNature>;
  transportMeans: Array<IntrastatTransportMean>;
  supplementaryUnits: Array<IntrastatSupplementaryUnit>;
  unClassifications: Array<UnClassification>;
  paymentTerms: Array<PaymentTerm>;
  form: FormGroup;

  totalValue: number;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private ajaxService: AjaxService, private issueService: IssueService, private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.calculate();

    this.form = new FormGroup({
      products: new FormArray(this.initSubForms(null))
    }, { updateOn: 'blur' });

    observableForkJoin([
      this.ajaxService.findCustomsNumbers().pipe(map((customsNumbers: Array<CustomsNumber>) => this.customsNumbers = customsNumbers)),
      this.ajaxService.findMeasures('length').pipe(map((measures: Array<Measure>) => this.lengthMeasures = measures)),
      this.ajaxService.findMeasures('mass').pipe(map((measures: Array<Measure>) => this.massMeasures = measures)),
      this.ajaxService.findCountries().pipe(map((countries: Array<CountryCode>) => this.countries = countries)),
      this.ajaxService.findCurrencies().pipe(map((currencies: Array<Currency>) => this.currencies = currencies)),
      this.ajaxService.findIntrastatTransactionNatures().pipe(map((transactionNatures: Array<IntrastatTransactionNature>) => this.transactionNatures = transactionNatures)),
      this.ajaxService.findIntrastatTransportMeans().pipe(map((transportMeans: Array<IntrastatTransportMean>) => this.transportMeans = transportMeans)),
      this.ajaxService.findIntrastatSupplementaryUnits().pipe(map((supplementaryUnits: Array<IntrastatSupplementaryUnit>) => this.supplementaryUnits = supplementaryUnits)),
      this.ajaxService.findUnClassifications().pipe(map((unClassifications: Array<UnClassification>) => this.unClassifications = unClassifications)),
      this.ajaxService.findPaymentTerms().pipe(map((paymentTerms: Array<PaymentTerm>) => this.paymentTerms = paymentTerms))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  initForm() {
    this.form = new FormGroup({
      products: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms(issue: Issue) {
    let subForms = [];
    if (issue) {
      if (issue.products && issue.products.length > 0) {
        issue.products.forEach(product => subForms.push(this.initSubForm(product)));
      } else {
        subForms.push(this.initSubForm());
      }
    }
    return subForms;
  }

  initSubForm(product?: IssueProduct) {
    let intrastat = product && product.intrastat;
    let specialRequirement = product && product.specialRequirement;

    let subForm = new FormGroup({
      isOpen: new FormControl({value: product ? false : true, disabled: this.editDisabled}),
      id: new FormControl({value: product && product.id || null, disabled: this.editDisabled}),
      pickupId: new FormControl({value: product && product.pickup && product.pickup.id || this.issue.pickups && this.issue.pickups[0].id || null, disabled: this.editDisabled}),
      deliveryId: new FormControl({value: product && product.delivery && product.delivery.id || this.issue.deliveries && this.issue.deliveries[0].id || null, disabled: this.editDisabled}),
      line: new FormControl({value: product && product.line || null, disabled: this.editDisabled}),
      description: new FormControl({value: product && product.description || null, disabled: this.editDisabled}),
      customsNumberId: new FormControl({value: product && product.customsNumber && product.customsNumber.id || null, disabled: this.editDisabled}),
      itemNumber: new FormControl({value: product && product.itemNumber || null, disabled: this.editDisabled}),
      length: new FormControl({value: product && product.length || null, disabled: this.editDisabled}),
      width: new FormControl({value: product && product.width || null, disabled: this.editDisabled}),
      height: new FormControl({value: product && product.height || null, disabled: this.editDisabled}),
      lengthMeasureId: new FormControl({value: product && product.lengthMeasure && product.lengthMeasure.id || this.ajaxService.defaultLengthUnitId(this.lengthMeasures) || null, disabled: this.editDisabled}),
      unitWeight: new FormControl({value: product && product.unitWeight || null, disabled: this.editDisabled}),
      massMeasureId: new FormControl({value: product && product.massMeasure && product.massMeasure.id || this.ajaxService.defaultMassUnitId(this.massMeasures) || null, disabled: this.editDisabled}),
      amount: new FormControl({value: product && product.amount || 1, disabled: this.editDisabled}),
      unitValue: new FormControl({value: product && product.unitValue || null, disabled: this.editDisabled}),
      unitValueCurrencyId: new FormControl({value: product && product.unitValueCurrency && product.unitValueCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: this.editDisabled}),
      totalValue: new FormControl({value: product && product.totalValue || null, disabled: this.editDisabled}),
      totalValueCurrencyId: new FormControl({value: product && product.totalValueCurrency && product.totalValueCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: true}),
      intrastat: new FormGroup({
        isOpen: new FormControl({value: true, disabled: this.editDisabled}),
        id: new FormControl({value: intrastat && intrastat.id || null, disabled: this.editDisabled}),
        statisticalPeriod: new FormControl({value: intrastat && intrastat.statisticalPeriod || null, disabled: this.editDisabled}, { validators: Validators.pattern('[0-9]{4}-((0[1-9])|(1[012]))') }),
        commodityCode: new FormControl({value: intrastat && intrastat.commodityCode && intrastat.commodityCode.code || product && product.customsNumber && product.customsNumber.code || null, disabled: this.editDisabled}),
        transactionNatureId: new FormControl({value: intrastat && intrastat.transactionNature && intrastat.transactionNature.id || null, disabled: this.editDisabled}),
        consignmentCountryCodeId: new FormControl({value: intrastat && intrastat.consignmentCountry && intrastat.consignmentCountry.id || null, disabled: this.editDisabled}),
        originCountryCodeId: new FormControl({value: intrastat && intrastat.originCountry && intrastat.originCountry.id || null, disabled: this.editDisabled}),
        transportMeanId: new FormControl({value: intrastat && intrastat.transportMean && intrastat.transportMean.id || null, disabled: this.editDisabled}),
        netMass: new FormControl({value: intrastat && intrastat.netMass || null, disabled: this.editDisabled}),
        supplementaryQuantity: new FormControl({value: intrastat && intrastat.supplementaryQuantity || null, disabled: this.editDisabled}),
        supplementaryUnitId: new FormControl({value: intrastat && intrastat.supplementaryUnit && intrastat.supplementaryUnit.id || product && product.customsNumber && product.customsNumber.supplementaryUnit && product.customsNumber.supplementaryUnit.id || null, disabled: this.editDisabled}),
        invoiceValue: new FormControl({value: intrastat && intrastat.invoiceValue || null, disabled: this.editDisabled}),
        statisticalValue: new FormControl({value: intrastat && intrastat.statisticalValue || null, disabled: this.editDisabled}),
        reference: new FormControl({value: intrastat && intrastat.reference || null, disabled: this.editDisabled})
      }, { updateOn: 'blur' }),
      specialRequirement: new FormGroup({
        isOpen: new FormControl({value: true, disabled: this.editDisabled}),
        id: new FormControl({value: specialRequirement && specialRequirement.id || null, disabled: this.editDisabled}),
        dangerousGoods: new FormControl({value: specialRequirement && specialRequirement.dangerousGoods || null, disabled: this.editDisabled}),
        unClassificationId: new FormControl({value: specialRequirement && specialRequirement.unClassification && specialRequirement.unClassification.id || null, disabled: this.editDisabled}),
        adrNumber: new FormControl({value: specialRequirement && specialRequirement.adrNumber || null, disabled: this.editDisabled}),
        packingGroup: new FormControl({value: specialRequirement && specialRequirement.packingGroup || null, disabled: this.editDisabled}),
        isProtectFromMoisture: new FormControl({value: specialRequirement && specialRequirement.isProtectFromMoisture || null, disabled: this.editDisabled}),
        temperatureMin: new FormControl({value: specialRequirement && specialRequirement.temperatureMin || null, disabled: this.editDisabled}),
        temperatureMax: new FormControl({value: specialRequirement && specialRequirement.temperatureMax || null, disabled: this.editDisabled}),
        paymentTermId: new FormControl({value: specialRequirement && specialRequirement.paymentTerm && specialRequirement.paymentTerm.id || null, disabled: this.editDisabled}),
        other: new FormControl({value: specialRequirement && specialRequirement.other || null, disabled: this.editDisabled})
      }, { updateOn: 'blur' })
    }, { updateOn: 'blur' });
    // Intrastat commodity code is disabled since it is a copy of customs number handled by the API
    const subControl = <FormGroup>subForm.controls['intrastat'];
    subControl.controls['commodityCode'].disable();
    return subForm;
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['products'];
    control.push(this.initSubForm(null));
    setTimeout(() => {
      this.expansionPanels.last.open();
    });
  }

  toggleIsOpen(formGroup: FormGroup) {
    formGroup.patchValue({isOpen: !formGroup.value.isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['products'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    let form = this.form.getRawValue();
    this.issueService.patch(this.issue.referenceNo, form).subscribe((issue: Issue) => {
      const control = <FormArray>this.form.controls['products'];
      control.controls.forEach((subForm: FormGroup, index: number) => {
        let product = issue.products[index];

        subForm.patchValue({id: product.id || null}, {emitEvent: false});

        // Reflect the change on the product totalValue and totalValueCurrency
        subForm.patchValue({totalValue: product.totalValue || null}, {emitEvent: false});
        subForm.patchValue({totalValueCurrencyId: product.totalValueCurrency && product.totalValueCurrency.id || null}, {emitEvent: false});

        // Reflect the change on the product customs number to the intrastat commodity code and product total value to invoice value and to statistical value
        const subControl = <FormGroup>subForm.controls['intrastat'];
        subControl.patchValue({commodityCode: product && product.intrastat && product.intrastat.commodityCode && product.intrastat.commodityCode.code || product && product.customsNumber && product.customsNumber.code || null}, {emitEvent: false});
        subControl.patchValue({invoiceValue: product && product.intrastat && product.intrastat.invoiceValue || null}, {emitEvent: false});
        subControl.patchValue({statisticalValue: product && product.intrastat && product.intrastat.statisticalValue || null}, {emitEvent: false});
      });
      this.changeEmitter.emit(issue);
      this.issue.products = issue.products;
      this.calculate();
    });
  }

  findPropertyById(collection: any, id: number, property: string) {
    let object = _.find(collection, {id: Number(id)});
    return object && object.hasOwnProperty(property) ? object[property] : null;
  }

  calculate() {
    if (this.issue.products) {
      this.totalValue = this.issue.products.filter(item => item && item.totalValue).map(item => Number(item.totalValue)).reduce(this.reducer, 0);
    }
  }

  reducer(accumulator, currentValue) {
    return accumulator + currentValue;
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  defaultStatisticalPeriod() : string {
    let today = new Date();
    let yearPart = today.getFullYear();
    let monthPart = today.getMonth() < 9 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
    return `${yearPart}-${monthPart}`;
  }

  trackById = (index, item) => {
    return item.id;
  }
}
