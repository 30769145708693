
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class SnackBarService {

  private snackBarRef: any;

  constructor(private snackBar: MatSnackBar) {
  }

  public error(error: any, msg: string = 'API is not responding: try refreshing the page') {
    // handle blob API errors
    if (error && error._body && error._body instanceof Blob) {
      this.errorBlob(error, msg, this.openError.bind(this));
    }
    else {
      try {
        // handle json API errors
        this.openError(error.json(), msg);
      }
      catch (e) {
        // handle other errors like gateway timeout / proxy etc.
        this.openError(error, msg);
      }
    }
    return observableThrowError(error);
  }

  public info(msg: string, isForce: boolean = false, duration: number = 5000): any {
    // Don't redisplay the note if it's already visible
    if (!isForce && this.snackBarRef &&
      this.snackBarRef.containerInstance &&
      this.snackBarRef.containerInstance._elementRef &&
      this.snackBarRef.containerInstance._elementRef.nativeElement &&
      this.snackBarRef.containerInstance._elementRef.nativeElement.offsetParent !== null &&
      this.snackBarRef.instance &&
      this.snackBarRef.instance.data &&
      this.snackBarRef.instance.data.message === msg) {
      return;
    }

    setTimeout(() => {
      this.snackBarRef = this.snackBar.open(msg, 'X', {duration: duration});
    });
  }

  private errorBlob(error: any, msg: string, callback: Function) {
    let fileReader = new FileReader();
    fileReader.onload = function (e) {
      error._body = JSON.parse(e.target['result'] as string);
      error.message = error._body.message;
      callback(error, msg);
    };
    fileReader.readAsText(error._body);
  }

  private openError(error: any, msg: string, duration: number = 5000) {
    let err = (error && error.error && error.error.error_description) || (error && error.statusText) || 'Server error';
    msg = msg || (error && error.error && error.error.message) || (error && error.message && error._body);
    let plain = msg;
    if (msg) {
      plain = plain.concat(': ', err);
    }
    setTimeout(() => {
      this.snackBarRef = this.snackBar.open(plain, 'X', {duration: duration});
    });
  }
}
