
import {tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {SnackBarService} from "../../snackbar/snackbar.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "../../../modules/auth/auth.service";
import * as Sentry from "@sentry/browser";

@Injectable()
export class MeService {

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, public snackBarService: SnackBarService) {
  }

  // TODO: change any to Me interface
  getMe(): Observable<any> {
    return this.http.get('/api/me').pipe(
      tap(data => {
        if (!data || data == "") {
          this.snackBarService.error("User details unavailable!");
          this.authService.logout();
        } else {
          Sentry.setUser({
            name: data['name'],
            email: data['email']
          })
        }
      }));
  }

  getMeCompanies(): Observable<any> {
    return this.http.get('/api/me/portal-companies');
  }

  activate(userCompanyId: string): Observable<any> {
    return this.http.put('/api/me/activate', userCompanyId);
  }
}
