import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import * as Dropzone from "dropzone";
import {SnackBarService} from "../snackbar/snackbar.service";

@Component({
  selector: 's3-upload',
  templateUrl: './s3-upload.component.html',
  styleUrls: ['./s3-upload.component.scss']
})
export class S3UploadComponent implements OnInit {

  @ViewChild("dropzone", { static: true }) dropzone: Dropzone;

  @Input() signUrl: string;
  @Input() bucket: string;
  @Input() contentType: string;

  @Output() fileAccepted: EventEmitter<string> = new EventEmitter();

  constructor(private http: HttpClient,
              private snackbar: SnackBarService) { }

  ngOnInit() {
    setTimeout(() => {
      this.dropzone = new Dropzone('form.dropzone', {
        url: window.location.href, // dropzone requires some url, actual upload url is set in accept handler
        accept: this.onFileAccept.bind(this),
        acceptedFiles: 'application/json,application/xml,text/xml',
        sending: S3UploadComponent.onFileSend
      });

      this.dropzone.on('processing', (file) => {
        this.dropzone.options.url = file.uploadUrl
      });
    });
  }

  /**
   * Clear all completed (processed or failed) thumbnails from dropzone
   */
  public clearCompletedFiles() {
    this.dropzone.removeAllFiles();
  }

  /**
   * When file is dropped to dropzone get post policy for file/bucket and save results to file object.
   */
  private onFileAccept(file, callback) {

    if ((this.contentType === 'application/json' && file.type !== 'application/json') ||
         this.contentType === 'application/xml' && file.type !== 'application/xml' && file.type !== 'text/xml') {
      this.snackbar.info( `File content of type '${file.type}' invalid for '${this.contentType}'`);
      callback("Invalid content type"); // dropzone error
      return;
    }

    // Allowing special characters would require effort on all systems participating with the s3 object.
    // Easier to sanitize file name. Whitelist allows: 0-9,a-z,A-Z,_,.
    const objectKey = file.name.replace(/[^\w.]/gi, '');

    this.http.get(`${this.signUrl}?key=${objectKey}&bucket=${this.bucket}`, {
      observe: 'response',
      headers: new HttpHeaders({
        'Authorization': 'Bearer:' + localStorage.getItem('LHP.access_token')
      })
    }).subscribe(data => {

      file.uploadUrl = data.body['url'];
      file.policy = data.body['fields'];
      file.contentType = this.contentType;
      this.fileAccepted.emit(file.name);
      callback(); // dropzone success

    }, err => {
      this.snackbar.info( `File upload failed for ${file.name}`);
      console.error(err);
      callback("Upload error");
    });
  }

  /**
   * Before object upload set all post policy fields as form data. Adds additional s3 object meta fields
   * like current identity.
   */
  private static onFileSend(file, xhr, formData) {
    for (const formField in file.policy) {
      // noinspection JSUnfilteredForInLoop
      formData.append(formField, file.policy[formField]);
    }
    formData.append('Content-Type', file.contentType);
    formData.append('x-amz-meta-identity', localStorage.getItem('LHP.access_token'));
  }
}
