<div fxLayout="column" *ngIf="!isMenu && issue?.type == 'INDICATION_BOOKING'">
  <div fxLayout="row" fxLayoutGap="20px">
    <button mat-raised-button color="accent" (click)="showSpotPriceInquiryDialog()">
      <span i18n>SPOT INQUIRY</span>
    </button>

    <button mat-raised-button color="accent" (click)="showTransportBookingDialog()">
      <span i18n>TRANSPORT BOOKING</span>
    </button>
  </div>
</div>

<ng-container *ngIf="isMenu && issue?.type == 'INDICATION_BOOKING'">
  <button mat-icon-button [matMenuTriggerFor]="printMenu">
    <mat-icon color="accent">print</mat-icon>
  </button>

  <mat-menu #printMenu="matMenu">
    <button mat-menu-item (click)="showSpotPriceInquiryDialog()">SPOT inquiry</button>
    <button mat-menu-item (click)="showTransportBookingDialog()">Transport booking</button>
  </mat-menu>
</ng-container>
