<form *ngIf="issue" novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Uploaded files ({{form.controls.uploads.length}})</h2>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" formArrayName="uploads" fxLayoutGap="10px">
        <div fxLayout="column" class="issue-upload" *ngFor="let subForm of form.controls.uploads.controls; let i = index; trackBy: trackById">
          <div fxLayout="row">
            <button mat-button color="accent" (click)="download(subForm.value.filename, subForm.value.fileType, subForm.value.filePath)">
              <mat-icon>attachment</mat-icon>
              <span>{{subForm.value.filename}} ({{subForm.value.fileType}})</span>
            </button>
            <button *ngIf="authGuard.hasAnyAuthority(superRoles)" mat-icon-button (click)="removeSubForm(i)" class="issue-upload-delete">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
          <div fxLayout="row">
            <app-issue-upload-form style="margin: 0 20px;" fxFlex="100" [form]="subForm"></app-issue-upload-form>
          </div>
        </div>

        <div fxLayout="row">
          <input #fileInput type="file" style="display: none;" (change)="upload($event)"/>
          <button mat-button color="accent" (click)="fileInput.click()">Upload new file</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
