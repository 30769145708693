
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {Issue} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormControl, FormGroup} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-reference-form',
  templateUrl: 'issue-reference-form.component.html',
  styleUrls: ['issue-reference-form.component.scss']
})
export class IssueReferenceFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() inner: boolean;
  @Input() editDisabled: boolean;
  form: FormGroup;

  constructor(private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      customerReference: new FormControl({value: this.issue.reference && this.issue.reference.customerReference || null, disabled: this.editDisabled}),
      projectNumber: new FormControl({value: this.issue.reference && this.issue.reference.projectNumber || null, disabled: this.editDisabled}),
      pickupReference: new FormControl({value: this.issue.reference && this.issue.reference.pickupReference || null, disabled: this.editDisabled}),
      otherReference: new FormControl({value: this.issue.reference && this.issue.reference.otherReference || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {reference: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }
}
