<mat-card class="app-card inner">
  <mat-card-header fxLayout="column" *ngIf="!isAdd && (!issue?.links || issue?.links?.length === 0)">
    <small><a (click)="toggleAdd()">Link this issue to another issue</a></small>
  </mat-card-header>

  <mat-card-header fxLayout="column" fxLayoutGap="10px" *ngIf="issue?.links?.length > 0 || isAdd">
    <small fxLayoutGap="10px">
      <span i18n>Linked issues</span>
      <a *ngIf="editDisabled" (click)="toggleAdd()">
        <span *ngIf="!isAdd">Add new link</span>
        <span *ngIf="isAdd">Close new link</span>
      </a>
    </small>

    <form *ngIf="issue && form" novalidate [formGroup]="form">
      <div fxLayout="column" formArrayName="links">
        <div fxLayout="row" *ngFor="let subForm of form.controls.links.controls; let i = index; trackBy: trackById" [formGroupName]="i">
          <app-issue-link-form fxFlex="100" [form]="subForm" [index]="i" (changeEmitter)="onRemoveSubForm($event)" [editDisabled]="editDisabled"></app-issue-link-form>
        </div>
      </div>
    </form>

    <!-- TODO SEARCH FORM -->
    <form fxLayoutGap="20px" id="searchForm" novalidate autocomplete="off" [formGroup]="searchForm" *ngIf="isAdd">
      <mat-form-field fxFlex="150px" (click)="focusOnSearchInput()" floatLabel="always">
        <mat-placeholder>Link to issue</mat-placeholder>
        <input matInput type="text" formControlName="searchTerm" [matAutocomplete]="search" (change)="invalidateSelection()">

        <mat-autocomplete #search="matAutocomplete" (optionSelected)="addForm.patchValue({referenceNo: $event.option.value})">
          <mat-option *ngFor="let result of issues; let isLast = last; trackBy: trackById" [value]="result.referenceNo">

            <div fxLayout="row" fxLayoutAlign="space-between top" *ngIf="searchForm.value.searchTerm">
              <div fxLayout="column">
                <strong class="text-small" *ngIf="result?.referenceNo">
                  <strong i18n>LH ref: </strong>
                  <span [innerHtml]="result.referenceNo | highlight:searchForm.value.searchTerm"></span>
                </strong>
                <span class="text-small" *ngIf="result?.reference?.customerReference">
                  <span i18n>Customer ref: </span>
                  <span [innerHtml]="result.reference.customerReference | highlight:searchForm.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="result?.reference?.projectNumber">
                  <span i18n>Project no: </span>
                  <span [innerHtml]="result.reference.projectNumber | highlight:searchForm.value.searchTerm"></span>
                </span>
              </div>
              <div fxLayout="column">
                <strong class="text-small">&nbsp;</strong>
                <span class="text-small" *ngIf="result?.reference?.pickupReference">
                  <span i18n>Pickup ref: </span>
                  <span [innerHtml]="result.reference.pickupReference | highlight:searchForm.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="result?.reference?.otherReference">
                  <span i18n>Other ref: </span>
                  <span [innerHtml]="result.reference.otherReference | highlight:searchForm.value.searchTerm"></span>
                </span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>

        <button matSuffix mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>

      <form [formGroup]="addForm" fxFlex="150px">
        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>Note</mat-placeholder>
          <input matInput type="text" formControlName="note">
        </mat-form-field>
      </form>

      <div fxFlex="110px" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="primary" (click)="addSubForm()" [disabled]="addForm.invalid" i18n>
          SAVE LINK
        </button>
      </div>
    </form>
  </mat-card-header>
</mat-card>
