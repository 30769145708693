import {Component} from "@angular/core";

@Component({
  selector: 'app-settings-dialog',
  template: '<app-settings></app-settings>'
})
export class SettingsDialog {

  constructor() {
  }
}
