
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, EventEmitter, Output} from "@angular/core";
import {Issue} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormControl} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";
import {DateValidator} from "../../../validators/date.validator";

@Component({
  selector: 'app-issue-deadline-form',
  templateUrl: 'issue-deadline-form.component.html',
  styleUrls: ['issue-deadline-form.component.scss']
})
export class IssueDeadlineFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  form: FormGroup;

  constructor(private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    let issue = this.issue;
    this.form = new FormGroup({
      date: new FormControl(issue.deadline && issue.deadline.date || null, {validators: DateValidator.date}),
      time: new FormControl(issue.deadline && issue.deadline.time || null)
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {deadline: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }
}
