import {
  EventEmitter,
  Inject,
  Injectable,
  Output,
  Directive,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { WebSocketService } from "../websocket/websocket.service";
import { Notification } from "./notification";
import { Environment } from "../../modules/environment/environment";
import { SnackBarService } from "../snackbar/snackbar.service";
import { AuthService } from "../../modules/auth/auth.service";
import { AuthGuardService } from "../../modules/auth/auth-guard.service";
import { Data } from "../data/data";
import { superRoles } from "../../modules/auth/roles";

@Directive()
@Injectable()
export class NotificationService {
  private onMessageReceived: Subject<Notification> =
    new Subject<Notification>();
  private onAuthUpdated: Subscription;
  @Output() onAuditActivity: EventEmitter<Notification> =
    new EventEmitter<Notification>();
  @Output() onEmailActivity: EventEmitter<Notification> =
    new EventEmitter<Notification>();

  private loggedIn: boolean = false;
  private socketOpen: boolean = false;
  private socket: any;
  superRoles = superRoles;

  constructor(
    @Inject("APP_ENV") private env: Environment,
    private data: Data,
    private authGuard: AuthGuardService,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private snackBarService: SnackBarService
  ) {
    if (this.authService.isAuthenticated()) {
      this.openWebsocket();
    }

    this.getMessageReceived().subscribe((msg) => {
      switch (msg.notificationType) {
        case "AUDIT_ACTIVITY":
          if (
            this.authGuard.hasAnyAuthority(superRoles) ||
            !msg.notificationCompany ||
            msg.notificationCompany == this.data.me.companyCode
          ) {
            this.onAuditActivity.next(msg);
            // LHP-302 hide user activity
            // this.snackBarService.info(msg.notificationData);
          }
          break;
        case "EMAIL_ACTIVITY":
          if (
            this.authGuard.hasAnyAuthority(superRoles) ||
            !msg.notificationCompany ||
            msg.notificationCompany == this.data.me.companyCode
          ) {
            this.onEmailActivity.next(msg);
          }
          break;
      }
    });
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() =>
      this.authService.isAuthenticated()
        ? this.openWebsocket()
        : this.closeWebsocket()
    );
  }

  getMessageReceived() {
    return this.onMessageReceived;
  }

  reopen() {
    if (this.loggedIn === false) return;

    setTimeout(() => {
      this.openWebsocket();
      if (this.socketOpen === false) this.reopen();
    }, 5000);
  }

  openWebsocket() {
    this.socketOpen = true;
    this.socket = this.webSocketService.connect(
      new URL(this.env.notificationsUrl)
    );
    this.socket.subscribe(
      (msg) => {
        if (msg && msg.data) {
          let payload = JSON.parse(msg.data);
          this.onMessageReceived.next(payload);
        }
      },
      (err) => {
        this.snackBarService.error(err);
      },
      () => {
        this.socketOpen = false;
        this.reopen();
      }
    );
  }

  closeWebsocket() {
    this.socketOpen = false;
    if (this.socket) this.socket.complete();
  }

  isReferenceNoUpdatedBySomeoneElse(referenceNo, msg): boolean {
    return (
      referenceNo == msg.notificationReferenceNo &&
      this.authService.userEmail() != msg.notificationUser
    );
  }

  isUuidUpdatedBySomeoneElse(uuid, msg): boolean {
    return (
      uuid == msg.notificationUuid &&
      this.authService.userEmail() != msg.notificationUser
    );
  }
}
