import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-issue-link-form',
  templateUrl: 'issue-link-form.component.html',
  styleUrls: ['issue-link-form.component.scss']
})
export class IssueLinkFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() index: any;
  @Output() changeEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Input() editDisabled: boolean;
  isEdit: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
  }
}
