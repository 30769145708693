import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {User} from "./user.interface";
import {UserRole} from "./user-role.interface";
import {SortablePageRequest} from "../api/api.interface";
import {SnackBarService} from "../snackbar/snackbar.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class UserService {
  // onUpdated: Subject<User> = new Subject<User>();

  constructor(private http: HttpClient, public snackBarService: SnackBarService) {
  }

  getUsers(): Observable<any> {
    return this.http.get(`/api/users`);
  }

  fetchUser(userId: number): Observable<any> {
    return this.http.get(`/api/users/find/${userId}`)
  }

  create(user: User): Observable<any> {
    return this.http.post(`/api/users/`, user);
  }

  patch(userId: number, params: any): Observable<any> {
    return this.http.patch(`/api/users/${userId}`, params);
  }

  patchUserActivity(userId: number, isActive: boolean): Observable<any> {
    return this.http.patch(`/api/users/activation/${userId}`, { isActive: isActive });
  }

  findUserRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>('/api/users/roles/');
  }

  getColumnState(userId: number): Observable<any> {
    return this.http.get(`/api/users/gridsettings/${userId}`);
  }

  patchColumnState(userId: number, columnState: any): Observable<any> {
    return this.http.patch(
      `/api/users/gridsettings/${userId}`,
      {
        "gridSettings": JSON.stringify(columnState)
      }
    );
  }
}
