export const environment = {
  name: "development",
  version: "VERSION",
  production: false,
  apiGatewayUrl: "https://api.dev.leafhill.net",
  notificationsUrl: "wss://ws.api.dev.leafhill.net",
  apidocsUrl:
    "http://leafhill-gateway-dev-apidocs.s3-website-eu-west-1.amazonaws.com/",
  integrationS3Buckets: {
    leafhill: "leafhill-gateway-dev-issues",
    bmh: "leafhill-gateway-dev-bmh",
  },
  domain: "dev.leafhill.net",
  sentry: {
    dsn: null, // Disables Sentry
    environment: "development",
    release: "VERSION",
  },
};
