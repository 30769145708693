import {Component, Input} from "@angular/core";
import {Issue} from "../issue.interface";
import {EnumService} from "../../enum/enum.service";

@Component({
  selector: 'app-issue-links',
  templateUrl: 'issue-links.component.html',
  styleUrls: ['issue-links.component.scss']
})
export class IssueLinksComponent {
  @Input() issue: Issue;

  constructor(public enums: EnumService) {
  }

  trackById = (index, item) => {
    return item.id;
  }
}
