import {Component, OnInit} from "@angular/core";
import {Me, MeCompany} from "./shared/toolbar/me/me.interface";
import {MeService} from "./shared/toolbar/me/me.service";
import {Data} from "./shared/data/data";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthService} from "./modules/auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private authService: AuthService,
              public data: Data,
              private meService: MeService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.meService.getMe().subscribe((me: Me) => {
        this.data.me = me;
        this.data.companyLogo = this.sanitizer.bypassSecurityTrustUrl(`${me.companyLogoType},${me.companyLogoData}`);
      });
      this.meService.getMeCompanies().subscribe((meCompanies: MeCompany[]) => {
        this.data.meCompanies = meCompanies
      });
    }
  }
}
