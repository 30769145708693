import {Component, OnInit} from "@angular/core";

import {NotificationService} from "../notification/notification.service";
import {SnackBarService} from "../snackbar/snackbar.service";

class BatchNotification {
    constructor(msg) {
      let parts = msg.notificationData.split(';');
      if (msg.notificationType === 'BATCH_LOADED') {
        this.text = parts[0] + ' loaded';
      }
      if (msg.notificationType === 'BATCH_STARTED') {
        this.text = parts[0] + ' started';
      }
      if (msg.notificationType === 'BATCH_FAILED') {
        this.text = parts[0] + ' failed';
        parts.splice(0, 1);
        this.data = parts.join(', ');
      }
      this.timestamp = new Date(Date.now()).toLocaleString();
    }
    text: string;
    data: string;
    timestamp: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public notifications: Array<BatchNotification> = new Array<BatchNotification>();

  constructor(private notificationService: NotificationService,
              private snackBarService: SnackBarService) {
  }

  ngOnInit() {
    this.notificationService.getMessageReceived().subscribe((msg) => {
      let notification = new BatchNotification(msg);
      if (msg.notificationType === 'BATCH_LOADED') {
        this.snackBarService.info('Please refresh browser cache (CTRL + F5)! Batch file and cache for ' + notification.text);
      }
      else if (msg.notificationType === 'BATCH_FAILED') {
        this.snackBarService.info('Please check file! Batch file for ' + notification.text + '\n' + notification.data);
      }
      this.notifications.unshift(notification);
    });
  }

  clearNotification(notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }
}
