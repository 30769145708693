import {Observable} from "rxjs";
import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {Injectable, ViewContainerRef} from "@angular/core";
import {IssueStatusDialog} from "./status/issue-status-dialog.component";
import {Issue} from "./issue.interface";
import {IssueTransportationDialog} from "./transportation/issue-transportation-dialog.component";
import {IssueClonedDialog} from "./issue-cloned-dialog.component";

@Injectable()
export class IssueDialogsService {

  constructor(private dialog: MatDialog) {
  }

  public editIssueStatus(issue: Issue, viewContainerRef: ViewContainerRef): Observable<boolean> {

    let dialogRef: MatDialogRef<IssueStatusDialog>;
    let config = new MatDialogConfig();

    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(IssueStatusDialog, config);

    dialogRef.componentInstance.issue = issue;

    return dialogRef.afterClosed();
  }

  public editIssueTransportation(issue: Issue, viewContainerRef: ViewContainerRef): Observable<boolean> {

    let dialogRef: MatDialogRef<IssueTransportationDialog>;
    let config = new MatDialogConfig();

    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(IssueTransportationDialog, config);

    dialogRef.componentInstance.issue = issue;

    return dialogRef.afterClosed();
  }

  public showIssueCloned(original: Issue, cloned: Issue, viewContainerRef: ViewContainerRef): Observable<boolean> {

    let dialogRef: MatDialogRef<IssueClonedDialog>;
    let config = new MatDialogConfig();

    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(IssueClonedDialog, config);

    dialogRef.componentInstance.originalIssue = original;
    dialogRef.componentInstance.clonedIssue = cloned;

    return dialogRef.afterClosed();
  }

}
