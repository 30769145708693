import {Component, Input} from "@angular/core";
import {Issue} from "../issue.interface";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-issue-status-dialog',
  template: '<app-issue-status-form *ngIf="issue" [editDisabled]="editDisabled" [issue]="issue"></app-issue-status-form>'
})
export class IssueStatusDialog {
  @Input() editDisabled: boolean;
  public issue: Issue;

  constructor(public dialogRef: MatDialogRef<IssueStatusDialog>) {
  }
}
