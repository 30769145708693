
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input} from "@angular/core";
import {Issue, IssueLink} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormArray, Validators, FormControl} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {IssueFormComponent} from "../../issue-form.component";
import * as _ from "lodash";

@Component({
  selector: 'app-issue-links-form',
  templateUrl: 'issue-links-form.component.html',
  styleUrls: ['issue-links-form.component.scss']
})
export class IssueLinksFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() inner: boolean;
  @Input() editDisabled: boolean;
  form: FormGroup;

  @ViewChild('searchInput') searchInput: ElementRef;
  searchForm: FormGroup;
  issues: Array<Issue>;
  removeDialogRef: MatDialogRef<RemoveDialog>;

  addForm: FormGroup;
  isAdd: boolean;

  constructor(
    private ajaxService: AjaxService,
    private issueService: IssueService,
    private MatDialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();

    // search form
    this.searchForm = new FormGroup({
      searchTerm: new FormControl(),
      page: new FormControl(0),
      size: new FormControl(10),
      sort: new FormControl('-issue.referenceNo')
    }, { updateOn: 'change' });

    this.searchForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.find());

    // add form
    this.addForm = new FormGroup({
      referenceNo: new FormControl('', { validators: Validators.required }),
      note: new FormControl('')
    }, { updateOn: 'blur' });
  }

  initForm() {
    // links form
    this.form = new FormGroup({
      links: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms(issue) {
    let subForms = [];
    if (issue.links && this.issue.links.length > 0) {
      issue.links.forEach(link => subForms.push(this.initSubForm(link)));
    } else {
      //subForms.push(this.initSubForm(<IssueLink>{}));
    }
    return subForms;
  }

  initSubForm(link: IssueLink) {
    return new FormGroup({
      id: new FormControl(link && link.id),
      referenceNo: new FormControl(link && link.referenceNo),
      type: new FormControl(link && link.type),
      note: new FormControl(link && link.note)
    }, { updateOn: 'blur' });
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['links'];
    control.push(this.initSubForm(this.addForm.value));
    // clear search and add forms
    this.searchForm.patchValue({referenceNo: '', note: ''}, {emitEvent: false});
    this.addForm.patchValue({referenceNo: '', note: ''}, {emitEvent: false});
    this.isAdd = false;
  }

  onRemoveSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['links'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    const control = <FormArray>this.form.controls['links'];
    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      control.controls.forEach((subForm: FormGroup, index: number) => {
        subForm.patchValue({
          id: issue.links[index].id,
          type: issue.links[index].type
        }, {emitEvent: false});
      });
      this.changeEmitter.emit(issue)
    });
  }

  focusOnSearchInput() {
    if (this.searchInput)
      this.searchInput.nativeElement.focus();
  }

  find() {
    let exclusion = _.map(this.form.value.links, 'referenceNo');
    exclusion.push(this.issue.referenceNo);

    this.issueService.find(this.searchForm.value.searchTerm).subscribe(issues => {
      this.issues = _.filter(issues, function (issue) {
        return exclusion.indexOf(issue.referenceNo) == -1;
      });
    });
  }

  toggleAdd() {
    this.isAdd = !this.isAdd;
  }

  invalidateSelection() {
    if (this.searchForm.value.searchTerm != this.addForm.value.referenceNo) {
      this.addForm.patchValue({referenceNo: ''}, {emitEvent: false});
    }
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
