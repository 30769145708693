import {Observable} from "rxjs";
import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {Injectable, ViewContainerRef} from "@angular/core";
import {SettingsDialog} from "../settings/settings-dialog.component";

@Injectable()
export class DialogsService {

  constructor(private dialog: MatDialog) {
  }

  /**
   * Settings dialog
   */
  public settings(viewContainerRef: ViewContainerRef): Observable<boolean> {
    let dialogRef: MatDialogRef<SettingsDialog>;
    let config = new MatDialogConfig();

    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(SettingsDialog, config);

    return dialogRef.afterClosed();
  }
}
