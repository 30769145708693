<div fxLayout="column" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="begin center" fxLayoutGap="10px" *ngIf="form.value.type && form.value.referenceNo">
    <app-issue-type-dot *ngIf="form.value.type" [issueType]="form.value.type"></app-issue-type-dot>
    <small>
      {{form.value.referenceNo}}
      <ng-container *ngIf="form.value.note">({{form.value.note}})</ng-container>
    </small>
    <a routerLink="/issues/{{form.value.referenceNo}}">
      <mat-icon>open_in_new</mat-icon>
    </a>
    <a *ngIf="editDisabled" (click)="toggleEdit()">
      <mat-icon *ngIf="!isEdit">edit</mat-icon>
      <mat-icon *ngIf="isEdit">done</mat-icon>
    </a>
  </div>

  <div fxLayout="row" *ngIf="isEdit">
    <form novalidate [formGroup]="form" fxFlex="100" fxLayoutGap="20px">
      <input type="hidden" formControlName="id"/>

      <mat-form-field fxFlex="150px">
        <mat-placeholder i18n>Linked issue</mat-placeholder>
        <input matInput value="{{form.value.referenceNo}}" disabled/>
      </mat-form-field>

      <mat-form-field fxFlex="150px">
        <mat-placeholder i18n>Note</mat-placeholder>
        <input matInput type="text" formControlName="note">
      </mat-form-field>

      <div fxFlex="110px" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="warn" (click)="changeEmitter.next(index)" i18n>
          DELETE
        </button>
      </div>
    </form>
  </div>
</div>
