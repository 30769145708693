import {Component, OnInit, ViewContainerRef} from "@angular/core";
import {DatePipe} from "@angular/common";
import {IssueService} from "../issue/issue.service";
import {Issue} from "../issue/issue.interface";
import {Router} from "@angular/router";
import {Data} from "../data/data";
//noinspection TypeScriptCheckImport
import {saveAs} from "file-saver";
import {AuthGuardService} from "../../modules/auth/auth-guard.service";
import {Me, MeCompany} from "../toolbar/me/me.interface";
import {DomSanitizer} from "@angular/platform-browser";
import {MeService} from "../toolbar/me/me.service";
import {AuthService} from "../../modules/auth/auth.service";
import {DialogsService} from "../dialog/dialog.service";
import {SnackBarService} from "../../shared/snackbar/snackbar.service";
import {roleType, portalRoles, editorRoles, superRoles} from "../../modules/auth/roles";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  isExpanded: boolean;
  roleType = roleType;
  portalRoles = portalRoles;
  superRoles = superRoles;
  editorRoles = editorRoles;

  constructor(private router: Router,
              public data: Data,
              private meService: MeService,
              private authService: AuthService,
              private issueService: IssueService,
              private sanitizer: DomSanitizer,
              public authGuard: AuthGuardService,
              private dialogService: DialogsService,
              private snackBarService: SnackBarService,
              private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  create(type: string) {
    this.issueService.create(type)
      .subscribe(
        (issue: Issue) => {this.router.navigate(['/browse', issue.referenceNo])},
        (error) => { throw error; }
    )
  }

  xlsx() {
    this.snackBarService.info(`Downloading file...`, true, 300000);
    var datePipe = new DatePipe('en');
    var date = datePipe.transform(new Date(), 'yyyyMMddHHmm');
    let filename = `Browse All ${date}.xlsx`;
    this.issueService.xlsx(filename).subscribe(
      (file: File) => {
        saveAs(file, filename);
      },
      err => {
        if (err.status === 504 || err.status === 502) {
          this.snackBarService.info(`Couldn't fetch file: request timed out (` + err.status + `).`, true, 4000);
        } else {
          this.snackBarService.info(`Couldn't fetch file: error response (` + err.status + `).`, true, 4000);
        }
        console.log(`Couldn't fetch file:`, err.status, err.statusText);

      },
      () => {
        this.snackBarService.info(`Successfully fetched file.`, true, 2000);
      }
    );
  }

  sanitizeLogoUrl(meCompany: MeCompany) {
    return this.sanitizer.bypassSecurityTrustUrl(`${meCompany.logoType},${meCompany.logoData}`);
  }

  loginAs(company) {
    if (company && company['id']) {
      this.meService.activate(company['id']).subscribe((me: Me) => {
        this.authService.renewToken();
      });
    }
  }

  openSettingsDialog() {
    this.dialogService
      .settings(this.viewContainerRef)
      .subscribe();
  }
}
