import {Injectable, NgZone, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {SnackBarService} from "../snackbar/snackbar.service";
import {IssueService} from "../issue/issue.service";
import {AuthService} from "../../modules/auth/auth.service";

@Injectable()
export class SpeechRecognitionService implements OnInit, OnDestroy {
  private onAuthUpdated: Subscription;
  searchGrammar: Observable<any[]>;
  speechRecognition: any;

  constructor(private zone: NgZone,
              private authService: AuthService,
              private snackbar: SnackBarService,
              private issueService: IssueService) {
    this.init();
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() => this.authService.isAuthenticated() && this.init());
  }

  ngOnDestroy() {
    if (this.onAuthUpdated)
      this.onAuthUpdated.unsubscribe();
  }

  init() {
    this.searchGrammar = this.issueService.findSearchGrammar();
  }

  start(isSilent?): Observable<string> {
    return Observable.create(observer => {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
      const SpeechGrammarList = (window as any).SpeechGrammarList || (window as any).webkitSpeechGrammarList;
      const SpeechRecognitionEvent = (window as any).SpeechRecognitionEvent || (window as any).webkitSpeechRecognitionEvent;

      this.speechRecognition = new SpeechRecognition();
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      this.speechRecognition.lang = 'fi-fi';
      this.speechRecognition.maxAlternatives = 1;

      this.speechRecognition.onresult = speech => {
        let term: string = '';
        if (speech.results) {
          let result = speech.results[speech.resultIndex];
          let transcript = result[0].transcript;
          if (result.isFinal && result[0].confidence < 0.3) {
            this.snackbar.info('Unrecognized. Please, try again...', true, 1000);
          }
          else {
            term = transcript.trim();
          }
        }
        this.zone.run(() => {
          observer.next(term);
        });
      };

      this.speechRecognition.onerror = error => {
        observer.error(error);
      };

      this.speechRecognition.onend = () => {
        observer.complete();
      };

      this.searchGrammar.subscribe(searchGrammar => {
        let speechRecognitionList = new SpeechGrammarList();
        for (let key in searchGrammar) {
          speechRecognitionList.addFromString(`#JSGF V1.0 UTF-8 fi-fi; grammar for ${key}; public <${key}> = ${searchGrammar[key].join(' | ')} ;`, 1);
        }
        this.speechRecognition.grammars = speechRecognitionList;
        this.speechRecognition.start();
      });

      if (!isSilent)
        this.snackbar.info('Started speech recognition...', true, 1000);
    });
  }

  stop(isSilent?) {
    // stop needed only if started... i.e. initialized
    if (this.speechRecognition) {
      this.speechRecognition.stop();
      if (!isSilent)
        this.snackbar.info('Stopped speech recognition.', true, 1000);
    }
  }
}
