import {Component} from "@angular/core";
import {Issue} from "../issue.interface";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-issue-report-parameters-dialog',
  templateUrl: 'issue-report-parameters-dialog.html'
})
export class IssueReportParametersDialog {
  public issue: Issue;

  selectedPickupId: number;
  selectedDeliveryId: number;

  constructor(public dialogRef: MatDialogRef<IssueReportParametersDialog>) {
  }

  ngOnInit() {
    this.selectedPickupId = this.issue.pickups && this.issue.pickups[0].id;
    this.selectedDeliveryId = this.issue.deliveries && this.issue.deliveries[0].id;
  }

  generate() {
    this.dialogRef.close({
      selectedPickupId: this.selectedPickupId,
      selectedDeliveryId: this.selectedDeliveryId
    });
  }

  trackById = (index, item) => {
    return item.id;
  }
}
