
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {Activity} from "../../issue/issue.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../../../modules/auth/auth.service";
import {Subscription} from "rxjs";
import {NewestService} from "../newest.service";
import {AuthGuardService} from "../../../modules/auth/auth-guard.service";
import {NotificationService} from "../../notification/notification.service";
import {superRoles} from '../../../modules/auth/roles';

@Component({
  selector: 'app-activity',
  templateUrl: 'newest-activity.component.html',
  styleUrls: ['newest-activity.component.scss']
})
export class NewestActivityComponent implements OnInit, OnDestroy {

  private onAuthUpdated: Subscription;
  private onNotificationUpdated: Subscription;

  @Input() isShowFilter: boolean;
  superRoles = superRoles;
  activities: Array<Activity>;
  filterOptions: Array<string>;
  form: FormGroup;

  constructor(private authService: AuthService,
              private notificationService: NotificationService,
              private newestService: NewestService,
              public authGuard: AuthGuardService) {
    this.filterOptions = ['Today', 'This week', 'This month', 'This year', 'All'];

    this.form = new FormGroup({
      timespan: new FormControl(this.filterOptions[4]),
      limit: new FormControl(5)
    }, { updateOn: 'change' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.findIssueActivity());

    this.findIssueActivity();
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() => this.authService.isAuthenticated() && this.findIssueActivity());
    this.onNotificationUpdated = this.notificationService.onAuditActivity.subscribe(() => this.findIssueActivity());
  }

  ngOnDestroy() {
    if (this.onAuthUpdated)
      this.onAuthUpdated.unsubscribe();
    if (this.onNotificationUpdated)
      this.onNotificationUpdated.unsubscribe();
  }

  findIssueActivity() {
    let today = new Date;
    let startDate;
    let endDate;
    if (this.form.value.timespan) {
      switch (this.form.value.timespan) {
        case this.filterOptions[0]: //today
          startDate = this.dateToISODateString(today);
          endDate = null;
          break;
        case this.filterOptions[1]: //week
          startDate = this.dateToISODateString(new Date(today.setDate(today.getDate() - today.getDay() + 1)));
          endDate = this.dateToISODateString(new Date(today.setDate(today.getDate() - today.getDay() + 7)));
          break;
        case this.filterOptions[2]: //month
          startDate = this.dateToISODateString(new Date(today.setDate(1)));
          endDate = this.dateToISODateString(new Date(today.getFullYear(), today.getMonth() + 1, 0));
          break;
        case this.filterOptions[3]: //year
          startDate = this.dateToISODateString(new Date(today.getFullYear(), 0, 1));
          endDate = this.dateToISODateString(new Date(today.getFullYear() + 1, 0, 0));
          break;
        case this.filterOptions[4]: //all
          startDate = null;
          endDate = null;
          break;
      }
    }
    this.newestService.findNewestActivity(startDate, endDate, this.form.value.limit).subscribe(activities => this.activities = activities);
  }

  dateToISODateString(date) {
    return date.toISOString().substr(0, date.toISOString().indexOf('T'));
  }
}
