import {Component, OnInit, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {UnClassification, PaymentTerm} from "../../issue.interface";

@Component({
  selector: 'app-issue-special-requirement-form',
  templateUrl: 'issue-special-requirement-form.component.html',
  styleUrls: ['issue-special-requirement-form.component.scss']
})
export class IssueSpecialRequirementFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() unClassifications: Array<UnClassification>;
  @Input() paymentTerms: Array<PaymentTerm>;
  @Input() editDisabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  trackById = (index, item) => {
    return item.id;
  }
}
