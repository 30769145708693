import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import {Component, Input, OnInit, OnChanges, SimpleChange, Output, EventEmitter} from '@angular/core';
import { CompanyRole } from "./company-role.interface";
import { Company } from "../../../../issue/issue.interface";
import { UserRole } from "../../../user-role.interface";
import { startWith } from "rxjs/operators";

@Component({
    selector: 'app-company-role-selection',
    templateUrl: 'company-role-selection.component.html'
})
export class CompanyRoleSelectionComponent implements OnInit, OnChanges {
    
    @Input() set companies(val: Array<Company>) {
        this.selectableCompanies = val && [...val];
    }

    @Input() userRoles: Array<UserRole>
    @Input() selected: CompanyRole;

    @Output() onCompanyRoleChange = new EventEmitter<CompanyRole>();

    selectableCompanies: Array<Company>
    filteredCompanies: Array<Company>
    companyControl: FormControl;
    form: FormGroup;

    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes) {
        if(changes["companies"] && changes["companies"].currentValue) {
            this.selectableCompanies = [...changes["companies"].currentValue];
            this.filteredCompanies = [...changes["companies"].currentValue];
        }

        if(changes["selected"]) {
            this.initForm();
        }
    }

    private initForm() {
        this.form = new FormGroup({
            companyId: new FormControl(this.selected && this.selected.companyId || null),
            roleId: new FormControl(this.selected && this.selected.roleId || null)
        });

        this.companyControl = new FormControl(this.selected || null);
        this.checkCompanyField();

        this.form.valueChanges.subscribe(form => {
            this.onCompanyRoleChange.emit(form);
        }); 

        this.companyControl.valueChanges.pipe(
            startWith(null))
            .subscribe((val) => {
                let searchTerm = "";
                if(!!val && val.name) {
                    searchTerm = val.name;
                } else if(!!val) {
                    searchTerm = val.toString();
                }
                if(this.selectableCompanies) {
                    this.filteredCompanies = this.selectableCompanies.filter(c => {
                        let companyName = "";
                        if(!!c.name) {
                            if(!!c.code) {
                                companyName += `(${c.code}) ${c.name}`;
                            } else {
                                companyName += c.name.toString();
                            }
                        }
                        return companyName.toLowerCase().includes(searchTerm.toLowerCase());
                    });
                }
        });
    }

    checkCompanyField(): void {
        // If the input field is empty on blur => null the value.
        if(!this.companyControl.value) {
          this.form.patchValue({companyId: null});
          this.companyControl.setValue(null);
        } else {
          // Set the input field text to match the selected company
          if(!this.selectableCompanies) return;
          const selectedCompany = this.selectableCompanies.find(r => r.id === this.form.controls["companyId"].value);
          this.companyControl.setValue(selectedCompany, {emitEvent: true});
        }
    }

    selectCompany(company: Company): void {
        this.form.patchValue({companyId: company.id});
        this.companyControl.setValue(company);
    }
    
    displayCompany(company: Company): string {
        return (!!company ? (!!company.code ? company.code : "") + " " + (!!company.name ? company.name : "") : "").trim();
    }

    delayedBlur(f, timeout): void {
        // Timeout is needed because the blur event gets called differently depending on
        // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
        // Own function simply for comment centralization.
        setTimeout(f, !!timeout ? timeout : 300);
    }

    trackById = (index, item) => {
        return item.id;
    }

}