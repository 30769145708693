import {Component, Input, Output, EventEmitter} from '@angular/core';
import {HostListener} from '@angular/core';

@Component({
  selector: 'app-grid-modal',
  templateUrl: './grid-modal.component.html',
  styleUrls: ['./grid-modal.component.scss']
})

export class GridModalComponent {
  @Output() closeModalEvent = new EventEmitter();
  @Input() isModalOpen: any;
  isModalInitialized = false;

  constructor() {}

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent() {
    this.closeModal();
  }

  onClickedOutside(e: Event) {
    //this.closeModal();
  }

  closeModal() {
    this.isModalOpen = false;
    this.closeModalEvent.emit(null);
  }
}
