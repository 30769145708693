<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Claim</h2>
        <h2 *ngIf="issue?.claim">
          <span class="text-small color-light" i18n>Last modified by {{issue?.claim?.lastModifiedBy?.name}} {{issue?.claim?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row" fxLayoutGap="20px">

      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Related party</mat-placeholder>
        <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" type="text" formControlName="relatedParty"></textarea>
      </mat-form-field>

      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Owner</mat-placeholder>
        <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" type="text" formControlName="owner"></textarea>
      </mat-form-field>

    </mat-card-content>
    <mat-card-content fxLayout="row" fxLayoutGap="20px">

      <decimalinput fxFlex="100px" [(value)]="form.value.amount"
                    (valueChange)="form.patchValue({ amount: $event })"
                    placeholder="Amount"></decimalinput>

      <mat-form-field fxFlex="80px">
        <mat-select formControlName="amountCurrencyId">
          <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id">{{option.code}}</mat-option>
        </mat-select>
        <mat-placeholder i18n>Currency</mat-placeholder>
      </mat-form-field>

    </mat-card-content>
  </mat-card>
</form>
