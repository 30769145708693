import {Component, OnDestroy, OnInit} from "@angular/core";
import {IssueNote} from "../../issue/issue.interface";
import {NewestService} from "../newest.service";
import {EnumService} from "../../enum/enum.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../../modules/auth/auth.service";
import {AuthGuardService} from "../../../modules/auth/auth-guard.service";
import {NotificationService} from "../../notification/notification.service";
import {superRoles} from "../../../modules/auth/roles";

@Component({
  selector: 'app-newest-issue-notes',
  templateUrl: 'newest-issue-notes.component.html',
  styleUrls: ['newest-issue-notes.component.scss']
})

export class NewestIssueNotesComponent implements OnInit, OnDestroy {

  private onAuthUpdated: Subscription;
  private onNotificationUpdated: Subscription;
  superRoles = superRoles;
  notes: Array<IssueNote>;

  constructor(private newestService: NewestService,
              public enumService: EnumService,
              private authService: AuthService,
              private notificationService: NotificationService,
              public authGuard: AuthGuardService) {
    this.findIssueNotes();
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() => this.authService.isAuthenticated() && this.findIssueNotes());
    this.onNotificationUpdated = this.notificationService.onAuditActivity.subscribe(() => this.findIssueNotes());
  }

  ngOnDestroy() {
    if (this.onAuthUpdated)
      this.onAuthUpdated.unsubscribe();
    if (this.onNotificationUpdated)
      this.onNotificationUpdated.unsubscribe();
  }

  findIssueNotes() {
    this.newestService.findNewestIssueNotes(5)
      .subscribe(notes => this.notes = notes);
  }
}
