<form novalidate [formGroup]="form">
  <mat-card class="app-card" [ngClass]="{'inner': inner}">
    <mat-card-header fxLayoutAlign="space-between" *ngIf="!inner">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Status</h2>
        <h2 *ngIf="issue?.status">
          <span class="text-small color-light" i18n>Last modified by {{issue?.status?.lastModifiedBy?.name}} {{issue?.status?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>

    <mat-card-content fxLayout="row" fxLayoutGap="20px">
      <!-- BOOKING STATUS -->
      <mat-form-field fxFlex="100" *ngIf="issue.status?.bookingStatus">
        <input type="text"
          placeholder="Status"
          matInput
          formControlName="bookingStatusId"
          autocomplete="off"
          #bookingStatus
          (keyup)="filterBookingStatuses(bookingStatus.value)"
          (blur)="checkBookingStatus(bookingStatus.value)"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBookingStatus.bind(this)" (optionSelected)="selectBookingStatus($event.option.value)">
          <mat-option *ngFor="let option of filteredBookingStatuses; trackBy: trackById" [value]="option.id">{{option.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- HELP DESK STATUS -->
      <mat-form-field fxFlex="100" *ngIf="issue.status?.helpDeskStatus">
        <input type="text"
          placeholder="Status"
          matInput
          formControlName="helpDeskStatusId"
          autocomplete="off"
          #helpDeskStatus
          (keyup)="filterHelpDeskStatuses(helpDeskStatus.value)"
          (blur)="checkHelpDeskStatus(helpDeskStatus.value)"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayHelpDeskStatus.bind(this)" (optionSelected)="selectHelpDeskStatus($event.option.value)">
          <mat-option *ngFor="let option of filteredHelpDeskStatuses; trackBy: trackById" [value]="option.id">{{option.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>
