<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="row">

      <span fxLayout="column" fxFlex="100">
        <input type="hidden" formControlName="id"/>

        <span fxLayout="row" fxLayoutGap="20px">
          <integerinput
            fxFlex="150px"
            [(value)]="form.value.ranking"
            (valueChange)="form.patchValue({ ranking: $event})"
            placeholder="Ranking"
            [disabled]="editDisabled">
          </integerinput>

          <mat-form-field fxFlex.gt-sm="290px" fxFlex.lt-md="100">
            <mat-placeholder i18n>Logistic service provider</mat-placeholder>
            <input type="text" matInput [formControl]="logisticsServiceProvider" [matAutocomplete]="logisticServiceProvider" (change)="resetLSP()">
          </mat-form-field>
          <mat-autocomplete #logisticServiceProvider="matAutocomplete" [displayWith]="displaySP">
             <mat-option *ngFor="let option of filteredProviders | async; trackBy: trackById" [value]="option.name" (onSelectionChange)="updateLSP(option.id, $event)">
                {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </span>

        <span fxLayout="row" fxLayoutGap="20px">
          <decimalinput
            fxFlex="150px"
            [(value)]="form.value.cost"
            (valueChange)="form.patchValue({ cost: $event })"
            placeholder="Cost"
            [disabled]="editDisabled">
          </decimalinput>

          <mat-form-field fxFlex="80px">
            <mat-select formControlName="costCurrencyId">
              <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id">{{option.code}}</mat-option>
            </mat-select>
            <mat-placeholder i18n>Currency</mat-placeholder>
          </mat-form-field>

          <mat-form-field fxFlex="200px">
            <mat-placeholder i18n>Comparison</mat-placeholder>
            <input matInput type="text" formControlName="comparison">
          </mat-form-field>
        </span>

        <span fxLayout="row">
          <mat-form-field fxFlex="250px">
            <mat-placeholder i18n>Notes</mat-placeholder>
            <textarea matInput matTextareaAutosize [matAutosizeMinRows]="1" type="text" formControlName="note"></textarea>
          </mat-form-field>
        </span>

        <div fxLayout="column" class="issue-upload" *ngIf="form.value.filename && form.value.fileType && form.value.filePath">
          <div fxLayout="row">
            <button mat-button color="accent" (click)="download(form.value.filename, form.value.fileType, form.value.filePath)">
              <mat-icon>attachment</mat-icon>
              <span>{{form.value.filename}} ({{form.value.fileType}})</span>
            </button>
          </div>
        </div>

        <div *ngIf="!editDisabled" fxLayout="row">
          <input #fileInput type="file" style="display: none;" (change)="upload($event)"/>
          <button mat-button color="accent" (click)="fileInput.click()">Upload file</button>
        </div>
      </span>

    </mat-card-content>
  </mat-card>

</form>
