
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {Issue, BookingStatus, HelpDeskStatus} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormControl, FormGroup} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-status-form',
  templateUrl: 'issue-status-form.component.html',
  styleUrls: ['issue-status-form.component.scss']
})
export class IssueStatusFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() inner: boolean;
  @Input() editDisabled: boolean;

  bookingStatuses: Array<BookingStatus>;
  helpDeskStatuses: Array<HelpDeskStatus>;
  filteredBookingStatuses: Array<BookingStatus>;
  filteredHelpDeskStatuses: Array<HelpDeskStatus>;
  selectedHelpDeskStatus: HelpDeskStatus;
  selectedBookingStatus: BookingStatus;
  form: FormGroup;

  constructor(private ajaxService: AjaxService, private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    // INITIALIZE EMPTY FORM
    this.form = new FormGroup({
      bookingStatusId: new FormControl(),
      helpDeskStatusId: new FormControl()
    }, { updateOn: 'blur' });

    observableForkJoin([
      this.ajaxService.findBookingStatuses().pipe(map(statuses => {
        this.bookingStatuses = statuses;
        this.filteredBookingStatuses = statuses;
      })),
      this.ajaxService.findHelpDeskStatuses().pipe(map(statuses => {
        this.helpDeskStatuses = statuses;
        this.filteredHelpDeskStatuses = statuses;
      }))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  initForm() {
    let status = this.issue && this.issue.status;
    this.selectedHelpDeskStatus = status.helpDeskStatus;
    this.selectedBookingStatus = status.bookingStatus;
    this.form = new FormGroup({
      bookingStatusId: new FormControl({value: status && status.bookingStatus && status.bookingStatus.id || null, disabled: this.editDisabled}),
      helpDeskStatusId: new FormControl({value: status && status.helpDeskStatus && status.helpDeskStatus.id || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this._issue.referenceNo, {status: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  // HELP DESK STATUS

  checkHelpDeskStatus(statusText: string): void {
    // Force selection from the list of options. Defaults to initial status if status is not selected from the list of options.
    if(statusText !== this.selectedHelpDeskStatus.name || typeof this.form.controls["helpDeskStatusId"].value === "string") {
      this.form.controls["helpDeskStatusId"].setValue(this.issue.status.helpDeskStatus.id);
      this.filteredHelpDeskStatuses = [...this.helpDeskStatuses];
    } else {
      //this.form.controls["helpDeskStatusId"].setValue(this.selectedHelpDeskStatus.id);
    }
  }

  selectHelpDeskStatus(statusId: number): void {
    this.selectedHelpDeskStatus = this.getHelpDeskStatus(statusId);
  }

  displayHelpDeskStatus(statusId: number): string {
    const helpDeskStatus = this.getHelpDeskStatus(statusId);
    return !!helpDeskStatus ? helpDeskStatus.name : "";
  }

  filterHelpDeskStatuses(statusText: string) : void {
    this.filteredHelpDeskStatuses = this.helpDeskStatuses.filter(s => {
      return s.name.toLowerCase().indexOf(statusText.toLowerCase()) > -1
    });
  }

  private getHelpDeskStatus(id: number): HelpDeskStatus {
    return !!this.helpDeskStatuses ? this.helpDeskStatuses.find(s => s.id === id) : undefined;
  }


  // BOOKING STATUS

  checkBookingStatus(statusText: string): void {
    // Force selection from the list of options. Defaults to initial status if status is not selected from the list of options.
    if(statusText !== this.selectedBookingStatus.name || typeof this.form.controls["bookingStatusId"].value === "string") {
      this.form.controls["bookingStatusId"].setValue(this.issue.status.bookingStatus.id);
      this.filteredHelpDeskStatuses = [...this.helpDeskStatuses];
    }
  }

  selectBookingStatus(statusId: number): void {
    this.selectedBookingStatus = this.getBookingStatus(statusId);
  }

  displayBookingStatus(statusId: number): string {
    const bookingStatus = this.getBookingStatus(statusId);
    return !!bookingStatus ? bookingStatus.name : "";
  }

  filterBookingStatuses(statusText: string) : void {
    this.filteredBookingStatuses = this.bookingStatuses.filter(s => {
      return s.name.toLowerCase().indexOf(statusText.toLowerCase()) > -1
    });
  }

  private getBookingStatus(id: number): BookingStatus {
    return !!this.bookingStatuses ? this.bookingStatuses.find(s => s.id === id) : undefined;
  }

  trackById = (index, item) => {
    return item.id;
  }
}
