import {Component, EventEmitter, Input, Output, ChangeDetectionStrategy} from "@angular/core";
import {isNumeric} from "rxjs/internal-compatibility";
import {Formatter} from "../formatter/formatter";


@Component({
  selector: 'decimalinput',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'decimalinput.html'
})
export class DecimalInputComponent {
  @Input() value: any;
  @Input() placeholder: string;
  @Input() floatLabel: string;
  @Input() suffix: string;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter();

  public inputValue: any = '';

  constructor(public formatter : Formatter) {
  }

  private formatInput(input) {
    if (isNumeric(input)) {
      input = Number(input).toFixed(2);
      return String(input).replace('.', ',');
    }
    return '';
  }

  ngOnChanges(...args: any[]) {
    if (args.length > 0 && args[0].value) {
      if (isNumeric(args[0].value.currentValue)) {
        this.inputValue = this.formatInput(args[0].value.currentValue);
      } else {
        this.inputValue = '';
      }
    }
  }

  valueChanged(event: any) {
    var input = event.currentTarget.value.replace(',', '.');
    this.inputValue = this.formatInput(input);
    this.valueChange.emit(input);
  }
}
