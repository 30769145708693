import {BrowserModule, HammerModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, Injectable, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {LoginModule} from "./login/login.module";
import {DateValidator} from "./shared/validators/date.validator";
import {AjaxService} from "./shared/ajax/ajax.service";
import {WebSocketService} from "./shared/websocket/websocket.service";
import {NotificationService} from "./shared/notification/notification.service";
import {CdkTableModule} from "@angular/cdk/table";
import {Data} from "./shared/data/data";
import {AuthService} from "./modules/auth/auth.service";
import {AuthGuardService} from "./modules/auth/auth-guard.service";
import {RoleGuardService} from "./modules/auth/role-guard.service";
import {SnackBarService} from "./shared/snackbar/snackbar.service";

import { environment } from '../environments/environment';

import * as Sentry from "@sentry/browser";
Sentry.init(environment.sentry);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private dialogShownTimeout;

  async handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);

    // Delay dialog in case multiple errors are triggered within 250ms
    if (this.dialogShownTimeout) { clearTimeout(this.dialogShownTimeout) }

    this.dialogShownTimeout = setTimeout(() => {
      Sentry.showReportDialog({ eventId });
    }, 250);

    console.error(error);
  }
}

@NgModule({
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    CdkTableModule,
    LoginModule,
    AppRoutingModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    RoleGuardService,
    Data,
    DateValidator,
    AjaxService,
    WebSocketService,
    NotificationService,
    SnackBarService,
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
