<mat-list [ngClass]="{'opened': isExpanded, 'closed': !isExpanded}">
  <mat-list-item [matMenuTriggerFor]="companyMenu" class="link">
    <mat-icon *ngIf="!data?.me?.companyLogoData">business</mat-icon>
    <img mat-card-avatar *ngIf="data?.companyLogo" [src]="data?.companyLogo" [alt]="data?.me?.companyName">

    <a fxFlex="100" *ngIf="isExpanded">{{data?.me?.companyName}}</a>

    <mat-icon *ngIf="isExpanded">keyboard_arrow_down</mat-icon>
  </mat-list-item>

  <mat-divider></mat-divider>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)" [routerLink]="['/dashboard']" routerLinkActive="active" class="link">
    <mat-icon>dashboard</mat-icon>
    <a *ngIf="isExpanded" i18n>Dashboard</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)" [routerLink]="['/browse']" routerLinkActive="active" class="link" [routerLinkActiveOptions]="{'exact': true}">
    <mat-icon>assignment</mat-icon>
    <a *ngIf="isExpanded" i18n>Issues</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)" [routerLink]="['/issues-grid']" routerLinkActive="active" class="link" [routerLinkActiveOptions]="{'exact': true}">
    <mat-icon>grid_on</mat-icon>
    <a *ngIf="isExpanded" i18n>Issues Grid</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)" [routerLink]="['/support']" routerLinkActive="active" class="link" [routerLinkActiveOptions]="{'exact': true}">
    <mat-icon>help_center</mat-icon>
    <a *ngIf="isExpanded" i18n>Support</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)"  [routerLink]="['/inbox']" routerLinkActive="active" class="link" [routerLinkActiveOptions]="{'exact': true}">
    <mat-icon>all_inbox</mat-icon>
    <a *ngIf="isExpanded" i18n>Inbox</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAuthority(roleType.CUSTOMER_INTEGRATION)" [routerLink]="['/integration']" routerLinkActive="active" class="link">
    <mat-icon>import_export</mat-icon>
    <a *ngIf="isExpanded" i18n>Integrations</a>
  </mat-list-item>

  <mat-divider></mat-divider>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(portalRoles)" class="link" [matMenuTriggerFor]="createMenu">
    <mat-icon>edit</mat-icon>
    <a *ngIf="isExpanded" i18n>New issue</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(superRoles)" (click)="xlsx()" class="link">
    <mat-icon>cloud_download</mat-icon>
    <a *ngIf="isExpanded" i18n>.xlsx</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(superRoles)" [routerLink]="['/users']" routerLinkActive="active" class="link" [routerLinkActiveOptions]="{'exact': true}">
    <mat-icon>people_alt</mat-icon>
    <a *ngIf="isExpanded" i18n>Users</a>
  </mat-list-item>

  <mat-list-item *ngIf="authGuard.hasAnyAuthority(superRoles)" (click)="openSettingsDialog()" class="link">
    <mat-icon>settings</mat-icon>
    <a *ngIf="isExpanded" i18n>Settings</a>
  </mat-list-item>

  <mat-divider></mat-divider>

  <mat-list-item (click)="toggle()" class="link">
    <mat-icon>menu</mat-icon>
  </mat-list-item>

  <mat-divider></mat-divider>
</mat-list>

<mat-menu #companyMenu="matMenu" [overlapTrigger]="false" class="app-sidenav-menu">
  <button fxLayoutAlign="space-between center" mat-menu-item *ngFor="let meCompany of data?.meCompanies" (click)="loginAs(meCompany)">
    <mat-icon *ngIf="!meCompany.logoData">business</mat-icon>
    <img mat-card-avatar *ngIf="meCompany.logoData" [src]="sanitizeLogoUrl(meCompany)" [alt]="meCompany.companyName">
    <a fxFlex="100">{{meCompany.companyName}}</a>
  </button>
</mat-menu>

<mat-menu #createMenu="matMenu" [overlapTrigger]="false" class="app-sidenav-menu">
  <button *ngIf="authGuard.hasAnyAuthority(editorRoles)" mat-menu-item (click)="create('INDICATION_BOOKING')">
    <mat-icon color="primary">receipt</mat-icon>
    <span i18n>Indication - booking</span>
  </button>
  <button mat-menu-item (click)="create('HELP_DESK')">
    <mat-icon color="accent">help</mat-icon>
    <span i18n>Help desk</span>
  </button>
  <button mat-menu-item (click)="create('EXCEPTION_CLAIM')">
    <mat-icon color="warn">warning</mat-icon>
    <span i18n>Exception claim</span>
  </button>
</mat-menu>
