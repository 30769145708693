
import {of as observableOf, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter} from "@angular/core";
import {Issue, Currency} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormControl} from "@angular/forms";
import {AjaxService} from "../../../ajax/ajax.service";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-claim-form',
  templateUrl: 'issue-claim-form.component.html',
  styleUrls: ['issue-claim-form.component.scss']
})
export class IssueClaimFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  currencies: Array<Currency>;
  form: FormGroup;

  constructor(private ajaxService: AjaxService, private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    // INITIALIZE EMPTY FORM
    this.initForm();

    observableOf(
      this.ajaxService.findCurrencies().pipe(map((currencies: Array<Currency>) => {
        this.currencies = currencies;
      })).subscribe(any => {
        this.initForm();
      }));
  }

  initForm() {
    var claim = this.issue && this.issue.claim;
    this.form = new FormGroup({
      relatedParty: new FormControl(claim && claim.relatedParty || null),
      owner: new FormControl(claim && claim.owner || null),
      amount: new FormControl(claim && claim.amount || null),
      amountCurrencyId: new FormControl(claim && claim.amountCurrency && claim.amountCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null)
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {claim: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
