<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Exception</h2>
        <h2 *ngIf="issue?.exception">
          <span class="text-small color-light" i18n>Last modified by {{issue?.exception?.lastModifiedBy?.name}} {{issue?.exception?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row" fxLayoutGap="20px">

      <mat-form-field fxFlex="100">
        <mat-select formControlName="exceptionClaimId">
          <mat-option *ngFor="let option of exceptionClaims; trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
        </mat-select>
        <mat-placeholder i18n>Exception code</mat-placeholder>
      </mat-form-field>

    </mat-card-content>
  </mat-card>
</form>
