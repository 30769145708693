
import {Injectable} from '@angular/core';
import {viewTypes} from './view-types';
import {
  masterColumnDefinitions,
  issuesColumnDefinitions,
  supportColumnDefinitions,
  invoicesColumnDefinitions,
  usersColumnDefinitions
} from './default-column-orderings';

@Injectable()
export class ColumnDefinitionsService {
  readonly viewTypes = viewTypes;

  getColumnDefs(viewTypeID: number) {
    const viewType = this.viewTypes[viewTypeID];

    if (viewType === 'ISSUES') {
      return issuesColumnDefinitions;
    }
    else if (viewType === 'SUPPORT') {
      return supportColumnDefinitions;
    }
    else if (viewType === 'INVOICES') {
      return invoicesColumnDefinitions;
    }
    else if (viewType === 'USERS') {
      return usersColumnDefinitions;
    }
    else {
      return masterColumnDefinitions;
    }
  }
}
