<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Subject</h2>
        <h2 *ngIf="issue?.subject">
          <span class="text-small color-light" i18n>Last modified by {{issue?.subject?.lastModifiedBy?.name}} {{issue?.subject?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayoutGap="20px">
      <span fxLayout="row">
        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Subject</mat-placeholder>
          <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" formControlName="subject"></textarea>
        </mat-form-field>
      </span>

      <span fxLayout="row">
        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Question, free text</mat-placeholder>
          <textarea matInput matTextareaAutosize [matAutosizeMinRows]="6" formControlName="note"></textarea>
        </mat-form-field>
      </span>

    </mat-card-content>
  </mat-card>
</form>
