
import {from as observableFrom, Observable} from 'rxjs';

import {startWith} from 'rxjs/operators';
import {Component, OnInit, Input} from "@angular/core";
import {CustomsNumber, Currency, IssueDelivery, IssuePickup, Measure} from "../../issue.interface";
import {FormGroup, FormControl} from "@angular/forms";
import {IssueService} from "../../issue.service";

@Component({
  selector: 'app-issue-product-form',
  templateUrl: 'issue-product-form.component.html',
  styleUrls: ['issue-product-form.component.scss']
})
export class IssueProductFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() pickups: Array<IssuePickup>;
  @Input() deliveries: Array<IssueDelivery>;
  @Input() customsNumbers: Array<CustomsNumber>;
  @Input() lengthMeasures: Array<Measure>;
  @Input() massMeasures: Array<Measure>;
  @Input() currencies: Array<Currency>;
  @Input() editDisabled: boolean;

  public descriptionControl: FormControl;
  public customsNumberControl: FormControl;

  private searchText: String;
  public descriptions: Observable<any>;
  public filteredCustomsNumbers: Array<CustomsNumber>;


  constructor(private service: IssueService) {
    this.searchText = '';
  }

  ngOnInit() {
    this.descriptionControl = new FormControl({value: this.form.value.description, disabled: this.editDisabled}, { updateOn: 'change' });
    this.customsNumberControl = new FormControl({value: this.form.value.customsNumberId ? this.customsNumbers.find(c => {
      return c.id === this.form.value.customsNumberId;
    }) : null, disabled: this.editDisabled}, { updateOn: 'change' } );

    this.filteredCustomsNumbers = this.customsNumbers.slice();

    this.descriptionControl.valueChanges.pipe(
        startWith(null))
        .subscribe((val) => {
          if (val)
            this.descriptions = this.service.findProductDescriptions(val);
          else
            this.descriptions = observableFrom([]);
        });

    this.customsNumberControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.customsNumbers) {
          this.filteredCustomsNumbers = this.customsNumbers.filter(cn => {
            const term = cn.code.toString() + " " + cn.name;
            return term.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });
  }

  updateDescription() {
    this.form.patchValue({description: this.descriptionControl.value}, {onlySelf: false, emitEvent: true});
  }

  displayCustomsNumber(cn: CustomsNumber) : string {
    return cn ? cn.code.toString() + " " + cn.name : "";
  }

  selectCustomsNumber(cn: CustomsNumber): void {
    this.form.patchValue({customsNumberId: cn.id});
    this.customsNumberControl.setValue(cn);
  }

  checkCustomsNumberField(): void {
    //If the input field is empty on blur => null the value.
    if(!this.customsNumberControl.value) {
      this.form.patchValue({customsNumberId: null});
      this.customsNumberControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedCustomsNumber = this.customsNumbers.find(cn => cn.id === this.customsNumberControl.value.id);
      this.customsNumberControl.setValue(selectedCustomsNumber);
    }
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }
}
