import {Injectable} from "@angular/core";
import {bindCallback} from "rxjs";
import * as _ from "lodash";

@Injectable()
export class GPlaces {

  private autoCompleteService: any;
  private detailsService: any;

  private displaySuggestions(predictions, status): Array<any> {
    if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT)
      throw {};
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      return [];
    }
    return predictions;
  };

  private displayDetails(place, status): any {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      return place;
    }
    return {};
  }

  constructor() {
    let map = new google.maps.Map(document.createElement('div'));
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.detailsService = new google.maps.places.PlacesService(map);
  }

  private mapCountries(countries) {
    return encodeURIComponent(countries
      .map((country) => {
        return 'country:' + country;
      })
      .reduce((acc, val) => {
        if (acc) {
          return acc + '|' + val;
        } else {
          return val;
        }
      }));
  }

  public autoComplete(query, types, countries) {
    types = _.without(types, null, '');
    countries = _.without(countries, null, '');
    const cb = this.autoCompleteService.getPlacePredictions.bind(this.autoCompleteService);

    let getQuery: any;
    getQuery = bindCallback(cb, this.displaySuggestions);
    let queryObject = {
      input: query,
      types: types
    };
    if (countries && countries.length > 0) {
      Object.assign(queryObject, {componentRestrictions: {country: countries}});
    }
    return getQuery(queryObject);
  }

  public details(placeId) {
    const cb = this.detailsService.getDetails.bind(this.detailsService);
    let details: any;
    details = bindCallback(cb, this.displayDetails);
    return details({placeId: placeId});
  }
}
