import {Pipe} from "@angular/core";
import {isNumeric} from "rxjs/internal-compatibility";

@Pipe({
	name : "decimal"
})
export class Decimal {
  transform(value, arg1){
    if (isNumeric(value)) {
      var decimal = Number(value).toFixed(arg1);
      return String(decimal).replace('.', ',');
    }
    return value;
  }
}
