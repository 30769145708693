import {Component, OnInit, ViewChild, Input} from "@angular/core";
import * as Dropzone from "dropzone";

@Component({
  selector: 'app-dropzone',
  templateUrl: 'dropzone.component.html',
  styleUrls: ['dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {
  @ViewChild("dropzone", { static: true }) dropzone: Dropzone;
  @Input("url") url: string;
  @Input("acceptedFiles") acceptedFiles: string;

  constructor() {
  }

  ngOnInit() {
    // Get the access token from the local storage.
    const access_token = localStorage.getItem('LHP.access_token');
    const token_type = localStorage.getItem('LHP.token_type');

    setTimeout(() => {
      this.dropzone = new Dropzone('form.dropzone', {
        url: this.url,
        acceptedFiles: this.acceptedFiles,
        headers: {'Authorization': `${token_type} ${access_token}`},
        error: (file, message) => {
          throw new Error(`Error: ${message} uploading file ${file}`)
        }
      });
    }, 0);
  }
}
