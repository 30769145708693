import {Component, Input} from "@angular/core";
import {Issue} from "./issue.interface";

@Component({
  template: ''
})
export class IssueFormComponent {
  protected _issue: Issue;
  @Input()
  set issue(value: Issue) {
    let changed = this._issue !== undefined && this._issue.referenceNo && value.referenceNo !== this._issue.referenceNo;
    this._issue = value;
    if (changed) {
      this.initForm(this._issue);
    }
  }
  get issue() {
    return this._issue;
  }

  initForm(value: any) {
  }
}
