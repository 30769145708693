<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h2 i18n>Package descriptions</h2>

    <button *ngIf="!editDisabled" mat-button color="accent" (click)="addSubForm()">
      <h2 fxHide.lt-lg fxShow.gt-md i18n>Add package details</h2>
      <mat-icon fxHide.gt-md fxShow.lt-lg>add_circle</mat-icon>
    </button>
  </div>

  <div fxLayout="row">
    <div fxLayout="column" fxFlex="90px">
      <app-issue-detail-form *ngIf="issue" [issue]="issue" [inner]="true" [editDisabled]="editDisabled"></app-issue-detail-form>
    </div>
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total net weight</span>
      <span class="text-medium">{{totalNetWeight | decimal:2}} kg</span>
    </div>
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total cross weight</span>
      <span class="text-medium">{{totalGrossWeight | decimal:2}} kg</span>
    </div>
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total freight weight</span>
      <span class="text-medium">{{totalFreightWeight | decimal:2}} kg</span>
    </div>
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total volume</span>
      <span class="text-medium">{{totalVolume | decimal:2}} m3</span>
    </div>
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total ldm</span>
      <span class="text-medium">{{totalLoadingMeter | decimal:2}} ldm</span>
    </div>
  </div>

  <form *ngIf="issue && form" novalidate [formGroup]="form" style="margin-top: 20px">
    <div formArrayName="packages">
      <mat-accordion [multi]="false" displayMode="default">
        <mat-expansion-panel *ngIf="form.controls.packages?.length === 0" [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="text-small" i18n>There are no packages</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let subForm of form.controls.packages.controls; let i = index; trackBy: trackById" [expanded]="i === 0">
          <mat-expansion-panel-header>
            <mat-panel-title fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxLayout="column" fxFlex="60px">
                <span class="text-small" i18n>Package #{{i+1}}</span>
              </div>
              <div fxLayout="column">
                <span class="text-small">
                  <strong *ngIf="subForm.value.pickupId || subForm.value.deliveryId">
                    <span *ngIf="subForm.value.pickupId">{{findPropertyById(issue.pickups, subForm.value.pickupId, 'companyName')}}</span>
                    <span *ngIf="subForm.value.pickupId && subForm.value.deliveryId">-</span>
                    <span *ngIf="subForm.value.deliveryId">{{findPropertyById(issue.deliveries, subForm.value.deliveryId, 'companyName')}}</span>
                    <br>
                  </strong>
                  <span *ngIf="subForm.value.pieces && subForm.value.packageTypeId">
                    <span>{{subForm.value.pieces}} </span>
                    <span>{{findPropertyById(packageTypes, subForm.value.packageTypeId, 'type')}}, </span>
                  </span>
                  <span *ngIf="subForm.value.equipmentAmount && subForm.value.equipmentId">
                    <span>{{subForm.value.equipmentAmount}} </span>
                    <span>{{findPropertyById(equipments, subForm.value.equipmentId, 'type')}}, </span>
                  </span>
                  <span *ngIf="subForm.value.length && subForm.value.width && subForm.value.height">({{subForm.value.length | decimal:2}} x {{subForm.value.width | decimal:2}} x {{subForm.value.height | decimal:2}}) {{findPropertyById(lengthMeasures, subForm.value.lengthMeasureId, 'unit')}}, </span>
                  <span *ngIf="subForm.value.volume">{{subForm.value.volume | decimal:2}} {{findPropertyById(volumeMeasures, subForm.value.volumeMeasureId, 'unit')}}, </span>
                  <span *ngIf="subForm.value.loadingMeter">{{subForm.value.loadingMeter | decimal:2}} <span i18n>ldm</span>, </span>
                  <span *ngIf="subForm.value.netWeight">{{subForm.value.netWeight | decimal:2}} <span i18n>kg</span>, </span>
                  <span *ngIf="subForm.value.grossWeight">{{subForm.value.grossWeight | decimal:2}} <span i18n>kg</span>, </span>
                  <span *ngIf="subForm.value.freightWeight">{{subForm.value.freightWeight | decimal:2}} <span i18n>kg</span>, </span>
                  <span *ngIf="subForm.value.isStackable"><span i18n>Stackable</span></span>
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <mat-divider></mat-divider>
            <app-issue-package-form [form]="subForm" [pickups]="issue.pickups" [deliveries]="issue.deliveries" [lengthMeasures]="lengthMeasures" [volumeMeasures]="volumeMeasures" [packageTypes]="packageTypes" [equipments]="equipments" [editDisabled]="editDisabled"></app-issue-package-form>

            <h2 i18n>Special requirements</h2>
            <app-issue-special-requirement-form *ngIf="subForm.controls.specialRequirement.value.isOpen" [form]="subForm.controls.specialRequirement" [unClassifications]="unClassifications" [paymentTerms]="paymentTerms" [editDisabled]="editDisabled"></app-issue-special-requirement-form>
            <mat-divider *ngIf="!editDisabled"></mat-divider>
            <button *ngIf="!editDisabled" mat-button color="warn" (click)="removeSubForm(i)">
              <span i18n>DELETE PACKAGE</span>
            </button>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</div>
