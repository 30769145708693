import {Component, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy} from "@angular/core";
import * as moment from 'moment';

import {Formatter} from "../formatter/formatter";


@Component({
  selector: 'timepicker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'timepicker.html',
  styleUrls: ['timepicker.scss']
})
export class TimepickerComponent {
  @Input() time: any;
  @Input() placeholder: string;
  @Input() format: string;
  @Input() disabled: boolean;
  @Output() timeChange = new EventEmitter();

  public inputValue: any = '';
  public pickerValue: any = '';

  constructor(public formatter : Formatter) {
  }

  ngOnChanges(...args: any[]) {
    if (args.length > 0 && args[0].time) {
      if (args[0].time.currentValue) {
        var input = moment(args[0].time.currentValue, this.format);
        if (input.isValid()) {
          this.inputValue = input.format(this.format);
          this.pickerValue = input.format(this.format);
        }
      } else {
        this.inputValue = '';
        this.pickerValue = '';
      }
    }
  }

  pickerChanged(value : any) {
    this.inputValue = moment(value, this.format).format(this.format);
    this.timeChange.emit(this.inputValue);
  }

  refreshPicker(event : any) {
    var input = moment(event.target.value, this.format);
    if (input.isValid()) {
      this.pickerValue = input.format(this.format);
    } else {
      this.pickerValue = '';
    }
    this.timeChange.emit(this.pickerValue);
  }

}
