<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Sender</h2>
        <h2 *ngIf="issue?.sender">
          <span class="text-small color-light" i18n>Last modified by {{issue?.sender?.lastModifiedBy?.name}} {{issue?.sender?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>

    <mat-card-content fxLayout="column">
      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <input type="text"
            placeholder="Company"
            matInput
            [formControl]="companyControl"
            autocomplete="off"
            (blur)="delayedBlur(checkCompanyField.bind(this), 300)"
            #company
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectCompany($event.option.value)" [displayWith]="displaySender.bind(this)">
            <mat-option *ngFor="let option of filteredSenders; trackBy: trackById" [value]="option">{{option.name}} <span *ngIf="option.code">({{option.code}})</span></mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button *ngIf="!editDisabled" mat-icon-button type="button" (click)="copy()">
          <mat-icon>content_copy</mat-icon>
        </button>

        <mat-form-field fxFlex="200px">
          <mat-placeholder i18n>Name</mat-placeholder>
          <input matInput type="text" formControlName="companyName">
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <input type="text"
            placeholder="Country"
            matInput
            [formControl]="countryControl"
            autocomplete="off"
            (blur)="delayedBlur(checkCountryField.bind(this), 300)"
            #country
            [matAutocomplete]="autoCountry">
          <mat-autocomplete #autoCountry="matAutocomplete" (optionSelected)="selectCountry($event.option.value)" [displayWith]="displayCountry.bind(this)">
            <mat-option *ngFor="let option of filteredCountries; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field fxFlex="200px">
          <mat-placeholder i18n>Street address</mat-placeholder>
          <input type="text" matInput [formControl]="streetControl" [matAutocomplete]="streetAddress" (change)="form.patchValue({ streetAddress: $event.currentTarget.value })">
        </mat-form-field>
        <mat-autocomplete #streetAddress="matAutocomplete">
          <mat-option *ngFor="let option of streetAddresses | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['streetAddress', 'city', 'postalCode', 'state', 'country'])">
            {{ option.description }}
          </mat-option>
        </mat-autocomplete>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <div>
          <mat-form-field fxFlex="150px">
            <mat-placeholder i18n>Postal code</mat-placeholder>
            <input type="text" matInput [formControl]="postalControl" [matAutocomplete]="postal" (change)="form.patchValue({ postalCode: $event.target.value })">
          </mat-form-field>
          <mat-autocomplete #postal="matAutocomplete">
            <mat-option *ngFor="let option of postalCodes | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['city', 'postalCode', 'state', 'country'])">
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div>
          <mat-form-field fxFlex="150px">
            <mat-placeholder i18n>City</mat-placeholder>
            <input type="text" matInput [formControl]="cityControl" [matAutocomplete]="city" (change)="form.patchValue({ city: $event.target.value })">
          </mat-form-field>
          <mat-autocomplete #city="matAutocomplete">
            <mat-option *ngFor="let option of cities | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['city', 'postalCode', 'state', 'country'])">
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div>
          <mat-form-field fxFlex="150px">
            <mat-placeholder i18n>State</mat-placeholder>
            <input type="text" matInput [formControl]="stateControl" [matAutocomplete]="state" (change)="form.patchValue({ state: $event.target.value })">
          </mat-form-field>
          <mat-autocomplete #state="matAutocomplete">
            <mat-option *ngFor="let option of states | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['state', 'country'])">
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </span>

      <mat-form-field fxFlex="100">
        <mat-placeholder i18n>Notes, contact information</mat-placeholder>
        <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" type="text" formControlName="notes"></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>
