import {Component, OnInit, Input, ViewContainerRef} from "@angular/core";
import {DatePipe} from "@angular/common";
import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {IssueService} from "../../issue.service";
import {saveAs} from "file-saver";
import {IssueFormComponent} from "../../issue-form.component";
import {IssueReportParametersDialog} from "../issue-report-parameters-dialog";

@Component({
  selector: 'app-issue-report-form',
  templateUrl: 'issue-report-form.component.html',
  styleUrls: ['issue-report-form.component.scss']
})
export class IssueReportFormComponent extends IssueFormComponent implements OnInit {
  @Input() isMenu: boolean;

  constructor(private issueService: IssueService,
              private dialog: MatDialog,
              private viewContainerRef: ViewContainerRef) {
    super();
  }

  ngOnInit() {
  }

  showSpotPriceInquiryDialog() {
    let dialogRef: MatDialogRef<IssueReportParametersDialog>;
    let config = new MatDialogConfig();

    if ((this.issue.pickups && this.issue.pickups.length > 1) ||
        (this.issue.deliveries && this.issue.deliveries.length > 1))
    {
      config.viewContainerRef = this.viewContainerRef;
      dialogRef = this.dialog.open(IssueReportParametersDialog, config);
      dialogRef.componentInstance.issue = this.issue;

      dialogRef.afterClosed().subscribe(result => {
        this.exportSpotPriceInquiry(result && result.selectedPickupId || this.issue.pickups && this.issue.pickups[0].id,
          result && result.selectedDeliveryId || this.issue.deliveries && this.issue.deliveries[0].id);
      });
    } else {
      this.exportSpotPriceInquiry(this.issue.pickups && this.issue.pickups[0].id,
                          this.issue.deliveries && this.issue.deliveries[0].id);
    }
  }

  showTransportBookingDialog() {
    let dialogRef: MatDialogRef<IssueReportParametersDialog>;
    let config = new MatDialogConfig();

    if ((this.issue.pickups && this.issue.pickups.length > 1) ||
        (this.issue.deliveries && this.issue.deliveries.length > 1))
    {
      config.viewContainerRef = this.viewContainerRef;
      dialogRef = this.dialog.open(IssueReportParametersDialog, config);
      dialogRef.componentInstance.issue = this.issue;

      dialogRef.afterClosed().subscribe(result => {
        this.exportTransportBooking(result && result.selectedPickupId || this.issue.pickups && this.issue.pickups[0].id,
          result && result.selectedDeliveryId || this.issue.deliveries && this.issue.deliveries[0].id);
      });
    } else {
      this.exportTransportBooking(this.issue.pickups && this.issue.pickups[0].id,
                          this.issue.deliveries && this.issue.deliveries[0].id);
    }
  }

  exportSpotPriceInquiry(pickupId, deliveryId) {
    let filename = this.filename('Leafhill SPOT price inquiry');
    let report = `spot_price_inquiry.jrxml`;
    let payload = {
      filename: filename,
      report: report,
      issue_pickup_id: parseInt(pickupId),
      issue_delivery_id: parseInt(deliveryId)
    };
    this.issueService.reports(this.issue.referenceNo, payload).subscribe((file: File) => {
      saveAs(file, filename);
    });
  }

  exportTransportBooking(pickupId, deliveryId) {
    let filename = this.filename('Leafhill Transport Booking');
    let report = `transport_booking.jrxml`;
    let payload = {
      filename: filename,
      report: report,
      issue_pickup_id: parseInt(pickupId),
      issue_delivery_id: parseInt(deliveryId)    };
    this.issueService.reports(this.issue.referenceNo, payload).subscribe((file: File) => {
      saveAs(file, filename);
    });
  }

  filename(startsWith: string) {
    var datePipe = new DatePipe('en');
    var date = datePipe.transform(new Date(), 'yyyyMMddHHmm');
    return `${startsWith} ${this.issue.referenceNo} ${date}.pdf`;
  }
}
