<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="column">

      <input type="hidden" formControlName="id"/>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <input matInput type="text" formControlName="statisticalPeriod" [textMask]="{mask: formatter.statisticalPeriod.mask, pipe: formatter.statisticalPeriod.pipe, keepCharPositions: true, guide: true, placeholderChar: formatter.placeholderChar}"/>
          <mat-placeholder i18n>Statistical period (YYYY-MM)</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="transactionNatureId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of transactionNatures; trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Nature of transaction</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>Commodity code (CN8)</mat-placeholder>
          <input matInput type="text" formControlName="commodityCode">
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="150px">
          <input type="text"
            placeholder="Country of consigment"
            matInput
            [formControl]="consignmentCountryControl"
            autocomplete="off"
            (blur)="delayedBlur(checkConsignmentCountryField.bind(this), 300)"
            #consignmentCountry
            [matAutocomplete]="autoConsignmentCountry">
          <mat-autocomplete #autoConsignmentCountry="matAutocomplete" (optionSelected)="selectConsignmentCountry($event.option.value)" [displayWith]="displayCountry.bind(this)">
            <mat-option *ngFor="let option of filteredCountries; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <input type="text"
            placeholder="Country of origin"
            matInput
            [formControl]="countryControl"
            autocomplete="off"
            (blur)="delayedBlur(checkCountryField.bind(this), 300)"
            #country
            [matAutocomplete]="autoCountry">
          <mat-autocomplete #autoCountry="matAutocomplete" (optionSelected)="selectCountry($event.option.value)" [displayWith]="displayCountry.bind(this)">
            <mat-option *ngFor="let option of filteredCountries; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="transportMeanId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of transportMeans; trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Mode of transport (mean)</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.netMass"
          (valueChange)="form.patchValue({ netMass: $event})"
          placeholder="Net mass"
          suffix="kg"
          [disabled]="editDisabled">
        </integerinput>

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.supplementaryQuantity"
          (valueChange)="form.patchValue({ supplementaryQuantity: $event})"
          placeholder="Supplementary quantity"
          [disabled]="editDisabled">
        </integerinput>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="supplementaryUnitId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of supplementaryUnits; trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Supplementary unit</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <input matInput type="text" [value]="form.value.invoiceValue | decimal:0" disabled/>
          <mat-placeholder i18n>Invoice value</mat-placeholder>
          <span matSuffix>EUR</span>
        </mat-form-field>

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.statisticalValue"
          (valueChange)="form.patchValue({ statisticalValue: $event})"
          placeholder="Statistical value"
          suffix="EUR"
          [disabled]="editDisabled">
        </integerinput>

        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>Reference</mat-placeholder>
          <input matInput type="text" formControlName="reference">
        </mat-form-field>
      </span>

    </mat-card-content>
  </mat-card>
</form>
