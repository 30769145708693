
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, Input, EventEmitter, ViewChildren, QueryList} from "@angular/core";
import {Issue, IssueCost, Currency, Company} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {AjaxService} from "../../../ajax/ajax.service";
import {IssueFormComponent} from "../../issue-form.component";
import * as _ from "lodash";

@Component({
  selector: 'app-issue-costs-form',
  templateUrl: 'issue-costs-form.component.html',
  styleUrls: ['issue-costs-form.component.scss']
})
export class IssueCostsFormComponent extends IssueFormComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  logisticsServiceProviders: Array<Company>;
  currencies: Array<Currency>;
  form: FormGroup;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private ajaxService: AjaxService, private issueService: IssueService, private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    // INITIALIZE EMPTY FORM
    this.form = new FormGroup({
      costs: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });

    observableForkJoin([
      this.ajaxService.findLogisticsServiceProviders().pipe(map(lsps => this.logisticsServiceProviders = lsps)),
      this.ajaxService.findCurrencies().pipe(map(currencies => this.currencies = currencies))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  initForm() {
    this.form = new FormGroup({
      costs: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });
    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms(issue) {
    let subForms = [];
    if (issue.costs && issue.costs.length > 0) {
      issue.costs.forEach(cost => subForms.push(this.initSubForm(cost)));
    }
    return subForms;
  }

  initSubForm(cost: IssueCost): any {
    return new FormGroup({
      isOpen: new FormControl({value: cost ? false : true, disabled: this.editDisabled}),
      id: new FormControl({value: cost && cost.id || null, disabled: this.editDisabled}),
      filename: new FormControl({value: cost && cost.filename || null, disabled: this.editDisabled}),
      fileType: new FormControl({value: cost && cost.fileType || null, disabled: this.editDisabled}),
      filePath: new FormControl({value: cost && cost.filePath || null, disabled: this.editDisabled}),
      ranking: new FormControl({value: cost && cost.ranking || null, disabled: this.editDisabled}),
      logisticsServiceProviderId: new FormControl({value: cost && cost.logisticsServiceProvider && cost.logisticsServiceProvider.id || null, disabled: this.editDisabled}),
      cost: new FormControl({value: cost && cost.cost || null, disabled: this.editDisabled}),
      costCurrencyId: new FormControl({value: cost && cost.costCurrency && cost.costCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: this.editDisabled}),
      comparison: new FormControl({value: cost && cost.comparison || null, disabled: this.editDisabled}),
      note: new FormControl({value: cost && cost.note || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['costs'];
    control.push(this.initSubForm(null));
    setTimeout(() => {
      this.expansionPanels.last.open();
    });
  }

  toggleIsOpen(formGroup: FormGroup) {
    formGroup.patchValue({isOpen: !formGroup.value.isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['costs'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      const control = <FormArray>this.form.controls['costs'];
      control.controls.forEach((subForm: FormGroup, index: number) => {
        subForm.patchValue({id: issue.costs[index].id}, {emitEvent: false});
      });
      this.changeEmitter.emit(issue)
    });
  }

  findPropertyById(collection: any, id: number, property: string) {
    let object = _.find(collection, {id: Number(id)});
    return object && object.hasOwnProperty(property) ? object[property] : null;
  }

  onChange(issueCost: IssueCost) {
    const control = <FormArray>this.form.controls['costs'];
    control.controls.forEach((subForm: FormGroup) => {
      if (subForm.value.id == issueCost.id) {
        subForm.patchValue(this.initSubForm(issueCost), {emitEvent: false});
      }
    });
  }

  refreshStatus(issue) {
    if (!issue.costs) {
      issue.costs = [];
    }
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
