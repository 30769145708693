export const issueTypes = [
  'INDICATION_BOOKING',
  'HELP_DESK',
  'EXCEPTION_CLAIM',
  'ARRIVAL',
  'DEPARTURE'
] as const;

type issueType = typeof issueTypes[number];

export const issueTypeNames: Record<issueType, string> = {
  INDICATION_BOOKING: 'Indication booking',
  HELP_DESK: 'Help desk',
  EXCEPTION_CLAIM: 'Exception claim',
  ARRIVAL: 'Arrival',
  DEPARTURE: 'Departure'
} as const;
