import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {Issue} from "../issue.interface";
import {AuthGuardService} from "../../../modules/auth/auth-guard.service";
import {superRoles} from "../../../modules/auth/roles";
import {EmailService} from "../../../modules/email/email.service";
import {Email} from "../../../modules/email/email";
import {Subscription} from "rxjs";
import {NotificationService} from "../../notification/notification.service";
import {SnackBarService} from "../../snackbar/snackbar.service";

@Component({
  selector: 'app-issue-emails',
  templateUrl: 'issue-emails.component.html',
  styleUrls: ['issue-emails.component.scss']
})
export class IssueEmailsComponent implements OnInit, OnDestroy {
  @Input() issue: Issue;
  @Input() inner: boolean;
  superRoles = superRoles;
  emails: Array<Email>;
  onEmailActivity: Subscription;

  constructor(public authGuard: AuthGuardService,
              private emailService: EmailService,
              private snackBarService: SnackBarService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.onEmailActivity = this.notificationService.onEmailActivity.subscribe((msg) => {
      setTimeout(() => {
        this.fetch();
      });
    });
    this.fetch();
  }


  ngOnDestroy() {
    if (this.onEmailActivity)
      this.onEmailActivity.unsubscribe();
  }

  fetch() {
    this.emailService.findIssueEmails(this.issue.referenceNo).subscribe(emails => this.emails = emails);
  }
}
