<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="column">

      <input type="hidden" formControlName="id"/>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <mat-select formControlName="pickupId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of pickups; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Pickup place</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="deliveryId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of deliveries; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Delivery place</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <integerinput
          fxFlex="150px"
          [(value)]="form.value.pieces"
          (valueChange)="form.patchValue({ pieces: $event})"
          placeholder="Pieces"
          [disabled]="editDisabled">
        </integerinput>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="packageTypeId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of packageTypes; trackBy: trackById" [value]="option.id">{{option.type}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Package type</mat-placeholder>
        </mat-form-field>


        <div fxLayoutAlign="center center">
          <mat-checkbox type="text" formControlName="isStackable" color="primary">
            <mat-placeholder i18n>Stackable</mat-placeholder>
          </mat-checkbox>
        </div>
      </span>

      <strong i18n>Size and weight</strong>

      <span fxLayout="row" fxLayoutGap="20px">
        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.length"
          (valueChange)="form.patchValue({ length: $event })"
          placeholder="Length"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.width"
          (valueChange)="form.patchValue({ width: $event })"
          placeholder="Width"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.height"
          (valueChange)="form.patchValue({ height: $event })"
          placeholder="Height"
          [disabled]="editDisabled">
        </decimalinput>

        <mat-form-field fxFlex="130px">
          <mat-select formControlName="lengthMeasureId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of lengthMeasures; trackBy: trackById" [value]="option.id">{{option.unit}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Length measure</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.volume"
          (valueChange)="form.patchValue({ volume: $event === '' ? null : $event })"
          placeholder="Volume"
          [disabled]="editDisabled">
        </decimalinput>

        <mat-form-field fxFlex="130px">
          <mat-select formControlName="volumeMeasureId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of volumeMeasures; trackBy: trackById" [value]="option.id">{{option.unit}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Volume measure</mat-placeholder>
        </mat-form-field>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.loadingMeter"
          (valueChange)="form.patchValue({ loadingMeter: $event === '' ? null : $event })"
          placeholder="Loadmeter"
          suffix="ldm"
          [disabled]="editDisabled">
        </decimalinput>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.netWeight"
          (valueChange)="form.patchValue({ netWeight: $event })"
          placeholder="Net weight"
          suffix="kg"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="100px"
          [(value)]="form.value.grossWeight"
          (valueChange)="form.patchValue({ grossWeight: $event === '' ? null : $event })"
          placeholder="Gross weight"
          suffix="kg"
          [disabled]="editDisabled">
        </decimalinput>

        <decimalinput
          fxFlex="130px"
          [(value)]="form.value.freightWeight"
          (valueChange)="form.patchValue({ freightWeight: $event })"
          placeholder="Freight weight"
          suffix="kg"
          [disabled]="editDisabled">
        </decimalinput>

      </span>

      <strong i18n>Transport details</strong>

      <span fxLayout="row" fxLayoutGap="20px">

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.equipmentAmount"
          (valueChange)="form.patchValue({ equipmentAmount: $event})"
          placeholder="Equipment amount"
          [disabled]="editDisabled">
        </integerinput>

        <mat-form-field fxFlex="220px">
          <mat-select formControlName="equipmentId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of equipments; trackBy: trackById" [value]="option.id">{{option.type}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Equipment type</mat-placeholder>
        </mat-form-field>

      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Loading instructions</mat-placeholder>
          <input matInput rows="3" type="text" formControlName="loadingInstructions">
        </mat-form-field>
      </span>

    </mat-card-content>
  </mat-card>
</form>
