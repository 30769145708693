<mat-card class="app-card">
  <mat-card-header fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
      <h2 i18n>Report parameters</h2>
    </div>
  </mat-card-header>
  <mat-card-content>
    <span fxLayout="row">

      <mat-form-field *ngIf="issue.pickups && issue.pickups.length > 1">
        <mat-select class="link" [(ngModel)]="selectedPickupId">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let option of issue.pickups; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
        </mat-select>
        <mat-placeholder class="mat-placeholder" i18n>Pickup place</mat-placeholder>
      </mat-form-field>

      <span fxFlex="5"></span>

      <mat-form-field *ngIf="issue.deliveries && issue.deliveries.length > 1">
        <mat-select class="link" [(ngModel)]="selectedDeliveryId">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let option of issue.deliveries; let i = index; trackBy: trackById" [value]="option.id">#{{i+1}}&nbsp;{{option.companyName}}</mat-option>
        </mat-select>
        <mat-placeholder class="mat-placeholder" i18n>Delivery place</mat-placeholder>
      </mat-form-field>

    </span>

    <mat-dialog-actions align="center">
      <button mat-button outline [disabled]="(issue.deliveries && issue.deliveries.length > 1 && !selectedDeliveryId) ||
                                    (issue.pickups && issue.pickups.length > 1 && !selectedPickupId)" (click)="generate()">Generate PDF</button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
