<div class="user-form">
    <h3>{{formTitle}}</h3>
    <div fxLayout="column">
        <form [formGroup]="form">
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="45">
                <h4>User details</h4>
                <mat-form-field>
                  <mat-label for="email">Email</mat-label>
                  <input matInput type="email" name="email" formControlName="email" required email>
                </mat-form-field>
                <mat-form-field>
                  <mat-label for="firstName">First name</mat-label>
                  <input matInput name="firstName" formControlName="firstName" required>
                </mat-form-field>
                <mat-form-field>
                  <mat-label for="lastName">Last name</mat-label>
                  <input matInput name="lastName" formControlName="lastName" required>
                </mat-form-field>
              </div>
              <div fxFlexOffset="10"></div>
              <div fxLayout="column" fxFlex="45">
                <div class="password-info">
                  <h4>Password</h4>
                  <p>8-30 characters including: lowercase letter, uppercase letter, digit, special character</p>
                </div>
                <mat-form-field>
                  <mat-label for="password">Password</mat-label>
                  <input type="password" [formControl]="passwordControl" (blur)="checkPassword()" matInput name="password" required>
                </mat-form-field>
                <mat-form-field>
                  <mat-label for="passwordConfirm">Confirm password</mat-label>
                  <input type="password" [formControl]="passwordConfirmControl" (blur)="checkPassword()" matInput name="passwordConfirm" required>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="45">
                <h4>Contact information</h4>
                <mat-form-field>
                  <mat-label for="title">Title</mat-label>
                  <input matInput name="title" formControlName="title">
                </mat-form-field>
                <mat-form-field>
                  <mat-label for="phone">Phone number</mat-label>
                  <input type="tel" matInput name="phone" formControlName="phone">
                </mat-form-field>
                <mat-form-field>
                  <mat-label for="mobile">Mobile phone number</mat-label>
                  <input type="tel" matInput name="mobile" formControlName="mobile">
                </mat-form-field>
                <mat-form-field appearance="outline" class="notes-input">
                  <mat-label for="notes">Notes</mat-label>
                  <textarea matInput name="notes" rows="5" formControlName="notes"></textarea>
                </mat-form-field>
              </div>
              <div fxFlexOffset="10"></div>
              <div fxLayout="column" fxFlex="45" formArrayName="userCompanies">


                <h4>Main company and role</h4>
                <div fxLayout="row">
                  <div fxLayout="column">
                    <div>
                      <app-company-role-selection
                      [companies]="selectableCompanies[0]"
                      [userRoles]="userRoles"
                      [selected]="selectedCompanyRoles[0]"
                      (onCompanyRoleChange)="companyRoleChanged($event, 0)"></app-company-role-selection>
                    </div>
                  </div>
                </div>

                <h4>Other companies</h4>
                <div fxLayout="row">
                  <div fxLayout="column">
                    <div *ngFor="let c of userCompanies.controls.slice(1); index as i" fxLayout="row" fxLayoutAlign="space-between center">
                      <app-company-role-selection
                      [companies]="selectableCompanies[i+1]"
                      [userRoles]="userRoles"
                      [selected]="selectedCompanyRoles[i+1]"
                      (onCompanyRoleChange)="companyRoleChanged($event, i+1)"></app-company-role-selection>
                      <div (click)="removeCompanyRole(i+1)" style="cursor: pointer; padding: 10px 0 0 5px">
                        <mat-icon>close</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <button mat-button color="primary" style="text-align: left; padding: 0 !important;" (click)="addCompanyRole()">
                  <h3 style="padding-left: 10px;">Add new company</h3>
                </button>
              </div>
            </div>
        </form>

        <div class="form-buttons" style="justify-content: space-between;" fxLayout="row">
          <div>
            <button style="padding: 4px 25px; display: inline-block;" mat-stroked-button color="warn"
              (click)="clearForm()">
              Clear form
            </button>
            <mat-checkbox *ngIf="this.user" style="margin-left: 25px;"
              (change)="toggleUserActivity()"
              [(ngModel)]="isUserActive">Active user</mat-checkbox>
          </div>
          <div>
            <button *ngIf="!this.user" style="padding: 4px 25px; width: 150px !important; display: inline-block;" mat-flat-button color="primary"
              [disabled]="!isFormValid() && !isSubmitPending"
              (click)="submitForm()">
              Create user
            </button>
            <button *ngIf="this.user" style="padding: 4px 25px; width: 150px !important; display: inline-block;" mat-flat-button color="primary"
              [disabled]="!isFormValid() && !isSubmitPending"
              (click)="submitForm()">
              Update user
            </button>
          </div>
        </div>
    </div>
</div>
