import {issueColumnDefinitions} from './issue-column-definitions';
import {userColumnDefinitions} from './user-column-definitions';

// MASTER: all
// ISSUES: 0-6, 9-19
// SUPPORT: 0-11
// INVOICES: 0-19

export const masterColumnDefinitions: Array<Object> = [].concat([
  issueColumnDefinitions[0],
  issueColumnDefinitions[1],
  issueColumnDefinitions[2],
  issueColumnDefinitions[3],
  issueColumnDefinitions[4],
  issueColumnDefinitions[5],
  issueColumnDefinitions[6],
  issueColumnDefinitions[7],
  issueColumnDefinitions[8],
  issueColumnDefinitions[9],
  issueColumnDefinitions[10],
  issueColumnDefinitions[11],
  issueColumnDefinitions[12],
  issueColumnDefinitions[13],
  issueColumnDefinitions[14],
  issueColumnDefinitions[15]
  // issueColumnDefinitions[16],
  // issueColumnDefinitions[17],
  // issueColumnDefinitions[18]
]);

export const issuesColumnDefinitions: Array<Object> = [].concat([
  issueColumnDefinitions[0],
  issueColumnDefinitions[1],
  issueColumnDefinitions[2],
  issueColumnDefinitions[3],
  issueColumnDefinitions[4],
  issueColumnDefinitions[5],
  issueColumnDefinitions[9],
  issueColumnDefinitions[10],
  issueColumnDefinitions[11],
  issueColumnDefinitions[12],
  issueColumnDefinitions[13],
  issueColumnDefinitions[14],
  issueColumnDefinitions[15]
  // issueColumnDefinitions[16],
  // issueColumnDefinitions[17],
  // issueColumnDefinitions[18]
]);

export const supportColumnDefinitions: Array<Object> = [].concat([
  issueColumnDefinitions[0],
  issueColumnDefinitions[1],
  issueColumnDefinitions[2],
  issueColumnDefinitions[3],
  issueColumnDefinitions[4],
  issueColumnDefinitions[5],
  issueColumnDefinitions[6],
  issueColumnDefinitions[7]
]);

export const invoicesColumnDefinitions: Array<Object> = [].concat([
  issueColumnDefinitions[0],
  issueColumnDefinitions[1],
  issueColumnDefinitions[2],
  issueColumnDefinitions[3],
  issueColumnDefinitions[4],
  issueColumnDefinitions[5],
  issueColumnDefinitions[6],
  issueColumnDefinitions[7],
  issueColumnDefinitions[8],
  issueColumnDefinitions[9],
  issueColumnDefinitions[10],
  issueColumnDefinitions[11]
]);

export const usersColumnDefinitions: Array<Object> = [].concat([
  userColumnDefinitions[0],
  userColumnDefinitions[1],
  userColumnDefinitions[2],
  userColumnDefinitions[3],
  userColumnDefinitions[4],
  userColumnDefinitions[5],
  userColumnDefinitions[6],
  userColumnDefinitions[7],
  userColumnDefinitions[8],
  userColumnDefinitions[9],
  userColumnDefinitions[10],
  userColumnDefinitions[11],
  userColumnDefinitions[12],
  userColumnDefinitions[13],
  userColumnDefinitions[14],
  userColumnDefinitions[15],
  userColumnDefinitions[16],
  userColumnDefinitions[17],
  userColumnDefinitions[18],
  userColumnDefinitions[19],
  userColumnDefinitions[20],
  userColumnDefinitions[21],
  userColumnDefinitions[22]
]);
