<mat-card class="app-card" fxLayout="column">

  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
      <h2 i18n>Newest messages</h2>
      <h2>
        <a color="accent" routerLink="/browse" i18n>See all messages</a>
      </h2>
    </div>
  </mat-card-header>

  <mat-card-content fxLayout="column" *ngFor="let note of notes;">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span fxLayoutGap="5px" class="text-small app-title">
        <span>{{note.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        <span>{{note.lastModifiedBy?.name}}</span>
      </span>
      <span class="text-small color-light" *ngIf="authGuard.hasAnyAuthority(superRoles)">{{note.companyName}}</span>
    </div>
    <div fxLayout="row" class="app-subtitle text-small" fxLayoutGap="2px">
      <span i18n>Commented on</span>
      <strong>{{enumService.getIssueType(note.issueType)?.name}}</strong>
      <span fxFlex></span>
      <a [routerLink]="['/browse', note.referenceNo]">{{note.referenceNo}}</a>
    </div>
    <div fxLayout="row" class="app-subtitle">
      <span class="text-small">{{note.note}}</span>
    </div>
  </mat-card-content>

</mat-card>


