import {PipeTransform, Pipe} from "@angular/core";

/**
 * See https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7#gistcomment-1897702 for details
 *
 * The split.filter.join section is commented out to support full text highlight instead of partial words highlight.
 * The code block is left here for future if there comes a need for partial search term finding.
 */

@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    search = search ? search : '';
    let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    // pattern = pattern.split(' ').filter((t) => {
    //   return t.length > 0;
    // }).join('|');
    let regex = new RegExp(pattern, 'gi');

    text = text ? text : '';
    return search ? text.replace(regex, (match) => `<span class="highlight">${match}</span>`) : text;
  }
}
