import {Component, OnInit, Input} from "@angular/core";
import {IssueDelivery, IssuePickup, Measure, Equipment, PackageType} from "../../issue.interface";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-issue-package-form',
  templateUrl: 'issue-package-form.component.html',
  styleUrls: ['issue-package-form.component.scss']
})
export class IssuePackageFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() pickups: Array<IssuePickup>;
  @Input() deliveries: Array<IssueDelivery>;
  @Input() lengthMeasures: Array<Measure>;
  @Input() volumeMeasures: Array<Measure>;
  @Input() packageTypes: Array<PackageType>;
  @Input() equipments: Array<Equipment>;
  @Input() editDisabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  trackById = (index, item) => {
    return item.id;
  }
}
