<form novalidate [formGroup]="form">
  <mat-card class="app-card" [ngClass]="{'inner': inner}">
    <mat-card-header fxLayoutAlign="space-between" *ngIf="!inner">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Detail</h2>
        <h2 *ngIf="issue?.detail">
          <span class="text-small color-light" i18n>Last modified by {{issue?.detail?.lastModifiedBy?.name}} {{issue?.detail?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row">

      <decimalinput [(value)]="form.value.totalWeight"
                    (valueChange)="form.patchValue({ totalWeight: $event })"
                    placeholder="Total weight"
                    floatLabel="always"
                    suffix="kg"
                    [disabled]="editDisabled"></decimalinput>

    </mat-card-content>
  </mat-card>
</form>
