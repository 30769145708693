
import {map, startWith} from 'rxjs/operators';
import {Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter} from "@angular/core";
import {FormGroup, FormControl} from "@angular/forms";
import {Company, Currency, Issue, IssueCost} from "../../issue.interface";
import {Formatter} from "../../../formatter/formatter";
import {IssueService} from "../../issue.service";
import {IssueFormComponent} from "../../issue-form.component";
import * as _ from "lodash";
import {Observable} from "rxjs";
import {AjaxService} from "../../../ajax/ajax.service";
import {saveAs} from "file-saver";

var self;

@Component({
  selector: 'app-issue-cost-form',
  templateUrl: 'issue-cost-form.component.html',
  styleUrls: ['issue-cost-form.component.scss']
})
export class IssueCostFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<IssueCost> = new EventEmitter<IssueCost>();
  @Input() form: FormGroup;
  @Input() currencies: Array<Currency>;
  @Input() editDisabled: boolean;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() logisticsServiceProviders: Array<Company> = [];
  logisticsServiceProvider: FormControl;
  filteredProviders: Observable<Company[]>;

  constructor(public formatter: Formatter, private ajaxService: AjaxService, private issueService: IssueService) {
    super();
    self = this;
  }

  ngOnInit() {
    this.logisticsServiceProvider = new FormControl({value: null, disabled: this.editDisabled});
    this.filteredProviders = this.logisticsServiceProvider.valueChanges.pipe(
      startWith(null),
      map(val => val ? this.filter(val) : this.logisticsServiceProviders.slice()),);

    this.init();
  }

  init() {
    let val = this.findPropertyById(this.logisticsServiceProviders, this.form.value.logisticsServiceProviderId, 'name');
    this.logisticsServiceProvider.setValue(val);
  }

  findPropertyById(collection: any, id: number, property: string) {
    let object = _.find(collection, {id: Number(id)});
    return object && object.hasOwnProperty(property) ? object[property] : null;
  }

  filter(val: string): Company[] {
    return this.logisticsServiceProviders.filter(option => new RegExp(`${val}`, 'gi').test(option.name));
  }

  displaySP(sp: any) {
    if (sp === null || sp === undefined) {
      return sp;
    }

    if (sp.name) {
      return sp.name;
    }

    var x = self.logisticsServiceProviders.filter(i => { return i.name === sp; })
      .map(i => i.name);
    return x[0];
  }

  resetLSP() {
    var ctrl = this.logisticsServiceProvider;
    if (ctrl.value === undefined || ctrl.value === null || ctrl.value === '')
      this.form.patchValue({ logisticsServiceProviderId: null });
  }

  updateLSP(value: string, event: any) {
    // Two events will be fired on selection: deselect old and select new value
    if (event.source.selected === true)
      this.form.patchValue({ logisticsServiceProviderId: value });
  }

  upload(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.issueService.upload(this.issue.referenceNo, 'COSTS', file, this.form.value.id).subscribe((issue: Issue) => {

        let issueCost = _.find(issue.costs, {id: this.form.value.id});

        this.form.patchValue({
          filename: issueCost.filename,
          fileType: issueCost.fileType,
          filePath: issueCost.filePath
        }, {emitEvent: false});
        this.fileInput.nativeElement.value = '';
        this.changeEmitter.emit(issueCost);
      });
    }
  }

  download(filename: string, fileType: string, filePath: string) {
    this.issueService.download(this.issue.referenceNo, filename, fileType, filePath).subscribe((file: File) => {
      saveAs(file, filename);
    });
  }

  trackById = (index, item) => {
    return item.id;
  }
}
