import {Component, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef} from "@angular/core";
import * as moment from 'moment';

import {Formatter} from "../formatter/formatter";


@Component({
  selector: 'datepicker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'datepicker.html'
})
export class DatepickerComponent {
  @Input() date: any;
  @Input() placeholder: string;
  @Input() format: string;
  @Input() disabled: boolean;
  @Output() dateChange = new EventEmitter();

  public inputValue: any = null;
  public pickerValue: any = null;

  constructor(public formatter : Formatter,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges(...args: any[]) {
    if (args.length > 0 && args[0].date) {
      if (this.isDate(args[0].date.currentValue)) {
        let input = moment(args[0].date.currentValue, 'YYYY-MM-DD');
        if (input.isValid()) {
          this.inputValue = input.format('DD.MM.YYYY');
          this.pickerValue = input.toDate();
        }
      } else {
        this.inputValue = null;
        this.pickerValue = null;
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.changeDetectorRef.markForCheck()
    }, 0);
  }

  private isDate(value: any) {
      return value && moment(value).isValid();
  }

  refreshPicker(event : any) {
    let input = moment(event.target.value, 'DD.MM.YYYY');
    if (input.isValid()) {
      this.pickerValue = input.format('YYYY-MM-DD');
    } else {
      this.pickerValue = null;
    }

    this.dateChange.emit(this.pickerValue);
  }

}
