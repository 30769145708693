<mat-form-field *ngIf="issue.createdBy != issue.lastModifiedBy && issue.createdDate != issue.lastModifiedDate && issue.lastModifiedBy">
  <mat-placeholder i18n>Last modified by</mat-placeholder>
  <mat-hint align="end">{{issue.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</mat-hint>
  <input matInput value="{{issue.lastModifiedBy?.name}}" disabled/>
</mat-form-field>
<mat-form-field *ngIf="issue.createdBy != null">
  <mat-placeholder i18n>Created by</mat-placeholder>
  <mat-hint align="end">{{issue.createdDate | date:'dd.MM.yyyy HH:mm:ss'}}</mat-hint>
  <input matInput value="{{issue.createdBy?.name}}" disabled/>
</mat-form-field>
