import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Email} from "./email";
import {SortablePageRequest} from "../../shared/api/api.interface";

@Directive()
@Injectable()
export class EmailService {

  private email: Email;
  @Output() onEmailUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) {
    this.getEmail();
  }

  getEmail() {
    if (localStorage.getItem('net.leafhill.email')) {
      this.email = JSON.parse(localStorage.getItem('net.leafhill.email'));
    }
    return this.email;
  }

  setEmail(email?: any): any {
    if (email) {
      this.email = email;
      localStorage.setItem('net.leafhill.email', JSON.stringify(email));
    } else {
      delete this.email;
      localStorage.removeItem('net.leafhill.email');
    }
    this.onEmailUpdated.next();
    return this.getEmail();
  }

  /**
   * API methods
   */

  findPages(request: SortablePageRequest<any>): Observable<any> {
    let params = new HttpParams()
      .set('page', request.page)
      .set('size', request.size)
      .set('sort', request.sort)
      .set('types', request['types'])
      .set('searchTerm', request['searchTerm']);

    if (request.order) {
      for (let order of request.order) {
        params = params.append('order', order);
      }
    }

    if (request['isDraft']) {
      params = params.append('isDraft', request['isDraft']);
    }

    if (request['isSent']) {
      params = params.append('isSent', request['isSent']);
    }

    if (request['isReceived']) {
      params = params.append('isReceived', request['isReceived']);
    }

    if (request['isUnread']) {
      params = params.append('isUnread', request['isUnread']);
    }

    if (request['isStarred']) {
      params = params.append('isStarred', request['isStarred']);
    }

    if (request['isError']) {
      params = params.append('isError', request['isError']);
    }

    if (request['companies']) {
      for (let company of request['companies']) {
        params = params.append('company', company);
      }
    }

    return this.http.get('/api/emails/pages', {params});
  }

  findIssueEmails(referenceNo: string): Observable<any> {
    return this.http.get(`/api/emails/issues/${referenceNo}`);
  }

  upsertEmail(email: any): Observable<any> {
    return this.http.patch(`/api/emails`, email);
  }

  attachFile(uuid: string, storageType: string, file: File, id?: number): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('storageType', storageType);
    formData.append('file', file, file.name);
    if (id) {
      formData.append('id', String(id));
    }
    return this.http.post(`/api/emails/${uuid}/attach`, formData);
  }

  detachFile(uuid: string, id: number): Observable<any> {
    return this.http.delete(`/api/emails/${uuid}/detach/${id}`);
  }

  downloadFile(uuid: string, filename: string, fileType: string, filePath: string): Observable<any> {
    let params = new HttpParams()
      .set('filePath', filePath);
    return this.http.get(`/api/emails/${uuid}/download`, {params, responseType: 'blob'});
  }

  deleteEmail(uuid: string): Observable<any> {
    return this.http.delete(`/api/emails/${uuid}`, {params: {uuid: uuid}});
  }
}
