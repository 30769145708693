
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input} from "@angular/core";
import {
  Issue,
  IssuePackage,
  Measure,
  PackageType,
  Equipment,
  UnClassification,
  PaymentTerm
} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import * as _ from "lodash";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-packages-form',
  templateUrl: 'issue-packages-form.component.html',
  styleUrls: ['issue-packages-form.component.scss']
})
export class IssuePackagesFormComponent extends IssueFormComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  lengthMeasures: Array<Measure>;
  volumeMeasures: Array<Measure>;
  packageTypes: Array<PackageType>;
  equipments: Array<Equipment>;
  unClassifications: Array<UnClassification>;
  paymentTerms: Array<PaymentTerm>;
  form: FormGroup;

  totalVolume: number;
  totalNetWeight: number;
  totalGrossWeight: number;
  totalFreightWeight: number;
  totalLoadingMeter: number;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private ajaxService: AjaxService, private issueService: IssueService, private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      packages: new FormArray(this.initSubForms(null))
    }, { updateOn: 'blur' });

    // TODO consider to refactor the dirty duplicate initialization of form used to populate form with resolved observable values
    observableForkJoin([
      this.ajaxService.findMeasures('length').pipe(map((measures: Array<Measure>) => this.lengthMeasures = measures)),
      this.ajaxService.findMeasures('volume').pipe(map((measures: Array<Measure>) => this.volumeMeasures = measures)),
      this.ajaxService.findPackageTypes().pipe(map((packageTypes: Array<PackageType>) => this.packageTypes = packageTypes)),
      this.ajaxService.findEquipments().pipe(map((equipments: Array<Equipment>) => this.equipments = equipments)),
      this.ajaxService.findUnClassifications().pipe(map((unClassifications: Array<UnClassification>) => this.unClassifications = unClassifications)),
      this.ajaxService.findPaymentTerms().pipe(map((paymentTerms: Array<PaymentTerm>) => this.paymentTerms = paymentTerms))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  initForm() {
    this.calculate();
    this.form = new FormGroup({
      packages: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms(issue: Issue) {
    let subForms = [];
    if (issue) {
      if (issue.packages && issue.packages.length > 0) {
        issue.packages.forEach(issuePackage => subForms.push(this.initSubForm(issuePackage)));
      } else {
        subForms.push(this.initSubForm());
      }
    }
    return subForms;
  }

  initSubForm(issuePackage?: IssuePackage) {
    let specialRequirement = issuePackage && issuePackage.specialRequirement;

    let subForm = new FormGroup({
      isOpen: new FormControl({value: issuePackage ? false : true, disabled: this.editDisabled}),
      id: new FormControl({value: issuePackage && issuePackage.id || null, disabled: this.editDisabled}),
      pickupId: new FormControl({value: issuePackage && issuePackage.pickup && issuePackage.pickup.id || this.issue.pickups && this.issue.pickups[0].id || null, disabled: this.editDisabled}),
      deliveryId: new FormControl({value: issuePackage && issuePackage.delivery && issuePackage.delivery.id || this.issue.deliveries && this.issue.deliveries[0].id || null, disabled: this.editDisabled}),
      pieces: new FormControl({value: issuePackage && issuePackage.pieces || 1, disabled: this.editDisabled}),
      packageTypeId: new FormControl({value: issuePackage && issuePackage.packageType && issuePackage.packageType.id || null, disabled: this.editDisabled}),
      length: new FormControl({value: issuePackage && issuePackage.length || null, disabled: this.editDisabled}),
      width: new FormControl({value: issuePackage && issuePackage.width || null, disabled: this.editDisabled}),
      height: new FormControl({value: issuePackage && issuePackage.height || null, disabled: this.editDisabled}),
      lengthMeasureId: new FormControl({value: issuePackage && issuePackage.lengthMeasure && issuePackage.lengthMeasure.id || this.ajaxService.defaultLengthUnitId(this.lengthMeasures) || null, disabled: this.editDisabled}),
      volume: new FormControl({value: issuePackage && issuePackage.volume || null, disabled: this.editDisabled}),
      volumeMeasureId: new FormControl({value: issuePackage && issuePackage.volumeMeasure && issuePackage.volumeMeasure.id || this.ajaxService.defaultVolumeUnitId(this.volumeMeasures) || null, disabled: this.editDisabled}),
      loadingMeter: new FormControl({value: issuePackage && issuePackage.loadingMeter || null, disabled: this.editDisabled}),
      netWeight: new FormControl({value: issuePackage && issuePackage.netWeight || null, disabled: this.editDisabled}),
      grossWeight: new FormControl({value: issuePackage && issuePackage.grossWeight || null, disabled: this.editDisabled}),
      freightWeight: new FormControl({value: issuePackage && issuePackage.freightWeight || null, disabled: this.editDisabled}),
      isStackable: new FormControl({value: issuePackage && issuePackage.isStackable ? true : false, disabled: this.editDisabled}),
      equipmentAmount: new FormControl({value: issuePackage && issuePackage.equipmentAmount || 1, disabled: this.editDisabled}),
      equipmentId: new FormControl({value: issuePackage && issuePackage.equipment && issuePackage.equipment.id || null, disabled: this.editDisabled}),
      loadingInstructions: new FormControl({value: issuePackage && issuePackage.loadingInstructions || null, disabled: this.editDisabled}),
      specialRequirement: new FormGroup({
        isOpen: new FormControl({value: true, disabled: this.editDisabled}),
        id: new FormControl({value: specialRequirement && specialRequirement.id || null, disabled: this.editDisabled}),
        dangerousGoods: new FormControl({value: specialRequirement && specialRequirement.dangerousGoods || null, disabled: this.editDisabled}),
        unClassificationId: new FormControl({value: specialRequirement && specialRequirement.unClassification && specialRequirement.unClassification.id || null, disabled: this.editDisabled}),
        adrNumber: new FormControl({value: specialRequirement && specialRequirement.adrNumber || null, disabled: this.editDisabled}),
        packingGroup: new FormControl({value: specialRequirement && specialRequirement.packingGroup || null, disabled: this.editDisabled}),
        isProtectFromMoisture: new FormControl({value: specialRequirement && specialRequirement.isProtectFromMoisture || null, disabled: this.editDisabled}),
        temperatureMin: new FormControl({value: specialRequirement && specialRequirement.temperatureMin || null, disabled: this.editDisabled}),
        temperatureMax: new FormControl({value: specialRequirement && specialRequirement.temperatureMax || null, disabled: this.editDisabled}),
        paymentTermId: new FormControl({value: specialRequirement && specialRequirement.paymentTerm && specialRequirement.paymentTerm.id || null, disabled: this.editDisabled}),
        other: new FormControl({value: specialRequirement && specialRequirement.other || null, disabled: this.editDisabled})
      }, { updateOn: 'blur' })
    }, { updateOn: 'blur' });
    return subForm;
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['packages'];
    control.push(this.initSubForm(null));
    setTimeout(() => {
      this.expansionPanels.last.open();
    });
  }

  toggleIsOpen(formGroup: FormGroup) {
    formGroup.patchValue({isOpen: !formGroup.value.isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['packages'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      const control = <FormArray>this.form.controls['packages'];
      control.controls.forEach((subForm: FormGroup, index: number) => {
        subForm.patchValue({id: issue.packages[index].id}, {emitEvent: false});
      });
      this.changeEmitter.emit(issue);
      this.issue.packages = issue.packages;
      this.calculate();
    });
  }

  findPropertyById(collection: any, id: number, property: string) {
    let object = _.find(collection, {id: Number(id)});
    return object && object.hasOwnProperty(property) ? object[property] : null;
  }

  calculate() {
    if (this.issue.packages) {
      this.totalVolume = this.issue.packages.filter(item => item && item.volume).map(item => Number(item.volume)).reduce(this.reducer, 0);
      this.totalNetWeight = this.issue.packages.filter(item => item && item.netWeight).map(item => Number(item.netWeight)).reduce(this.reducer, 0);
      this.totalGrossWeight = this.issue.packages.filter(item => item && item.grossWeight).map(item => Number(item.grossWeight)).reduce(this.reducer, 0);
      this.totalFreightWeight = this.issue.packages.filter(item => item && item.freightWeight).map(item => Number(item.freightWeight)).reduce(this.reducer, 0);
      this.totalLoadingMeter = this.issue.packages.filter(item => item && item.loadingMeter).map(item => Number(item.loadingMeter)).reduce(this.reducer, 0);
    } else {
      this.totalVolume = undefined;
      this.totalNetWeight = undefined;
      this.totalGrossWeight = undefined;
      this.totalFreightWeight = undefined;
      this.totalLoadingMeter = undefined;
    }
  }

  reducer(accumulator, currentValue) {
    return accumulator + currentValue;
  }

  refreshStatus(issue) {
    if (!issue.packages) {
      issue.packages = [];
    }
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
