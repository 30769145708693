<div *ngIf="issue?.links" fxLayout="column">
  <small i18n>Linked issues</small>
  <div fxLayout="row">
    <ng-container *ngFor="let link of issue?.links; trackBy: trackById">
      <span class="text-medium">{{link.referenceNo}} ({{enums.getIssueType(link.type)?.name}})
        <button mat-icon-button routerLink="/issues/{{link.referenceNo}}">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </span>
    </ng-container>
    <ng-container *ngFor="let link of issue?.linkedFrom; trackBy: trackById">
      <span class="text-medium">{{link.linkedReferenceNo}} ({{enums.getIssueType(link.linkedType)?.name}})
        <button mat-icon-button class="rotate180" routerLink="/issues/{{link.linkedReferenceNo}}">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </span>
    </ng-container>
  </div>
</div>
