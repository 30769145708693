<div fxLayout="column" *ngIf="emailForm">

  <hr *ngIf="email?.uuid"/>

  <form [formGroup]="emailForm" fxLayout="column" novalidate autocomplete="off">

    <div fxLayout="row" fxLayoutGap="20px">

      <mat-form-field class="email-field" fxFlex="70">
        <mat-label i18n>To</mat-label>
        <mat-chip-list #toChipList [disabled]="!isEditable">
          <mat-chip *ngFor="let recipient of emailForm.getRawValue().to"
                    [disabled]="!isEditable"
                    [selectable]="false"
                    [removable]="isEditable"
                    (removed)="remove(emailForm.controls.to, recipient)">
            {{recipient}}
            <mat-icon matChipRemove *ngIf="isEditable">cancel</mat-icon>
          </mat-chip>
          <input #toInput [disabled]="!isEditable"
                 [formControl]="addressCtrl"
                 [matAutocomplete]="toAuto"
                 [matChipInputFor]="toChipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="add(emailForm.controls.to, $event)">
        </mat-chip-list>
        <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="select(emailForm.controls.to, $event.option, toInput)">
          <mat-option *ngFor="let address of filteredAddresses | async" [value]="address">{{address}}</mat-option>
        </mat-autocomplete>

        <a class="email-toggle-recipient link" [ngClass]="{'first': !isShowCc && !isShowBcc, 'second': !isShowCc && isShowBcc}" *ngIf="!isShowCc" (click)="isShowCc = true">Cc</a>
        <a class="email-toggle-recipient link" [ngClass]="{'second': !isShowBcc}" *ngIf="!isShowBcc" (click)="isShowBcc = true">Bcc</a>
      </mat-form-field>

      <mat-form-field class="email-field" fxFlex="30">
        <mat-label i18n>Sender</mat-label>
        <mat-chip-list #senderChipList disabled>
          <mat-chip [disabled]="true" [selectable]="false" [removable]="false">{{emailForm.getRawValue().sender}}</mat-chip>
        </mat-chip-list>
        <mat-hint align="end">(Sender used as Reply To)</mat-hint>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" *ngIf="emailForm.getRawValue().cc?.length > 0 || isShowCc">
      <mat-form-field class="email-field" fxFlex="70">
        <mat-label i18n>Cc</mat-label>
        <mat-chip-list #ccChipList [disabled]="!isEditable">
          <mat-chip *ngFor="let recipient of emailForm.getRawValue().cc"
                    [disabled]="!isEditable"
                    [selectable]="false"
                    [removable]="isEditable"
                    (removed)="remove(emailForm.controls.cc, recipient)">
            {{recipient}}
            <mat-icon matChipRemove *ngIf="isEditable">cancel</mat-icon>
          </mat-chip>
          <input #ccInput [disabled]="!isEditable"
                 [formControl]="addressCtrl"
                 [matAutocomplete]="ccAuto"
                 [matChipInputFor]="ccChipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="add(emailForm.controls.cc, $event)">
        </mat-chip-list>
        <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="select(emailForm.controls.cc, $event.option, ccInput)">
          <mat-option *ngFor="let address of filteredAddresses | async" [value]="address">{{address}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span fxFlex="30"></span>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" *ngIf="emailForm.getRawValue().bcc?.length > 0 || isShowBcc">
      <mat-form-field class="email-field" fxFlex="70">
        <mat-label i18n>Bcc</mat-label>
        <mat-chip-list #bccChipList [disabled]="!isEditable">
          <mat-chip *ngFor="let recipient of emailForm.getRawValue().bcc"
                    [disabled]="!isEditable"
                    [selectable]="false"
                    [removable]="isEditable"
                    (removed)="remove(emailForm.controls.bcc, recipient)">
            {{recipient}}
            <mat-icon matChipRemove *ngIf="isEditable">cancel</mat-icon>
          </mat-chip>
          <input #bccInput [disabled]="!isEditable"
                 [formControl]="addressCtrl"
                 [matAutocomplete]="bccAuto"
                 [matChipInputFor]="bccChipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="add(emailForm.controls.bcc, $event)">
        </mat-chip-list>
        <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="select(emailForm.controls.bcc, $event.option, bccInput)">
          <mat-option *ngFor="let address of filteredAddresses | async" [value]="address">{{address}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span fxFlex="30"></span>
    </div>

    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field class="email-field" fxFlex="70">
        <mat-label i18n>Subject</mat-label>
        <input matInput formControlName="subject" name="subject">
      </mat-form-field>
      <mat-form-field class="email-field" fxFlex="30" [formGroup]="searchForm" *ngIf="!emailForm.getRawValue().referenceNo">
        <mat-label i18n>Issue</mat-label>
        <input matInput type="text" matInput formControlName="searchTerm" name="searchTerm" [matAutocomplete]="searchTermAuto" [disabled]="!isLinkable">
        <mat-autocomplete #searchTermAuto (optionSelected)="linkIssue($event.option.value.referenceNo)">
          <mat-option *ngFor="let issue of issues" [value]="issue">{{issue.referenceNo}}</mat-option>
        </mat-autocomplete>
        <mat-hint align="end">(Not linked to any issue)</mat-hint>
      </mat-form-field>
      <mat-form-field class="email-field" fxFlex="30" *ngIf="emailForm.getRawValue().referenceNo">
        <mat-label i18n>Issue</mat-label>
        <mat-chip-list #referenceNoChipList [disabled]="!isLinkable">
          <mat-chip [disabled]="!isLinkable" [selectable]="false" [removable]="isLinkable" (removed)="unlinkIssue()">{{emailForm.getRawValue().referenceNo}}<mat-icon matChipRemove *ngIf="isLinkable">cancel</mat-icon></mat-chip>
        </mat-chip-list>
        <mat-hint align="end">(Linked to {{emailForm.getRawValue().referenceNo}})</mat-hint>
      </mat-form-field>
    </div>

    <quill-editor #editor formControlName="html" name="html" format="html" [readOnly]="!isEditable" [modules]="quillModules" [class.hide-toolbar]="!isEditable" (onContentChanged)="onContentChanged($event)"></quill-editor>

  </form>

  <!-- ATTACHMENTS -->
  <div fxLayout="column">
    <form class="email-attachment" *ngFor="let attachment of email.attachments">
      <a class="link" color="accent" (click)="download(attachment)">
        <mat-icon>attachment</mat-icon>
        <span>{{attachment.filename}} ({{attachment.fileType}})</span>
      </a>
      <a class="link" color="warn" *ngIf="isEditable" (click)="removeAttachment(attachment)"><mat-icon>delete_outline</mat-icon></a>
    </form>
  </div>

  <div fxLayout="row" fxLayoutAlign="end" *ngIf="isEditable">
    <input #fileInput type="file" style="display: none;" (change)="addAttachment($event)" [disabled]="!isAttachable">
    <a color="accent" [class.disabled]="!isAttachable" class="link" (click)="fileInput.click()" matTooltipPosition="before" [matTooltip]="isAttachable ? '' : 'Save as draft to add attachments'">Attach a file <mat-icon>attach_file</mat-icon></a>
  </div>

  <hr *ngIf="isEditable || isDeletable"/>

  <div fxLayout="row" fxLayoutGap="20px" *ngIf="isEditable || isDeletable">
    <button mat-button color="warn" [disabled]="!isDeletable || !email.uuid" (click)="delete()">
      <span i18n>Delete email</span>
    </button>
    <span fxFlex></span>

    <span fxFlex></span>
    <button *ngIf="isBeingSent()" mat-button color="accent" (click)="undoSend()" i18n>Undo</button>
    <button *ngIf="isDraft()" mat-button color="accent" (click)="resetForm()" [disabled]="isResetDisabled()" i18n>Undo</button>
    <button *ngIf="isDraft()" mat-raised-button color="accent" (click)="saveDraft()" [disabled]="emailForm.invalid" i18n>Save</button>
    <button *ngIf="isDraft()" mat-raised-button color="primary" (click)="send()" [disabled]="emailForm.invalid || !isReady()" i18n>Send</button>
  </div>

</div>
