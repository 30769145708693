
import {timer as observableTimer, of as observableOf, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import {SnackBarService} from "../../shared/snackbar/snackbar.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Router} from "@angular/router";
import {roleType} from "./roles";

@Directive()
@Injectable()
export class AuthService {

  private jwtHelper = new JwtHelperService();
  private refreshSubscription: any;
  @Output() onAuthUpdated: EventEmitter<void> = new EventEmitter<void>();

  private auth = {
    'scope': ['API'],
    'client_secret': ['LHP'],
    'client_id': ['LHP']
  };

  constructor(private http: HttpClient,
              private router: Router,
              public snackBarService: SnackBarService) {
  }

  public login(username: string, password: string, rememberme: boolean): void {
    // Authentication API requires an Authorization header, see proxy.conf.json for development and webservers.yml for configuring nginx in other environments
    localStorage.setItem('LHP.rememberme', rememberme ? 'true' : 'false');
    let params = new HttpParams({fromObject: this.auth})
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);
    this.handleAuthentication(params);
  }

  public renewToken(): void {
    let rememberme = localStorage.getItem('LHP.rememberme');
    if (rememberme && rememberme === 'true') {
      let params = new HttpParams({fromObject: this.auth})
        .set('grant_type', 'refresh_token')
        .set('access_token', localStorage.getItem('LHP.access_token'))
        .set('refresh_token', localStorage.getItem('LHP.refresh_token'));
      this.handleAuthentication(params);
    } else {
      this.logout();
    }
  }

  public handleAuthentication(params: HttpParams): void {
    this.http.post('/oauth/token', params)
      .subscribe((authResult: any) => {
        if (authResult && authResult.access_token) {
          this.setSession(authResult);
          if (this.hasAuthority(roleType.CUSTOMER_INTEGRATION)) {
            this.router.navigate(['integration'])
          } else {
            this.router.navigate(['dashboard'])
          }
        }
      }, err => {
        this.snackBarService.error(err, 'Login failed');
        console.log(err);
        this.logout();
      });
  }

  public jwtToken(): any {
    try {
      let accessToken = localStorage.getItem('LHP.access_token');
      return this.jwtHelper.decodeToken(accessToken);
    } catch (e) {
      this.logout('Automatic re-login failed, you have been logged out. You can now login again.');
    }
  }

  public userEmail(): string {
    let user = this.jwtToken();
    return user && user.user_name ? user.user_name : '';
  }

  public hasAuthority(authority: string): boolean {
    return this.hasAnyAuthority(authority);
  }

  public hasAnyAuthority(...authorities: string[]): boolean {
    let jwt = this.jwtToken();
    return jwt && jwt.authorities && jwt.authorities.some((authority) => {
        return authorities.indexOf(authority) !== -1;
      }
    );
  }

  private setSession(authResult: any): void {
    const expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());

    localStorage.setItem('LHP.access_token', authResult.access_token);
    localStorage.setItem('LHP.token_type', authResult.token_type);
    localStorage.setItem('LHP.expires_at', expiresAt);
    localStorage.setItem('LHP.refresh_token', authResult.refresh_token);

    this.scheduleRenewal();
    this.onAuthUpdated.next();
  }

  logout(msg?: string): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('LHP.access_token');
    localStorage.removeItem('LHP.token_type');
    localStorage.removeItem('LHP.expires_at');
    localStorage.removeItem('LHP.refresh_token');
    localStorage.removeItem('LHP.rememberme');

    this.unscheduleRenewal();
    this.onAuthUpdated.next();

    if (msg) {
      this.snackBarService.info(msg);
    }

    // Go back to the default route
    this.router.navigate(['']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('LHP.expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public scheduleRenewal() {
    if(!this.isAuthenticated()) return;
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(localStorage.getItem('LHP.expires_at'));

    const source = observableOf(expiresAt).pipe(mergeMap(
      expiresAt => {

        const now = Date.now();

        // Use the delay in a timer to
        // run the refresh at the proper time
        return observableTimer(Math.max(1, expiresAt - now));
      }));

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal() {
    if(!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }
}
