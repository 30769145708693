import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class NewestService {

  constructor(private http: HttpClient) {
  }

  findNewestIssues(limit): Observable<any>  {
    const params = new HttpParams()
      .set('limit', limit);

    return this.http.get('/api/newest/issues', {params});
  }

  findNewestIssueNotes(limit): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit);

    return this.http.get('/api/newest/issue-notes', {params});
  }

  findNewestActivity(startDate, endDate, limit): Observable<any> {
    const params = new HttpParams()
      .set('startDate', startDate || '')
      .set('endDate', endDate || '')
      .set('limit', limit || '');
    return this.http.get('/api/newest/activity', {params});
  }

}

