import {Component, OnInit, Input} from "@angular/core";
import {
  IntrastatSupplementaryUnit,
  IntrastatTransportMean,
  CountryCode,
  IntrastatTransactionNature
} from "../../issue.interface";
import {FormGroup, FormControl} from "@angular/forms";
import {Formatter} from "../../../formatter/formatter";
import {startWith} from "rxjs/operators";

@Component({
  selector: 'app-issue-intrastat-form',
  templateUrl: 'issue-intrastat-form.component.html',
  styleUrls: ['issue-intrastat-form.component.scss']
})
export class IssueIntrastatFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() transactionNatures: Array<IntrastatTransactionNature>;
  @Input() countries: Array<CountryCode>;
  @Input() transportMeans: Array<IntrastatTransportMean>;
  @Input() supplementaryUnits: Array<IntrastatSupplementaryUnit>;
  @Input() editDisabled: boolean;

  filteredCountries: Array<CountryCode>;

  consignmentCountryControl: FormControl;
  countryControl: FormControl;

  constructor(public formatter: Formatter) {
  }

  ngOnInit() {
    this.consignmentCountryControl = new FormControl({value: this.countries.find(c => c.id === this.form.value.consignmentCountryCodeId), disabled: this.editDisabled}, {updateOn: "change"});
    this.countryControl = new FormControl({value: this.countries.find(c => c.id === this.form.value.originCountryCodeId), disabled: this.editDisabled}, {updateOn: "change"});
    this.filteredCountries = this.countries.slice();

    this.consignmentCountryControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.countries) {
          this.filteredCountries = this.countries.filter(c => {
            const countryName = c.code + " " + c.name;;
            return countryName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      })

    this.countryControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.countries) {
          this.filteredCountries = this.countries.filter(c => {
            const countryName = c.code + " " + c.name;;
            return countryName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      })

  }

  selectConsignmentCountry(country: CountryCode): void {
    this.form.patchValue({consignmentCountryCodeId: country.id});
    this.consignmentCountryControl.setValue(country);
  }

  selectCountry(country: CountryCode): void {
    this.form.patchValue({originCountryCodeId: country.id});
    this.countryControl.setValue(country);
  }

  displayCountry(country: CountryCode): string {
    return !!country ? country.code + " " + country.name : "";
  }

  checkConsignmentCountryField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.consignmentCountryControl.value) {
      this.form.patchValue({consignmentCountryCodeId: null});
      this.consignmentCountryControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedCountry = this.countries.find(c => c.id === this.form.controls["consignmentCountryCodeId"].value);
      this.consignmentCountryControl.setValue(selectedCountry);
    }
    this.filteredCountries = this.countries.slice();
  }

  checkCountryField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.countryControl.value) {
      this.form.patchValue({originCountryCodeId: null});
      this.countryControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedCountry = this.countries.find(c => c.id === this.form.controls["originCountryCodeId"].value);
      this.countryControl.setValue(selectedCountry);
    }
    this.filteredCountries = this.countries.slice();
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }
}
