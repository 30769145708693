import {NgModule} from "@angular/core";
import {Md2DatepickerModule} from "angular-md2";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {TimepickerComponent} from "./timepicker";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TextMaskModule} from "angular2-text-mask";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextMaskModule,
    Md2DatepickerModule
  ],
  declarations: [
    TimepickerComponent
  ],
  exports: [
    TimepickerComponent
  ],
  providers: []
})
export class TimepickerModule {
}
