<mat-form-field fxFlex="100">
  <input matInput type="text"
         [(ngModel)]="inputValue" (change)="refreshPicker($event)" [disabled]="disabled"
         [textMask]="{mask: formatter.time.mask, guide: true, placeholderChar: formatter.placeholderChar}"/>
  <mat-placeholder i18n>Time</mat-placeholder>
  <span matSuffix>
    <md2-datepicker [(ngModel)]="pickerValue" [ngModelOptions]="{standalone: true}"
                    (change)="pickerChanged(pickerValue)" [disabled]="disabled"
                    type="time" [format]="format"></md2-datepicker>
  </span>
</mat-form-field>
