<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h2 i18n>Cost comparison</h2>

    <button *ngIf="!editDisabled" mat-button color="accent" (click)="addSubForm()">
      <h2 fxHide.lt-lg fxShow.gt-md i18n>Add cost details</h2>
      <mat-icon fxHide.gt-md fxShow.lt-lg>add_circle</mat-icon></button>
  </div>

  <form *ngIf="issue && form" novalidate [formGroup]="form">
    <div formArrayName="costs">
      <mat-accordion [multi]="false" displayMode="default">
        <mat-expansion-panel *ngIf="form.controls.costs?.length === 0" [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="text-small" i18n>There are no cost comparisons</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let subForm of form.controls.costs.controls; let i = index; trackBy: trackById">
          <mat-expansion-panel-header>
            <mat-panel-title fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxLayout="column" fxFlex="60px">
                <span class="text-small" i18n>LSP #{{i+1}}</span>
              </div>
              <div fxLayout="column">
                <span class="text-small" fxLayoutGap="2px">
                  <strong *ngIf="subForm.value.ranking">#{{subForm.value.ranking}}</strong>
                  <span *ngIf="subForm.value.logisticsServiceProviderId && logisticsServiceProviders">{{findPropertyById(logisticsServiceProviders, subForm.value.logisticsServiceProviderId, 'name')}}</span>
                  <br>
                  <strong *ngIf="subForm.value.cost">{{subForm.value.cost ? (subForm.value.cost | decimal:2) : null}}</strong>
                  <strong *ngIf="subForm.value.costCurrencyId">{{findPropertyById(currencies, subForm.value.costCurrencyId, 'code')}}</strong>

                  <span *ngIf="subForm.value.comparison">{{subForm.value.comparison}}</span>
                  <span *ngIf="subForm.value.note">{{subForm.value.note}}</span>
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <mat-divider></mat-divider>
            <app-issue-cost-form [issue]="issue" [form]="subForm" [logisticsServiceProviders]="logisticsServiceProviders" [currencies]="currencies" [editDisabled]="editDisabled" (changeEmitter)="onChange($event)"></app-issue-cost-form>
            <mat-divider *ngIf="!editDisabled"></mat-divider>
            <button *ngIf="!editDisabled" mat-button color="warn" (click)="removeSubForm(i)">
              <span i18n>DELETE ENTRY</span>
            </button>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>

</div>
