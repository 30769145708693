import {Component, OnInit} from "@angular/core";
import {IssueFormComponent} from "../issue-form.component";
import {EnumService} from "../../enum/enum.service";

@Component({
  selector: 'app-issue-type',
  templateUrl: 'issue-type.component.html',
  styleUrls: ['issue-type.component.scss']
})
export class IssueTypeComponent extends IssueFormComponent implements OnInit {
  issueType: any;

  constructor(private enumService: EnumService) {
    super();
  }

  ngOnInit() {
    this.issueType = this.enumService.getIssueType(this.issue.type);
  }
}
