export enum roleType {
  SUPERUSER = "SuperUser",
  LH_USER = "LH_User",
  CUST_EDITOR = "Cust_Editor",
  CUST_USER = "Cust_User",
  DEPARTURE_USER = "Departure_user",
  ARRIVAL_USER = "Arrival_User",
  LSP_USER = "LSP_User",
  SUPPLIER_USER = "Supplier_User",
  // The following user roles will be deprecated later on:
  LEAFHILL_SUPERUSER = "Leafhill-SuperUser",
  CUSTOMER_USER = "Customer-User",
  CUSTOMER_INTEGRATION = "Customer-Integration"
}

export const portalRoles = [
  roleType.SUPERUSER,
  roleType.LH_USER,
  roleType.CUST_EDITOR,
  roleType.CUST_USER,
  // The following user roles will be deprecated later on:
  roleType.LEAFHILL_SUPERUSER,
  roleType.CUSTOMER_USER
]

export const superRoles = [
  roleType.SUPERUSER,
  // The following user role will be deprecated later on:
  roleType.LEAFHILL_SUPERUSER
]

export const leafhillRoles = [
  roleType.SUPERUSER,
  roleType.LH_USER,
  // The following user role will be deprecated later on:
  roleType.LEAFHILL_SUPERUSER
]

export const editorRoles = [
  roleType.SUPERUSER,
  roleType.LH_USER,
  roleType.CUST_EDITOR,
  // The following user role will be deprecated later on:
  roleType.LEAFHILL_SUPERUSER,
  roleType.CUSTOMER_USER
]
