import {Component} from "@angular/core";
import {Issue} from "./issue.interface";
import { MatDialogRef } from "@angular/material/dialog";
import {AuthGuardService} from "../../modules/auth/auth-guard.service";

@Component({
  selector: 'app-issue-cloned-dialog',
  templateUrl: 'issue-cloned-dialog.component.html',
  styleUrls: ['issue-cloned-dialog.component.scss']
})
export class IssueClonedDialog {
  public originalIssue: Issue;
  public clonedIssue: Issue;

  constructor(public dialogRef: MatDialogRef<IssueClonedDialog>) {
  }
}
