import { Injectable } from "@angular/core";
@Injectable()
export class DateValidator {
  static date(control): any {
    let datePattern = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

    if (control.value === null)
      return null;

    if (!control.value.match(datePattern))
      return { "invalidDate": true };

    return null;
  }
}
