import { NativeDateAdapter } from "@angular/material/core";
import * as moment from 'moment';
import {Injectable} from "@angular/core";

@Injectable()
export class LocalDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (value)
      return moment(value, 'DD.MM.YYYY').toDate();
    return null;
  }

  format(date: Date, displayFormat: Object): string {
    return moment(date).format('DD.MM.YYYY');
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
