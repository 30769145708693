<div fxLayout="column">
  <mat-card *ngIf="isShowCreate">
    <mod-email-form [issue]="issue" [email]="newEmail" [isEditable]="true" [isDeletable]="false" [isAttachable]="true" [isLinkable]="isAllowLink" (onReset)="hideCreateEmail()" (onUpdate)="updateEmail($event)" (onAttach)="updateAttachment(newEmail, $event)"></mod-email-form>
  </mat-card>

  <br *ngIf="isShowCreate" />

  <button mat-raised-button *ngIf="isAllowCreate && !isShowCreate" color="accent" fxLayoutAlign="center" (click)="showCreateEmail()" i18n>Compose new email</button>

  <br/>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start end" class="accordion-header" *ngIf="isShowHeader">
    <div fxFlex="1 0 60px" fxLayout="column" *ngIf="isShowColumnHeader"></div>
    <div fxFlex="1 0 60px" fxLayout="column"></div>
    <div fxFlex="1 0 200px" fxLayout="column">
      <span color="accent" class="text-medium" i18n>Recipients</span>
    </div>
    <div fxFlex="1 1 100" fxLayout="column">
      <span color="accent" class="text-medium" i18n>Email</span>
    </div>
    <div fxFlex="1 0 60px" fxLayout="column"></div>
    <div fxFlex="1 0 60px" fxLayout="column"></div>
  </div>

  <mat-accordion #accordion displayMode="default">
    <mat-expansion-panel *ngFor="let email of emails" [expanded]="isSelected(email)" (opened)="selected = email">
      <mat-expansion-panel-header (click)="toggleRead($event, email)">
        <mat-panel-title fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div fxFlex="1 0 60px" fxLayout="row" fxLayoutAlign="center" *ngIf="isShowColumnHeader">
            <mat-icon *ngIf="email.sent" matTooltip="Sent email">send</mat-icon>
            <mat-icon *ngIf="email.received && !email.read" matTooltip="Unread email">move_to_inbox</mat-icon>
            <mat-icon *ngIf="email.received && email.read" matTooltip="Read email">inbox</mat-icon>
            <mat-icon *ngIf="email.draft" matTooltip="Unsent draft">description</mat-icon>
            <mat-icon *ngIf="email.error" matTooltip="Erroneous email">error</mat-icon>
          </div>
          <div fxFlex="1 0 60px" fxLayout="row" fxLayoutAlign="center" (click)="toggleStarred($event, email)">
            <mat-icon color="accent" *ngIf="email.starred">star</mat-icon>
            <mat-icon class="color-light" *ngIf="!email.starred">star_border</mat-icon>
          </div>
          <div fxFlex="1 0 200px" fxLayout="column" fxLayoutAlign="center">
            <span class="text-medium" [ngClass]="{'text-strong': email.received && !email.read}" [innerHTML]="prettyRecipients(email)"></span>
          </div>
          <div fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="center">
            <span class="ellipsis one-line text-medium" [ngClass]="{'text-strong': email.received && !email.read}">{{email.subject}} - <span class="text-small">{{email.text ? email.text : email.html}}</span></span>
          </div>
          <div fxFlex="1 0 60px" fxLayout="column" fxLayoutAlign="center end">
            <span class="text-small color-light" *ngIf="email.attachments?.length > 0"><mat-icon>attachment</mat-icon></span>
          </div>
          <div fxFlex="1 0 60px" fxLayout="column" fxLayoutAlign="center end">
            <span class="text-small color-light" [innerHTML]="prettyTimestamps(email)"></span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <mod-email-form [issue]="issue" [email]="email" [isEditable]="isAllowEdit" [isDeletable]="isAllowDelete" [isAttachable]="isAllowEdit" [isLinkable]="isAllowLink" (onUpdate)="updateEmail($event)" (onDelete)="deleteEmail($event)" (onLink)="updateLink($event)" (onAttach)="updateAttachment(email, $event)" (onDownload)="downloadAttachment(email, $event)" (onRemove)="removeAttachment(email, $event)"></mod-email-form>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
