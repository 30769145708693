import {NgModule} from "@angular/core";
import {HttpClientModule} from "@angular/common/http";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CommonModule} from "@angular/common";
import {EmailAccordionComponent} from "app/modules/email/accordion/email-accordion.component";
import {EmailFormComponent} from "app/modules/email/form/email-form.component";
import {EmailService} from "app/modules/email/email.service";
import {FormService} from "../form/form.service";
import {QuillModule} from "ngx-quill";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    // material
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule
    // modules
  ],
  declarations: [
    EmailAccordionComponent,
    EmailFormComponent
  ],
  exports: [
    EmailAccordionComponent,
    EmailFormComponent
  ],
  providers: [
    EmailService,
    FormService
  ]
})
export class EmailModule {
}
