export const viewTypes = [
  'MASTER',
  'ISSUES',
  'SUPPORT',
  'INVOICES',
  'USERS'
] as const;

type viewType = typeof viewTypes[number];

export const viewTypeIDs = {
  master: 0,
  issues: 1,
  support: 2,
  invoices: 3,
  users: 4
} as const;

export const viewTypeNames: Record<viewType, string> = {
  MASTER: 'Master',
  ISSUES: 'Issues',
  SUPPORT: 'Support',
  INVOICES: 'Invoices',
  USERS: 'Users'
} as const;

export const viewTypePaths: Record<viewType, string> = {
  MASTER: '/master-data',
  ISSUES: '/issues-grid',
  SUPPORT: '/support',
  INVOICES: '/invoicing',
  USERS: '/users'
} as const;
