
import {from as observableFrom, Observable} from 'rxjs';

import {startWith, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, OnInit, Input} from "@angular/core";
import {Company, CountryCode, TimeZone, Airport, Port} from "../../issue.interface";
import {FormGroup, FormControl} from "@angular/forms";
import {AjaxService} from "../../../ajax/ajax.service";
import {IssueFormComponent} from "../../issue-form.component";
import {AddressService} from "../../../address/address.service";

@Component({
  selector: 'app-issue-delivery-form',
  templateUrl: 'issue-delivery-form.component.html',
  styleUrls: ['issue-delivery-form.component.scss']
})
export class IssueDeliveryFormComponent extends IssueFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() timeZones: Array<TimeZone>;
  @Input() countries: Array<CountryCode>;
  @Input() deliveries: Observable<Array<Company>>;
  @Input() editDisabled: boolean;
  ports: Array<Port>;
  airports: Array<Airport>;

  postalControl: FormControl;
  cityControl: FormControl;
  streetControl: FormControl;
  stateControl: FormControl;
  companyControl: FormControl;
  countryControl: FormControl;
  portControl: FormControl;
  airportControl: FormControl;;

  postalCodes: Observable<any>;
  cities: Observable<any>;
  streetAddresses: Observable<any>;
  states: Observable<any>;

  companies: Array<Company>;
  filteredCompanies: Array<Company>;
  filteredCountries: Array<CountryCode>;
  filteredPorts: Array<Port>;
  filteredAirports: Array<Airport>;

  constructor(private ajaxService: AjaxService,
              private addressService: AddressService) {
    super();
  }

  ngOnInit() {
    this.init(this.form.value.countryCodeId);
    this.form.controls['countryCodeId']
      .valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),)
      .subscribe(countryCodeId => countryCodeId && this.init(countryCodeId));

    this.streetControl = new FormControl({value: this.form.value.streetAddress, disabled: this.editDisabled}, { updateOn: 'change' });
    this.postalControl = new FormControl({value: this.form.value.postalCode, disabled: this.editDisabled}, { updateOn: 'change' });
    this.cityControl = new FormControl({value: this.form.value.city, disabled: this.editDisabled}, { updateOn: 'change' });
    this.stateControl = new FormControl({value: this.form.value.state, disabled: this.editDisabled}, { updateOn: 'change' });
    this.companyControl = new FormControl({value: null, disabled: this.editDisabled}, { updateOn: 'change' });
    this.countryControl = new FormControl({value: this.countries.find(c => c.id === this.form.value.countryCodeId), disabled: this.editDisabled}, { updateOn: 'change' });

    this.deliveries.subscribe(c => {
      this.companies = c;
      this.filteredCompanies = c;
      const company = c.find(c => c.id === this.form.value.companyId);
      this.companyControl.setValue(company, {emitEvent: true});
    });

    this.portControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.ports) {
          this.filteredPorts = this.ports.filter(p => {
            const portName = p.code + " " + p.name;
            return portName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });

    this.airportControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.airports) {
          this.filteredAirports = this.airports.filter(a => {
            const airportName = a.code + " " + a.name;
            return airportName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });

    this.companyControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.companies) {
          this.filteredCompanies = this.companies.filter(c => {
            let companyName = "";
            if(!!c.name) {
              if(!!c.code) {
                companyName += `(${c.code}) ${c.name}`;
              }
              companyName += c.name.toString();
            }
            return companyName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });

    this.countryControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.countries) {
          this.filteredCountries = this.countries.filter(c => {
            const countryName = c.code + " " + c.name;;
            return countryName.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      })

    this.streetControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findAddresses(val, this.form, this.countries);
          this.streetAddresses = suggestions;
        }
        else
          this.streetAddresses = observableFrom([]);
      });

    this.postalControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findPostals(val, this.form, this.countries);
          this.postalCodes = suggestions;
        }
        else
          this.postalCodes = observableFrom([]);
      });

    this.cityControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findCities(val, this.form, this.countries);
          this.cities = suggestions;
        }
        else
          this.cities = observableFrom([]);
      });

    this.stateControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        if (val) {
          let suggestions = this.addressService.findStates(val, this.form, this.countries);
          this.states = suggestions;
        }
        else
          this.states = observableFrom([]);
      });
  }

  selectAddress(option, fields) {
    this.addressService.selectAddress(option, fields, this.form, this.streetControl, this.cityControl, this.postalControl, this.stateControl, this.countries);
  }

  init(countryCodeId) {
    this.portControl = new FormControl({value: null, disabled: this.editDisabled}, { updateOn: 'change' });
    this.airportControl = new FormControl({value: null, disabled: this.editDisabled}, { updateOn: 'change' });

    this.ajaxService.findPorts(countryCodeId).subscribe(ports => {
      this.ports = ports
      this.portControl.setValue(this.ports.find(p => p.id === this.form.value.portId));
    });

    this.ajaxService.findAirports(countryCodeId).subscribe(airports => {
      this.airports = airports;
      this.airportControl.setValue(this.airports.find(a => a.id === this.form.value.airportId));
    });
  }

  copy() {
    this.deliveries.subscribe(companies => {
      let company = companies.filter(any => any.id == this.form.value.companyId)[0];
      this.form.patchValue({
        companyId: company && company.id || null,
        companyName: company && company.name || null,
        countryCodeId: company && company.countryCode && company.countryCode.id || null,
        streetAddress: company && company.streetAddress || null,
        postalCode: company && company.postalCode || null,
        city: company && company.city || null,
        state: company && company.state || null,
        notes: company && company.notes || null
      }, {emitEvent: true});
      this.streetControl.setValue(this.form.value.streetAddress, {emitEvent: false});
      this.cityControl.setValue(this.form.value.city, {emitEvent: false});
      this.postalControl.setValue(this.form.value.postalCode, {emitEvent: false});
      this.stateControl.setValue(this.form.value.state, {emitEvent: false});
      this.companyControl.setValue(company, {emitEvent: false});
      this.countryControl.setValue(this.countries.find(c => c.id === this.form.value.countryCodeId), {emitEvent: false});
    });
  }

  copyReceiver() {
    let receiver = this.form.value['isDifferentFromReceiver'] ? null : this.issue.receiver;
    this.form.patchValue({
      companyId: receiver && receiver.company && receiver.company.id || null,
      companyName: receiver && receiver.companyName || null,
      countryCodeId: receiver && receiver.countryCode && receiver.countryCode.id || this.ajaxService.defaultCountryCodeId(this.countries) || null,
      streetAddress: receiver && receiver.streetAddress || null,
      postalCode: receiver && receiver.postalCode || null,
      city: receiver && receiver.city || null,
      state: receiver && receiver.state || null,
      notes: receiver && receiver.notes || null
    }, {emitEvent: true});
    this.streetControl.setValue(this.form.value.streetAddress, {emitEvent: false});
    this.cityControl.setValue(this.form.value.city, {emitEvent: false});
    this.postalControl.setValue(this.form.value.postalCode, {emitEvent: false});
    this.stateControl.setValue(this.form.value.state, {emitEvent: false});
    this.companyControl.setValue(receiver && receiver.company, {emitEvent: false});
    this.countryControl.setValue(this.countries.find(c => c.id === this.form.value.countryCodeId), {emitEvent: false});
  }

  selectCompany(company: Company): void {
    this.form.patchValue({companyId: company.id});
    this.companyControl.setValue(company);
  }

  displayCompany(company: Company): string {
    return (!!company ? (!!company.code ? company.code : "") + " " + (!!company.name ? company.name : "") : "").trim();
  }

  checkCompanyField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.companyControl.value) {
      this.form.patchValue({companyId: null});
      this.companyControl.setValue(null);
    } else {
      // Set the input field text to match the selected company
      const selectedCompany = this.companies.find(r => r.id === this.form.controls["companyId"].value);
      this.companyControl.setValue(selectedCompany, {emitEvent: true});
    }
  }

  selectCountry(country: CountryCode): void {
    this.form.patchValue({countryCodeId: country.id});
    this.countryControl.setValue(country);
  }

  displayCountry(country: CountryCode): string {
    return !!country ? country.code + " " + country.name : "";
  }

  checkCountryField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.countryControl.value) {
      this.form.patchValue({countryCodeId: null});
      this.countryControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedCountry = this.countries.find(c => c.id === this.form.controls["countryCodeId"].value);
      this.countryControl.setValue(selectedCountry);
    }
  }

  selectPort(port: Port): void {
    this.form.patchValue({portId: port.id});
    this.portControl.setValue(port);
  }

  displayPort(port: Port | Airport): string {
    return !!port ? port.code + " " + port.name : "";
  }

  checkPortField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.portControl.value) {
      this.form.patchValue({portId: null});
      this.portControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedPort = this.ports.find(p => p.id === this.form.controls["portId"].value);
      this.portControl.setValue(selectedPort);
    }
  }

  selectAirport(airport: Airport): void {
    this.form.patchValue({airportId: airport.id});
    this.airportControl.setValue(airport);
  }

  checkAirportField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.airportControl.value) {
      this.form.patchValue({airportId: null});
      this.airportControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedAirport = this.airports.find(a => a.id === this.form.controls["airportId"].value);
      this.airportControl.setValue(selectedAirport);
    }
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }

}
