import {Component, OnInit, OnDestroy} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-title',
  template: ''
})
export class TitleComponent implements OnInit, OnDestroy {

  defaultTitle: string = 'Leafhill 247 Web';

  constructor(private route: ActivatedRoute, private titleService: Title) {
  }

  ngOnInit() {
    this.route.data.subscribe((data: {title: string}) => {
      let title = data && data.title ? 'Leafhill | ' + data.title : this.defaultTitle;
      this.titleService.setTitle(title);
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle(this.defaultTitle);
  }
}
