// src/app/auth/role-guard.service.ts
import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AuthService} from './auth.service';
import {SnackBarService} from "../../shared/snackbar/snackbar.service";

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRoles = route.data.expectedRoles;

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['']);
      return false;
    } else if (
      !expectedRoles.some((role) => {
        return this.authService.hasAuthority(role);
      })
    ) {
      this.router.navigate(['']);
      this.snackBarService.info(`You don't have access for this environment. Contact the Leafhill support for more information.`, true, 4000);
      return false;
    }
    return true;
  }
}
