<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Terms of delivery</h2>
        <h2 *ngIf="issue?.term">
          <span class="text-small color-light" i18n>Last modified by {{issue?.term?.lastModifiedBy?.name}} {{issue?.term?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row" fxLayoutGap="20px">

      <mat-form-field fxFlex="200px">
        <input type="text"
          placeholder="Term"
          matInput
          [formControl]="termIdControl"
          autocomplete="off"
          (blur)="delayedBlur(checkTermField.bind(this), 300)"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTerm.bind(this)" (optionSelected)="selectTerm($event.option.value)">
          <mat-option *ngFor="let option of filteredTerms; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.controls['termId'].hasError('incorrect')">
          Select from given options
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100">
        <mat-placeholder i18n>Incoterms location</mat-placeholder>
        <input matInput type="text" formControlName="location">
      </mat-form-field>
    </mat-card-content>

    <mat-card-content fxLayout="row">
      <mat-form-field fxFlex="100">
        <mat-placeholder i18n>Delivery notes</mat-placeholder>
        <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" formControlName="notes"></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>
