<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'link': data?.me}" [matMenuTriggerFor]="menu">

  <mat-icon style="margin-right: 10px;">account_circle</mat-icon>

  <span fxHide.lt-sm fxShow.gt-xs fxLayout="column">
    <span class="text-medium">{{data?.me?.name}}</span>
    <span class="text-small">{{data?.me?.email}}</span>
    <span class="text-small">{{data?.me?.companyName}}</span>
  </span>
</div>

<mat-menu #menu="matMenu" [overlapTrigger]="false" class="app-toolbar-menu">
  <a mat-menu-item (click)="logout()" i18n>Logout</a>
</mat-menu>
