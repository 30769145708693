import {Pipe} from "@angular/core";
import {isNumeric} from "rxjs/internal-compatibility";
import {isString} from "util";

@Pipe({
	name : "time"
})
export class TimePipe {
  transform(value){
    if (typeof value === 'string') {
      return String(value).substring(0, 5);
    }
    return value;
  }
}
