import { catchError } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Environment } from "../../modules/environment/environment";
import { SnackBarService } from "../snackbar/snackbar.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    @Inject("APP_ENV") private env: Environment,
    private snackBarService: SnackBarService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = new URL(req.url, this.env.apiGatewayUrl);

    let newUrl: string;
    if (req.url != url.toString()) {
      newUrl = url.toString();
    }

    // Get the access token from the local storage.
    let token_type = localStorage.getItem("LHP.token_type");
    let access_token = localStorage.getItem("LHP.access_token");
    if (req.url == "/oauth/token") {
      token_type = "Basic";
      access_token = "TEhQOkxIUA==";
    }
    // Clone the request to add the new header.
    const apiReq = req.clone({
      url: newUrl ? newUrl : req.url,
      headers: req.headers.set(
        "Authorization",
        `${token_type} ${access_token}`
      ),
    });
    // Pass on the cloned request instead of the original request.
    return next
      .handle(apiReq)
      .pipe(catchError((error: any) => this.snackBarService.error(error)));
  }
}
