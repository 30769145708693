import * as _ from "lodash";

import {Injectable} from "@angular/core";


@Injectable()
export class EnumService {

  private issueTypes = [
    {type: 'INDICATION_BOOKING', name: 'Indication Booking'},
    {type: 'HELP_DESK', name: 'Help Desk'},
    {type: 'EXCEPTION_CLAIM', name: 'Exception Claim'},
  ];

  constructor() {
  }

  getIssueType(type)  {
    return _.find(this.issueTypes, {type: type});
  }
}


