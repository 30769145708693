import {Component} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'remove-dialog',
  templateUrl: 'remove-dialog.component.html'
})
export class RemoveDialog {
  constructor(public dialogRef: MatDialogRef<RemoveDialog>) {
  }
}
