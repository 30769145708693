
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Issue} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormControl, FormGroup} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-detail-form',
  templateUrl: 'issue-detail-form.component.html',
  styleUrls: ['issue-detail-form.component.scss']
})
export class IssueDetailFormComponent extends IssueFormComponent implements OnInit, OnChanges {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() inner: boolean;
  @Input() editDisabled: boolean;

  form: FormGroup;

  constructor(private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      totalWeight: new FormControl(this.issue?.detail?.totalWeight)
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.form) {
      this.form.patchValue(changes.issue?.currentValue?.detail ?? { totalWeight: null });
    }
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {detail: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }
}
