import {Component} from "@angular/core";
import {Issue} from "../issue.interface";
import { MatDialogRef } from "@angular/material/dialog";
import {AuthGuardService} from "../../../modules/auth/auth-guard.service";
import {editorRoles} from "../../../modules/auth/roles";

@Component({
  selector: 'app-issue-transportation-dialog',
  template: '<app-issue-transportation-form *ngIf="issue" [issue]="issue" [editDisabled]="!authGuard.hasAnyAuthority(editorRoles)" ></app-issue-transportation-form>'
})
export class IssueTransportationDialog {
  public issue: Issue;
  editorRoles = editorRoles;

  constructor(public authGuard : AuthGuardService, public dialogRef: MatDialogRef<IssueTransportationDialog>) {
  }
}
