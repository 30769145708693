<mat-toolbar color="primary" class="primary-background-color hue-2">
  <span i18n>Settings</span>
</mat-toolbar>

<mat-card>
  <mat-card-content>

    <mat-tab-group>
      <mat-tab label="Batch file upload">
        <app-dropzone [url]="'/api/batch/upload'" [acceptedFiles]="'.xml'"></app-dropzone>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span>Notifications</span>
          <span *ngIf="notifications.length">({{notifications.length}})</span>
        </ng-template>

        <mat-list>
          <mat-list-item *ngFor="let notification of notifications">
            <h3 matLine fxFlex>{{notification.text}}</h3>
            <span matLine fxFlex>{{notification.timestamp}}</span>
            <button mat-mini-fab (click)="clearNotification(notification)"><mat-icon>clear</mat-icon></button>
            <span matLine *ngIf="notification.data">{{notification.data}}</span>
          </mat-list-item>
        </mat-list>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>
