<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h2 i18n>Product descriptions</h2>

    <button *ngIf="!editDisabled" mat-button color="accent" (click)="addSubForm()">
      <h2 fxHide.lt-lg fxShow.gt-md i18n>Add product details</h2>
      <mat-icon fxHide.gt-md fxShow.lt-lg>add_circle</mat-icon>
    </button>
  </div>

  <div fxLayout="row">
    <div fxLayout="column" fxFlex="90px">
      <span class="text-small" i18n>Total value</span>
      <span class="text-medium" i18n>{{totalValue | decimal:2}} EUR</span>
    </div>
  </div>

  <form *ngIf="issue && form" novalidate [formGroup]="form" style="margin-top: 20px">
    <div formArrayName="products">
      <mat-accordion [multi]="false" displayMode="default">
        <mat-expansion-panel *ngIf="form.controls.products?.length === 0" [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="text-small" i18n>There are no products</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-expansion-panel *ngFor="let subForm of form.controls.products.controls; let i = index; trackBy: trackById" [expanded]="i === 0">
        <mat-expansion-panel-header>
          <mat-panel-title fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxLayout="column" fxFlex="60px">
              <span class="text-small" i18n>Product #{{i+1}}</span>
            </div>
            <div fxLayout="column">
              <span class="text-small">
                <strong *ngIf="subForm.value.pickupId || subForm.value.deliveryId">
                  <span *ngIf="subForm.value.pickupId">{{findPropertyById(issue.pickups, subForm.value.pickupId, 'companyName')}}</span>
                  <span *ngIf="subForm.value.pickupId && subForm.value.deliveryId">-</span>
                  <span *ngIf="subForm.value.deliveryId">{{findPropertyById(issue.deliveries, subForm.value.deliveryId, 'companyName')}}</span>
                  <br>
                </strong>
                <span *ngIf="subForm.value.line">{{subForm.value.line}}, </span>
                <span *ngIf="subForm.value.description">{{subForm.value.description}}, </span>
                <span *ngIf="subForm.value.customsNumberId"><span i18n>CN </span>{{findPropertyById(customsNumbers, subForm.value.customsNumberId, 'code')}}, </span>
                <span *ngIf="subForm.value.itemNumber"><span i18n>item no </span>{{subForm.value.itemNumber}}, </span>
                <span *ngIf="subForm.value.length && subForm.value.width && subForm.value.height">({{subForm.value.length | decimal:2}} x {{subForm.value.width | decimal:2}} x {{subForm.value.height | decimal:2}}) {{findPropertyById(lengthMeasures, subForm.value.lengthMeasureId, 'unit')}}, </span>
                <span *ngIf="subForm.value.amount">{{subForm.value.amount | decimal:2}} <span i18n>pcs</span>, </span>
                <span *ngIf="subForm.value.unitValue"><span i18n>á </span>{{subForm.value.unitValue | decimal:2}} {{findPropertyById(currencies, subForm.value.unitValueCurrencyId, 'code')}}, </span>
                <span *ngIf="subForm.value.totalValue"><span i18n>total </span>{{subForm.value.totalValue | decimal:2}} {{findPropertyById(currencies, subForm.value.totalValueCurrencyId, 'code')}}</span>
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <mat-divider></mat-divider>
          <app-issue-product-form [form]="subForm" [pickups]="issue.pickups" [deliveries]="issue.deliveries" [customsNumbers]="customsNumbers" [lengthMeasures]="lengthMeasures" [massMeasures]="massMeasures" [currencies]="currencies" [editDisabled]="editDisabled"></app-issue-product-form>

          <h2 i18n>Intrastat</h2>
          <app-issue-intrastat-form *ngIf="subForm.controls.intrastat.value.isOpen" [form]="subForm.controls.intrastat" [transactionNatures]="transactionNatures" [countries]="countries" [transportMeans]="transportMeans" [supplementaryUnits]="supplementaryUnits" [editDisabled]="editDisabled"></app-issue-intrastat-form>
          <h2 i18n>Special requirements</h2>
          <app-issue-special-requirement-form *ngIf="subForm.controls.specialRequirement.value.isOpen" [form]="subForm.controls.specialRequirement" [unClassifications]="unClassifications" [paymentTerms]="paymentTerms" [editDisabled]="editDisabled"></app-issue-special-requirement-form>
          <mat-divider *ngIf="!editDisabled"></mat-divider>
          <button *ngIf="!editDisabled" mat-button color="warn" (click)="removeSubForm(i)">
            <span i18n>DELETE PRODUCT</span>
          </button>
        </ng-template>

      </mat-expansion-panel>
    </div>
  </form>

</div>
