<mat-card class="app-card" fxLayout="column" fxLayoutAlign="space-between">
  <mat-card-header fxLayoutAlign="center center">
    <img src="/assets/leafhill.png" width="150px">
  </mat-card-header>

  <mat-card-content>
    <form fxLayout="column" [formGroup]="form" novalidate autocomplete="off">
      <label><strong>Find issues with reference numbers, companies, or addresses</strong></label>
      <mat-form-field appearance="outline">
        <button mat-icon-button matPrefix type="submit" (click)="showIssues()">
          <mat-icon>search</mat-icon>
        </button>

        <input matInput formControlName="searchTerm" name="searchTerm" autocomplete="off" [matAutocomplete]="search" #autocompleteTrigger>

        <mat-autocomplete #search="matAutocomplete" (optionSelected)="showIssue($event.option.value)">
          <mat-option *ngFor="let issue of issues; let isLast = last" [value]="issue.referenceNo">

            <div fxLayout="row" fxLayoutAlign="space-between top" *ngIf="form.value.searchTerm">
              <div fxLayout="column">
                <span class="text-small">
                  <span i18n>LH ref: </span>
                  <strong [innerHtml]="issue.referenceNo | highlight:form.value.searchTerm"></strong>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.customerReference">
                  <span i18n>Customer ref: </span>
                  <span [innerHtml]="issue.reference.customerReference | highlight:form.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.projectNumber">
                  <span i18n>Project no: </span>
                  <span [innerHtml]="issue.reference.projectNumber | highlight:form.value.searchTerm"></span>
                </span>
              </div>
              <div fxLayout="column">
                <span class="text-small" *ngIf="authGuard.hasAnyAuthority(superRoles)">{{issue?.owner?.name}}</span>
                <span class="text-small" *ngIf="issue?.reference?.pickupReference">
                  <span i18n>Pickup ref: </span>
                  <span [innerHtml]="issue.reference.pickupReference | highlight:form.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.otherReference">
                  <span i18n>Other ref: </span>
                  <span [innerHtml]="issue.reference.otherReference | highlight:form.value.searchTerm"></span>
                </span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>

        <button mat-icon-button matSuffix (click)="startSearchByVoice()" *ngIf="!isSearchByVoiceActive">
          <mat-icon>mic</mat-icon>
        </button>
        <button mat-icon-button matSuffix (click)="stopSearchByVoice()" *ngIf="isSearchByVoiceActive" class="pulse">
          <mat-icon>mic_none</mat-icon>
        </button>
      </mat-form-field>

      <div fxLayoutAlign="center">
        <button mat-raised-button color="primary" type="submit" (click)="showIssues()" i18n>Find issues</button>
      </div>
    </form>
  </mat-card-content>

  <mat-card-content fxLayout="column" fxLayoutGap="10px">
    <strong i18n>View issues in the following categories</strong>
    <div fxLayout="row" fxLayoutGap="10px">
      <button color="accent" mat-button outline (click)="showOpenIssues()">Open issues</button>
      <button color="warn" mat-button outline (click)="showIssuesInProcess()">Issues in process</button>
    </div>
  </mat-card-content>
</mat-card>
