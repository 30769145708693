import {Component, EventEmitter, Input, Output} from "@angular/core";
import {EmailService} from "../email.service";
import {Email} from "../email";
import {DatePipe} from "@angular/common";
import {Issue} from "../../../shared/issue/issue.interface";
import {saveAs} from "file-saver";

@Component({
  selector: 'mod-email-accordion',
  templateUrl: './email-accordion.component.html',
  styleUrls: ['./email-accordion.component.scss'],
  providers: [DatePipe]
})
export class EmailAccordionComponent {
  @Input() filter: any;

  @Input() issue: Issue;
  @Input() emails: Array<Email>;
  @Input() isShowHeader: boolean;
  @Input() isShowColumnHeader: boolean;
  @Input() isAllowEdit: boolean;
  @Input() isAllowDelete: boolean;
  @Input() isAllowCreate: boolean;
  @Input() isAllowLink: boolean;

  @Output() onEmailsModified: EventEmitter<void> = new EventEmitter<void>();

  // Create email
  isShowCreate: boolean;
  newEmail: any;

  // Helpers
  today: Date;
  selected: any;

  constructor(private emailService: EmailService,
              private datePipe: DatePipe) {
    this.emails = [];
    this.isShowCreate = false;
    this.newEmail = undefined;
    this.today = new Date();
  }

  updateEmail(email: any): void {
    this.emailService.upsertEmail(email)
      .subscribe((data) => {
        this.onEmailsModified.next(data);
        this.hideCreateEmail(data);
      }, (err) => {
        console.log('Error upserting email', err);
      });
  }

  deleteEmail(email: any): void {
    this.emailService.deleteEmail(email.uuid)
      .subscribe(() => {
        this.onEmailsModified.next();
      }, (err) => {
        console.log('Error deleting email', err);
      })
  }

  updateLink(email) {
    this.emailService.upsertEmail(email)
      .subscribe((data) => {
        email = data;
      }, (err) => {
        console.log('Error reading email', err);
      });
  }

  showCreateEmail(): void {
    this.isShowCreate = true;
    this.newEmail = {
      referenceNo: this.issue ? this.issue.referenceNo : null,
      draft: new Date()
    };
  }

  hideCreateEmail(email?): void {
    this.isShowCreate = false;
    if (email) {
      this.selected = email;
    }
  }

  isSelected(email) {
    return this.selected && email && this.selected.uuid === email.uuid;
  }

  toggleRead(event, email) {
    if (!email.read) {
      email.read = new Date();
      this.emailService.upsertEmail(email)
        .subscribe((data) => {
          email = data;
        }, (err) => {
          console.log('Error reading email', err);
        });
    }
  }

  toggleStarred(event, email) {
    event.preventDefault();
    event.stopPropagation();
    if (email.starred) {
      delete email.starred;
    } else {
      email.starred = new Date();
    }
    this.emailService.upsertEmail(email)
      .subscribe((data) => {
        email = data;
      }, (err) => {
        console.log('Error starring email', err);
      });
  }

  updateAttachment(email, file) {
    // handle existing draft
    if (email.uuid) {
      this.attachFile(email, file);
    }
    // draft needs to be saved but keep the "newEmail" open
    else {
      this.emailService.upsertEmail(email)
        .subscribe((data) => {
          email = data;
          this.newEmail = email;
          this.attachFile(this.newEmail, file);
        }, (err) => {
          console.log('Error saving email', err);
        });
    }
  }

  private attachFile(email, file) {
    this.emailService.attachFile(email.uuid, 'ATTACHMENTS', file).subscribe(data => {
      email = data;
      this.onEmailsModified.next(email);
      this.hideCreateEmail(this.newEmail);
    }, (err) => {
      console.log('Error saving attachment', err);
    });
  }

  downloadAttachment(email, attachment) {
    this.emailService.downloadFile(email.uuid, attachment.filename, attachment.fileType, attachment.filePath)
      .subscribe((file: File) => {
        saveAs(file, attachment.filename);
      });
  }

  removeAttachment(email, attachment) {
    this.emailService.detachFile(email.uuid, attachment.id).subscribe(data => {
      email = data;
      this.onEmailsModified.next(email);
    }, (err) => {
      console.log('Error removing attachment', err);
    });
  }

  prettyTimestamps(email: Email): string {
    let pretty = '';
    if (email.error) {
      pretty = `<span color="warn">${this.prettyTimestamp(email.error)}</span>`;
    } else if (email.received) {
      pretty = this.prettyTimestamp(email.received);

    } else if (email.sent) {
      pretty = this.prettyTimestamp(email.sent);

    } else if (email.draft) {
      pretty = `<span color="accent">${this.prettyTimestamp(email.draft)}</span>`;
    }
    return pretty;
  }

  prettyTimestamp(timestamp): string {
    let date = new Date(timestamp);
    let pretty = '';
    // TODAY show time
    if (date.toDateString() === this.today.toDateString()) {
      pretty = this.datePipe.transform(date, 'HH.mm');
    }
    // CURRENT YEAR show day
    else if (date.getFullYear() === this.today.getFullYear()) {
      pretty = this.datePipe.transform(date, 'MMM d');
    }
    // OTHERWISE show full date
    else {
      pretty = this.datePipe.transform(date, 'dd.MM.yyyy');
    }
    return pretty;
  }

  prettyRecipients(email: Email): string {
    let pretty = '';
    let recipients = [];
    email.to && email.to.length > 0 && recipients.push(...email.to);
    email.cc && email.cc.length > 0 && recipients.push(...email.cc);
    email.bcc && email.bcc.length > 0 && recipients.push(...email.bcc);

    if (email.error) {
      pretty = '<span color="warn">Error</span>';
    }
    else if (email.draft) {
      pretty = '<span color="accent">Draft</span>';
    }
    else if (!email.sent && !email.received) {
      pretty = '<span class="color-light">Is being sent...</span>'
    }
    else {
      if (recipients.length == 1) {
        pretty = this.prettyRecipient(recipients[0]);
      } else if (recipients.length > 1) {
        pretty = `${this.prettyRecipient(recipients[0])} .. ${this.prettyRecipient(recipients[recipients.length - 1])} <span class="text-small" color="accent">${recipients.length}</span>`;
      }
    }
    return pretty;
  }

  prettyRecipient(recipient: string): string {
    let extract = { name: "", email: "" };
    let emails = recipient.match(/[^@<\s]+@[^@\s>]+/g);
    if (emails) {
      extract.email = emails[0];
    }
    let names = recipient.split(/\s+/);
    if (names.length > 1) {
      names.pop();
      extract.name = names.join(" ").replace(/"/g, "");
    }
    return extract.name ? extract.name : extract.email;
  }
}
