<div class="company-role-selection" [formGroup]="form">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="45" style="height: 50px">
          <mat-form-field style="min-height: initial;">
              <input type="text"
                placeholder="Company"
                matInput
                [formControl]="companyControl"
                (blur)="delayedBlur(checkCompanyField.bind(this), 300)"
                autocomplete="off"
                required
                #company
                [matAutocomplete]="autoCompany">
              <mat-autocomplete #autoCompany="matAutocomplete" (optionSelected)="selectCompany($event.option.value)" [displayWith]="displayCompany.bind(this)">
                <mat-option *ngFor="let option of filteredCompanies; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
              </mat-autocomplete>
          </mat-form-field>
        </div>

        <span fxFlex="5"></span>

        <div fxLayout="column" fxFlex="40" style="height: 50px">
          <mat-form-field fxFlex="100px">
            <mat-select formControlName="roleId" required>
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let option of userRoles; trackBy: trackById" [value]="option.id">{{option.offset}} {{option.role}}</mat-option>
            </mat-select>
            <mat-placeholder i18n>User role</mat-placeholder>
          </mat-form-field>
        </div>
      </div>
</div>
