import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "./modules/auth/auth-guard.service";
import {RoleGuardService} from "./modules/auth/role-guard.service";
import {roleType, portalRoles, superRoles} from './modules/auth/roles';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: portalRoles}
  },
  {
    path: 'issues',
    loadChildren: () => import('./issues/issues.module').then(m => m.IssuesModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: portalRoles}
  },
  {
    path: 'issues-grid',
    loadChildren: () => import('./issues-grid/issues-grid.module').then(m => m.IssuesGridModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: portalRoles}
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support-grid.module').then(m => m.SupportModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: portalRoles}
  },
  {
    path: 'integration',
    loadChildren: () => import('./integration/integration.module').then(m => m.IntegrationModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: [roleType.CUSTOMER_INTEGRATION]}
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: portalRoles}
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {expectedRoles: superRoles}
  },
  {
    path: 'browse',
    redirectTo: 'issues'
  },
  {
    path: 'home',
    redirectTo: 'dashboard',
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
