
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {IssueService} from "../issue/issue.service";
import {Issue} from "../issue/issue.interface";
import {SpeechRecognitionService} from "../speech/speech-recognition.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import {AuthGuardService} from "../../modules/auth/auth-guard.service";
import {superRoles} from '../../modules/auth/roles';

@Component({
  selector: 'app-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('autocompleteTrigger', { read: MatAutocompleteTrigger, static: true }) autocompleteTrigger: MatAutocompleteTrigger;
  superRoles = superRoles;
  issues: Array<Issue>;
  form: FormGroup;

  isSearchByVoiceActive: boolean;

  constructor(private router: Router,
              private issueService: IssueService,
              private speechRecognitionService: SpeechRecognitionService,
              public authGuard: AuthGuardService) {
    this.isSearchByVoiceActive = false;
  }

  ngOnInit() {
    this.form = new FormGroup({
      searchTerm: new FormControl(''),
      statuses: new FormControl([])
    }, { updateOn: 'change' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.find());
  }

  ngOnDestroy() {
    this.stopSearchByVoice();
  }

  startSearchByVoice(isSilent?) {
    this.isSearchByVoiceActive = true;
    this.speechRecognitionService
      .start(isSilent)
      .subscribe(
        (value) => {
          this.form.patchValue({searchTerm: value}, {emitEvent: true, onlySelf: false});
        },
        (err) => {
          if (err.error == "no-speech") {
            // restart on no-speech
            this.startSearchByVoice(true);
          }
        },
        () => {
          this.stopSearchByVoice(!this.isSearchByVoiceActive);
        });
  }

  stopSearchByVoice(isSilent?) {
    this.isSearchByVoiceActive = false;
    this.speechRecognitionService.stop(isSilent);
  }

  find() {
    // clear results and close panel if empty search
    if (this.form.value.searchTerm.length === 0) {
      this.issues = [];
      this.autocompleteTrigger.closePanel();
      return;
    }

    this.issueService.find(this.form.value.searchTerm).subscribe(issues => {
      this.issues = issues;
      // open manually needed if search by voice and results are found
      if (this.issues.length > 0 && this.isSearchByVoiceActive) {
        this.autocompleteTrigger.openPanel();
      }
    });
  }

  showIssue(referenceNo) {
    this.router.navigate(['browse', referenceNo]);
  }

  showIssues() {
    this.stopSearchByVoice(true);
    let params = this.form.value;
    this.router.navigate(['browse'], {queryParams: params});
  }

  showOpenIssues() {
    this.form.controls.statuses.setValue(this.issueService.openIssues);
    this.showIssues();
  }

  showIssuesInProcess() {
    this.form.controls.statuses.setValue(this.issueService.issuesInProcess);
    this.showIssues();
  }
}
