<form fxFlex="0 0 250px" fxLayout="column" fxLayoutGap="10px" novalidate (ngSubmit)="onSubmit(form)" [formGroup]="form">

  <p i18n>Login to Leafhill 247</p>

  <mat-form-field>
    <mat-placeholder i18n>Username</mat-placeholder>
    <input matInput formControlName="username" type="text">
    <mat-hint align="end" i18n>email</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-placeholder i18n>Password</mat-placeholder>
    <input matInput type="password" formControlName="password">
  </mat-form-field>

  <mat-checkbox color="primary" type="text" formControlName="rememberme" fxFlexAlign="end" labelPosition="before">
    <mat-placeholder i18n>Remember me</mat-placeholder>
  </mat-checkbox>

  <br>

  <button type="submit" [disabled]="form.invalid" mat-raised-button color="primary" i18n>Login</button>

</form>
