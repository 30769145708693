import {Component, OnDestroy, OnInit} from "@angular/core";
import {Issue} from "../../issue/issue.interface";
import {NewestService} from "../newest.service";
import {EnumService} from "../../enum/enum.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../../modules/auth/auth.service";
import {AuthGuardService} from "../../../modules/auth/auth-guard.service";
import {NotificationService} from "../../notification/notification.service";
import {superRoles} from "../../../modules/auth/roles";

@Component({
  selector: 'app-newest-issues',
  templateUrl: 'newest-issues.component.html',
  styleUrls: ['newest-issues.component.scss']
})

export class NewestIssuesComponent implements OnInit, OnDestroy {

  private onAuthUpdated: Subscription;
  private onNotificationUpdated: Subscription;
  superRoles = superRoles;
  issues: Array<Issue>;

  constructor(private newestService: NewestService,
              public enumService: EnumService,
              private authService: AuthService,
              private notificationService: NotificationService,
              public authGuard: AuthGuardService) {
    this.findIssues();
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() => this.authService.isAuthenticated() && this.findIssues());
    this.onNotificationUpdated = this.notificationService.onAuditActivity.subscribe(() => this.findIssues());
  }

  ngOnDestroy() {
    if (this.onAuthUpdated)
      this.onAuthUpdated.unsubscribe();
    if (this.onNotificationUpdated)
      this.onNotificationUpdated.unsubscribe();
  }

  findIssues() {
    this.newestService.findNewestIssues(5)
      .subscribe(issues => this.issues = issues);
  }
}
