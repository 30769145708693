<mat-toolbar color="primary" class="primary-background-color hue-2">
  <h2>Successfully copied {{originalIssue.referenceNo}}</h2>
</mat-toolbar>
<mat-card>
  <mat-card-content fxLayout="row">
    <p>Open the new <strong>{{clonedIssue.referenceNo}}</strong>?</p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button matDialogClose i18n>No</button>
    <span fxFlex></span>
    <button mat-raised-button color="primary" [matDialogClose]="true" i18n>Yes</button>
  </mat-card-actions>
</mat-card>
