
import {distinctUntilChanged, debounceTime, filter, startWith} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, Input} from "@angular/core";
import {Issue, DeliveryTerm} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-term-form',
  templateUrl: 'issue-term-form.component.html',
  styleUrls: ['issue-term-form.component.scss']
})
export class IssueTermFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  terms: Array<DeliveryTerm>;
  filteredTerms: Array<DeliveryTerm>;
  form: FormGroup;
  selectedTerm: DeliveryTerm;
  termIdControl: FormControl;

  constructor(private ajaxService: AjaxService, private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    this.ajaxService.findDeliveryTerms().subscribe((terms: Array<DeliveryTerm>) => {
      this.terms = terms;
      this.filteredTerms = terms;
    });
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      termId: new FormControl({value: this.issue.term && this.issue.term.term && this.issue.term.term.id || null, disabled: this.editDisabled}, { validators: Validators.required }),
      location: new FormControl({value: this.issue.term && this.issue.term.location || null, disabled: this.editDisabled}, { validators: Validators.required }),
      notes: new FormControl({value: this.issue.term && this.issue.term.notes || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });

    this.termIdControl = new FormControl({value: this.issue.term && this.issue.term.term || null, disabled: this.editDisabled}, { updateOn: 'change', validators: Validators.required });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());

    this.termIdControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.terms) {
          this.filteredTerms = this.terms.filter(t => {
            const term = t.code.toString() + " " + t.name;
            return term.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {term: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  displayTerm(term: DeliveryTerm) : string {
    let str = "";
    if(!!term) {
      if(!!term.code) {
        str += term.code.toString() + " ";
      }
      str += term.name;
    }
    return str;
  }

  selectTerm(term: DeliveryTerm): void {
    this.form.patchValue({termId: term.id});
    this.termIdControl.setValue(term);
  }

  checkTermField(): void {
    // If the input field is empty on blur => null the value.
    if(!this.termIdControl.value) {
      this.form.patchValue({termId: null});
      this.termIdControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedTerm = this.terms.find(t => t.id === this.form.controls["termId"].value);
      this.termIdControl.setValue(selectedTerm);
    }
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }
}
