import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-now',
  template: '<span>{{date | date: format}}</span>'
})
export class NowComponent {
  date: Date;

  @Input()
  format: String;

  constructor() {
    this.date = new Date();
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }
}
