<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="column">

      <input type="hidden" formControlName="id"/>

      <span fxLayout="row" fxLayoutGap="20px">
        <datepicker
          fxFlex="150px"
          [(date)]="form.value.earliestPickupDate"
          (dateChange)="form.patchValue({ earliestPickupDate: $event })"
          placeholder="Earliest pickup date"
          format="DD.MM.YYYY"
          [disabled]="editDisabled">
        </datepicker>

        <timepicker
          fxFlex="180px"
          [(time)]="form.value.earliestPickupTime"
          (timeChange)="form.patchValue({ earliestPickupTime: $event })"
          placeholder="Time"
          format="HH:mm"
          [disabled]="editDisabled">
        </timepicker>

        <mat-form-field fxFlex="100px">
          <mat-select formControlName="earliestPickupTimeZoneId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of timeZones; trackBy: trackById" [value]="option.id">{{option.offset}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Time zone</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <datepicker
          fxFlex="150px"
          [(date)]="form.value.latestPickupDate"
          (dateChange)="form.patchValue({ latestPickupDate: $event })"
          placeholder="Latest pickup date"
          format="DD.MM.YYYY"
          [disabled]="editDisabled">
        </datepicker>

        <timepicker
          fxFlex="180px"
          [(time)]="form.value.latestPickupTime"
          (timeChange)="form.patchValue({ latestPickupTime: $event })"
          placeholder="Time"
          format="HH:mm"
          [disabled]="editDisabled">
        </timepicker>

        <mat-form-field fxFlex="100px">
          <mat-select formControlName="latestPickupTimeZoneId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of timeZones; trackBy: trackById" [value]="option.id">{{option.offset}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Time zone</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row">
        <mat-checkbox type="text" formControlName="isDifferentFromSender" color="primary" (change)="copySender()">
          <mat-placeholder i18n>Different pickup place from sender</mat-placeholder>
        </mat-checkbox>
      </span>

      <ng-container *ngIf="form.value.isDifferentFromSender">
        <span fxLayout="row">
          <mat-form-field fxFlex="150px" style="margin-right: 20px;">
            <input type="text"
              placeholder="Company"
              matInput
              [formControl]="companyControl"
              autocomplete="off"

              #company
              [matAutocomplete]="autoCompany">
            <mat-autocomplete #autoCompany="matAutocomplete" (optionSelected)="selectCompany($event.option.value)" [displayWith]="displayCompany.bind(this)">
              <mat-option *ngFor="let option of filteredCompanies; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button *ngIf="!editDisabled" mat-icon-button type="button" (click)="copy()">
            <mat-icon>content_copy</mat-icon>
          </button>

          <mat-form-field fxFlex="200px">
            <mat-placeholder i18n>Name</mat-placeholder>
            <input matInput type="text" formControlName="companyName">
          </mat-form-field>
        </span>

        <span fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="150px">
              <input type="text"
                placeholder="Country"
                matInput
                [formControl]="countryControl"
                autocomplete="off"
                (blur)="delayedBlur(checkCountryField.bind(this), 300)"
                #country
                [matAutocomplete]="autoCountry">
              <mat-autocomplete #autoCountry="matAutocomplete" (optionSelected)="selectCountry($event.option.value)" [displayWith]="displayCountry.bind(this)">
                <mat-option *ngFor="let option of filteredCountries; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
              </mat-autocomplete>
          </mat-form-field>

          <mat-form-field fxFlex="200px">
            <mat-placeholder i18n>Street address</mat-placeholder>
            <input type="text" matInput [formControl]="streetControl" [matAutocomplete]="streetAddress" (change)="form.patchValue({ streetAddress: $event.currentTarget.value })">
          </mat-form-field>
          <mat-autocomplete #streetAddress="matAutocomplete">
            <mat-option *ngFor="let option of streetAddresses | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['streetAddress', 'city', 'postalCode', 'state', 'country'])">
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>
        </span>

        <span fxLayout="row" fxLayoutGap="20px">
          <div>
            <mat-form-field fxFlex="150px">
              <mat-placeholder i18n>Postal code</mat-placeholder>
              <input type="text" matInput [formControl]="postalControl" [matAutocomplete]="postal" (change)="form.patchValue({ postalCode: $event.currentTarget.value })">
            </mat-form-field>
            <mat-autocomplete #postal="matAutocomplete">
              <mat-option *ngFor="let option of postalCodes | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['city', 'postalCode', 'state', 'country'])">
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div>
            <mat-form-field fxFlex="150px">
              <mat-placeholder i18n>City</mat-placeholder>
              <input type="text" matInput [formControl]="cityControl" [matAutocomplete]="city" (change)="form.patchValue({ city: $event.currentTarget.value })">
            </mat-form-field>
            <mat-autocomplete #city="matAutocomplete">
              <mat-option *ngFor="let option of cities | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['city', 'postalCode', 'state', 'country'])">
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div>
            <mat-form-field fxFlex="150px">
              <mat-placeholder i18n>State</mat-placeholder>
              <input type="text" matInput [formControl]="stateControl" [matAutocomplete]="state" (change)="form.patchValue({ state: $event.currentTarget.value })">
            </mat-form-field>
            <mat-autocomplete #state="matAutocomplete">
              <mat-option *ngFor="let option of states | async; trackBy: trackById" [value]="option.structured_formatting.main_text" (onSelectionChange)="selectAddress(option, ['state', 'country'])">
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </span>

        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Notes, contact information</mat-placeholder>
          <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" type="text" formControlName="notes"></textarea>
        </mat-form-field>
      </ng-container>
    </mat-card-content>

    <mat-card-content fxLayout="row" fxLayoutGap="20px">
      <mat-form-field fxFlex="150px">
        <input type="text"
          placeholder="Port of origin"
          matInput
          [formControl]="portControl"
          autocomplete="off"
          (blur)="delayedBlur(checkPortField.bind(this), 300)"
          #port
          [matAutocomplete]="autoPort">
        <mat-autocomplete #autoPort="matAutocomplete" (optionSelected)="selectPort($event.option.value)" [displayWith]="displayPort.bind(this)">
          <mat-option *ngFor="let option of filteredPorts; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex="150px">
        <input type="text"
          placeholder="Airport of origin"
          matInput
          [formControl]="airportControl"
          autocomplete="off"
          (blur)="delayedBlur(checkAirportField.bind(this), 300)"
          #port
          [matAutocomplete]="autoAirport">
        <mat-autocomplete #autoAirport="matAutocomplete" (optionSelected)="selectAirport($event.option.value)" [displayWith]="displayPort.bind(this)">
          <mat-option *ngFor="let option of filteredAirports; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </mat-card-content>
  </mat-card>
</form>
