
import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {issueTypeNames} from '../../issue/type/types/types';
@Injectable()
export class RowDataService {

  private datePipe = new DatePipe('en');

  constructor() {
  }
  getRowData(issues: Array<any>) {
    if (issues) {
      let rowData = issues.map(issue => {
        return {
          referenceNo: issue.referenceNo,
          type: issueTypeNames[issue.type],
          customer: issue.owner?.name,
          reference: {
            customerReference: issue.reference?.customerReference,
            projectNumber: issue.reference?.projectNumber,
            pickupReference: issue.reference?.pickupReference,
            otherReference: issue.reference?.otherReference
          },
          status: {
            status: issue.status?.activeStatusName,
            helpdesk_status: issue.status?.helpDeskStatus?.name,
            deadline_date: this.datePipe.transform(issue.deadline?.date, 'dd.MM.yyyy'),
            deadline_time: issue.deadline?.time // this.datePipe.transform(issue.deadline?.time, 'hh:mm')
          },
          subject: {
            subject: issue.subject?.subject,
            note: issue.subject?.note
          },
          claim: {
            relatedParty: issue.claim?.relatedParty,
            owner: issue.claim?.owner,
            amount: issue.claim?.amount,
            amountCurrencyId: issue?.claim?.amountCurrency?.code
          },
          term: {
            code: issue.term?.term?.code,
            name: issue.term?.term?.name,
            location: issue.term?.location,
            notes: issue.term?.notes
          },
          trackingNo: issue.transportation?.trackingNo,
          sender: {
            companyName: issue.sender?.companyName,
            postalCode: issue.sender?.postalCode,
            city: issue.sender?.city,
            countryCode: issue.sender?.countryCode?.name,
            contact: '',
            contact_phone: '',
            contact_email: '',
            notes: issue.sender?.notes
          },
          receiver: {
            company: issue.sender?.companyName,
            postal_code: issue.sender?.postalCode,
            city: issue.sender?.city,
            country_code: issue.sender?.countryCode?.name,
            contact: '',
            contact_phone: '',
            contact_email: '',
            notes: issue.receiver?.notes
          },
          actualDeliveryDate: issue.transportation?.actualDeliveryDate,
          transportMode: issue.transportation?.transportMode?.name,
          serviceLevel: issue.transportation?.serviceLevel?.name,
          logisticsServiceProvider: issue.transportation?.logisticsServiceProvider?.name,
          created: {
            createdBy: issue.createdBy?.name,
            createdDate: this.datePipe.transform(issue.createdDate, 'dd.MM.yyyy')
          },
          linkedIssues: issue.linkedFrom
        }
      });
      return rowData;
    } else {
      return []
    }

  }

  getUserRowData(users: Array<any>) {
    if (users) {
      let userRowData = users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          password: user.password,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          name: user.name,
          title: user.title,
          company: user.userCompanies && user.userCompanies[0].company ? user.userCompanies[0].company.name : '',
          address1: user.address1,
          address2: user.address2,
          address3: user.address3,
          postalCode: user.postalCode,
          city: user.city,
          state: user.state,
          countryCodeId: user.countryCodeId,
          phone: user.phone,
          mobile: user.mobile,
          photoFilename: user.photoFilename,
          photoFileData: user.photoFileData,
          photoFileType: user.photoFileType,
          extId: user.extId,
          userRole: user.userCompanies && user.userCompanies[0].roles ? user.userCompanies[0].roles[0].role : '',
          notes: user.notes,
          gridSettings: user.gridSettings,
          validDateEnd: user.validDateEnd,
          validDateStart: user.validDateStart,
          isActive: user.isActive
        }
      });
      return userRowData;
    } else {
      return []
    }
  }
}
