
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, startWith, map} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {Issue, Company, TransportMode, ServiceLevel, TimeZone, Currency} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormControl} from "@angular/forms";
import {AjaxService} from "../../../ajax/ajax.service";
import {Formatter} from "../../../formatter/formatter";
import {DateValidator} from "../../../validators/date.validator";
import {IssueFormComponent} from "../../issue-form.component";
import {AuthGuardService} from "../../../../modules/auth/auth-guard.service";
import {editorRoles, leafhillRoles} from "../../../../modules/auth/roles";

var self;

@Component({
  selector: 'app-issue-transportation-form',
  templateUrl: 'issue-transportation-form.component.html',
  styleUrls: ['issue-transportation-form.component.scss']
})
export class IssueTransportationFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  @Input() inner: boolean;
  @Input() displayMode: string | 'transport' | 'invoice' | 'trackingNo';
  transportModes: Array<TransportMode>;
  serviceLevels: Array<ServiceLevel>;
  timeZones: Array<TimeZone>;
  logisticsServiceProviders: Array<Company> = [];
  currencies: Array<Currency>;
  form: FormGroup;
  editorRoles = editorRoles;
  leafhillRoles = leafhillRoles;

  logisticsServiceProvider: FormControl;
  filteredProviders: Observable<Company[]>;

  transportModeControl: FormControl;
  filteredTransportModes: Array<TransportMode>;

  constructor(
    public formatter: Formatter,
    private ajaxService: AjaxService,
    private issueService: IssueService,
    private authGuard: AuthGuardService
    ) {
    super();
    self = this;
  }

  ngOnInit() {
    // init empty form
    this.form = new FormGroup({
      transportModeId: new FormControl({value: null, disabled: this.editDisabled}),
      serviceLevelId: new FormControl({value: null, disabled: this.editDisabled}),
      actualPickupDate: new FormControl({value: null, disabled: this.editDisabled}),
      actualPickupTime: new FormControl({value: null, disabled: this.editDisabled}),
      actualPickupTimeZoneId: new FormControl({value: null, disabled: this.editDisabled}),
      actualDeliveryDate: new FormControl({value: null, disabled: this.editDisabled}),
      actualDeliveryTime: new FormControl({value: null, disabled: this.editDisabled}),
      actualDeliveryTimeZoneId: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsServiceProviderId: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostWithoutVat: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostWithoutVatCurrencyId: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostVatRate: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostVat: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostVatCurrencyId: new FormControl({value: null, disabled: true}),
      logisticsCostWithVat: new FormControl({value: null, disabled: this.editDisabled}),
      logisticsCostWithVatCurrencyId: new FormControl({value: null, disabled: true}),
      isReverseCharge: new FormControl({value: null, disabled: this.editDisabled}),
      lspInvoiceNumber: new FormControl({value: null, disabled: this.editDisabled}),
      lspInvoiceDate: new FormControl({value: null, disabled: this.editDisabled}),
      lspInvoiceDueDate: new FormControl({value: null, disabled: this.editDisabled}),
      isLspInvoiceAudit: new FormControl({value: null, disabled: this.editDisabled}),
      isInvoicedToCustomer: new FormControl({value: null, disabled: this.editDisabled}),
      invoiceNumberToCustomer: new FormControl({value: null, disabled: true}),
      invoiceDate: new FormControl({value: null, disabled: this.editDisabled}),
      invoiceDueDate: new FormControl({value: null, disabled: this.editDisabled}),
      customerPaidDate: new FormControl({value: null, disabled: this.editDisabled}),
      trackingNo: new FormControl({value: null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
    this.logisticsServiceProvider = new FormControl({value: null, disabled: this.editDisabled});
    this.filteredProviders = this.logisticsServiceProvider.valueChanges.pipe(
             startWith(null),
             map(val => val ? this.filter(val) : this.logisticsServiceProviders.slice()),);
    this.transportModeControl = new FormControl(null, { updateOn: 'change' });
    if (this.editDisabled) {
      this.transportModeControl.disable();
    }

    observableForkJoin([
      this.ajaxService.findTransportModes().pipe(map((transportModes: Array<TransportMode>) => this.transportModes = transportModes)),
      this.ajaxService.findServiceLevels().pipe(map((serviceLevels: Array<ServiceLevel>) => this.serviceLevels = serviceLevels)),
      this.ajaxService.findTimeZones().pipe(map((timeZones: Array<TimeZone>) => this.timeZones = timeZones)),
      this.ajaxService.findLogisticsServiceProviders().pipe(map((logisticsServiceProviders: Array<Company>) => this.logisticsServiceProviders = logisticsServiceProviders)),
      this.ajaxService.findCurrencies().pipe(map((currencies: Array<Currency>) => this.currencies = currencies))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  filter(val: string): Company[] {
    return this.logisticsServiceProviders.filter(option => new RegExp(`${val}`, 'gi').test(option.name));
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      transportModeId: new FormControl({value: this.issue.transportation && this.issue.transportation.transportMode && this.issue.transportation.transportMode.id || null, disabled: this.editDisabled}),
      serviceLevelId: new FormControl({value: this.issue.transportation && this.issue.transportation.serviceLevel && this.issue.transportation.serviceLevel.id || null, disabled: this.editDisabled}),
      actualPickupDate: new FormControl({value: this.issue.transportation && this.issue.transportation.actualPickupDate || null, disabled: this.editDisabled}),
      actualPickupTime: new FormControl({value: this.issue.transportation && this.issue.transportation.actualPickupTime || null, disabled: this.editDisabled}),
      actualPickupTimeZoneId: new FormControl({value: this.issue.transportation && this.issue.transportation.actualPickupTimeZone && this.issue.transportation.actualPickupTimeZone.id || !this.issue.transportation && this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      actualDeliveryDate: new FormControl({value: this.issue.transportation && this.issue.transportation.actualDeliveryDate || null, disabled: this.editDisabled}, { validators: DateValidator.date }),
      actualDeliveryTime: new FormControl({value: this.issue.transportation && this.issue.transportation.actualDeliveryTime || null, disabled: this.editDisabled}),
      actualDeliveryTimeZoneId: new FormControl({value: this.issue.transportation && this.issue.transportation.actualDeliveryTimeZone && this.issue.transportation.actualDeliveryTimeZone.id || !this.issue.transportation && this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      logisticsServiceProviderId: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsServiceProvider && this.issue.transportation.logisticsServiceProvider.id || null, disabled: this.editDisabled}),
      logisticsCostWithoutVat: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostWithoutVat || null, disabled: this.editDisabled}),
      logisticsCostWithoutVatCurrencyId: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostWithoutVatCurrency && this.issue.transportation.logisticsCostWithoutVatCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: this.editDisabled}),
      logisticsCostVatRate: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostVatRate || !this.issue.transportation && this.ajaxService.defaultVatRate() || null, disabled: this.editDisabled}),
      logisticsCostVat: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostVat || null, disabled: this.editDisabled}),
      logisticsCostVatCurrencyId: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostVatCurrency && this.issue.transportation.logisticsCostVatCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: this.editDisabled}),
      logisticsCostWithVat: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostWithVat || null, disabled: this.editDisabled}),
      logisticsCostWithVatCurrencyId: new FormControl({value: this.issue.transportation && this.issue.transportation.logisticsCostWithVatCurrency && this.issue.transportation.logisticsCostWithVatCurrency.id || this.ajaxService.defaultCurrencyId(this.currencies) || null, disabled: this.editDisabled}),
      isReverseCharge: new FormControl({value: this.issue.transportation && this.issue.transportation.isReverseCharge || null, disabled: this.editDisabled}),
      lspInvoiceNumber: new FormControl({value: this.issue.transportation && this.issue.transportation.lspInvoiceNumber || null, disabled: this.editDisabled}),
      lspInvoiceDate: new FormControl({value: this.issue.transportation && this.issue.transportation.lspInvoiceDate || null, disabled: this.editDisabled}),
      lspInvoiceDueDate: new FormControl({value: this.issue.transportation && this.issue.transportation.lspInvoiceDueDate || null, disabled: this.editDisabled}),
      isLspInvoiceAudit: new FormControl({value: this.issue.transportation && this.issue.transportation.isLspInvoiceAudit || null, disabled: this.editDisabled}),
      isInvoicedToCustomer: new FormControl({value: this.issue.transportation && this.issue.transportation.isInvoicedToCustomer || null, disabled: this.editDisabled}),
      invoiceNumberToCustomer: new FormControl({value: this.issue.transportation && this.issue.transportation.invoiceNumberToCustomer || null, disabled: this.editDisabled}),
      invoiceDate: new FormControl({value: this.issue.transportation && this.issue.transportation.invoiceDate || null, disabled: this.editDisabled}),
      invoiceDueDate: new FormControl({value: this.issue.transportation && this.issue.transportation.invoiceDueDate || null, disabled: this.editDisabled}),
      customerPaidDate: new FormControl({value: this.issue.transportation && this.issue.transportation.customerPaidDate || null, disabled: this.editDisabled}),
      trackingNo: new FormControl({value: this.issue.transportation && this.issue.transportation.trackingNo || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });

    let val = this.displaySP(this.issue.transportation && this.issue.transportation.logisticsServiceProvider && this.issue.transportation.logisticsServiceProvider.name);
    this.logisticsServiceProvider.setValue(val);

    this.transportModeControl.setValue(this.issue.transportation && this.issue.transportation.transportMode || null);
    this.filteredTransportModes = this.transportModes.slice();

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());

    this.transportModeControl.valueChanges.pipe(
      startWith(null))
      .subscribe((val) => {
        let searchTerm = "";
        if(!!val && val.name) {
          searchTerm = val.name;
        } else if(!!val) {
          searchTerm = val.toString();
        }
        if(this.transportModes) {
          this.filteredTransportModes = this.transportModes.filter(t => {
            const tm = t.code.toString() + " " + t.name;
            return tm.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      });
  }

  displaySP(sp: any) {
    if (sp === null || sp === undefined) {
      return sp;
    }

    if (sp.name) {
      return sp.name;
    }

    let x = self.logisticsServiceProviders.filter(i => { return i.name === sp; })
      .map(i => i.name);
    return x[0];
  }

  resetLSP() {
    let ctrl = this.logisticsServiceProvider;
    if (ctrl.value === undefined || ctrl.value === null || ctrl.value === '')
      this.form.patchValue({ logisticsServiceProviderId: null });
  }

  updateLSP(value: string, event: any) {
    // Two events will be fired on selection: deselect old and select new value
    if (event.source.selected === true)
      this.form.patchValue({ logisticsServiceProviderId: value });
  }

  update() {
    if (this.form.status === "INVALID")
      return;
    let form = this.form.getRawValue();
    this.issueService.patch(this.issue.referenceNo, {transportation: form}).subscribe((issue: Issue) => {
      // reflect value changes to fields managed by API
      this.form.patchValue({
        logisticsCostVat: issue.transportation && issue.transportation.logisticsCostVat || null,
        logisticsCostVatCurrencyId: issue.transportation && issue.transportation.logisticsCostVatCurrency && issue.transportation.logisticsCostVatCurrency.id || null,
        logisticsCostWithVat: issue.transportation && issue.transportation.logisticsCostWithVat || null,
        logisticsCostWithVatCurrencyId: issue.transportation && issue.transportation.logisticsCostWithVatCurrency && issue.transportation.logisticsCostWithVatCurrency.id || null
      }, {emitEvent: false});
      this.changeEmitter.emit(issue)
    });
  }

  displayTransportMode(tm: TransportMode) : string {
    return tm ? tm.code + " " + tm.name : "";
  }

  selectTransportMode(tm: TransportMode): void {
    this.form.patchValue({transportModeId: tm.id});
    this.transportModeControl.setValue(tm);
  }

  checkTransportModeField(): void {
    //If the input field is empty on blur => null the value.
    if(!this.transportModeControl.value) {
      this.form.patchValue({transportModeId: null});
      this.transportModeControl.setValue(null);
    } else {
      // Set the input field text to match the selected country
      const selectedTransportMode = this.transportModes.find(cn => cn.id === this.form.value.transportModeId);
      this.transportModeControl.setValue(selectedTransportMode);
    }
  }

  delayedBlur(f, timeout): void {
    // Timeout is needed because the blur event gets called differently depending on
    // whether the autocomplete option was selected using keyboard or mouse causing blur to trigger before change.
    // Own function simply for comment centralization.
    setTimeout(f, !!timeout ? timeout : 300);
  }

  trackById = (index, item) => {
    return item.id;
  }
}
