import {Component, OnInit, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-issue-upload-form',
  templateUrl: 'issue-upload-form.component.html',
  styleUrls: ['issue-upload-form.component.scss']
})
export class IssueUploadFormComponent implements OnInit {
  @Input() form: FormGroup;
  descriptions: Array<string>;

  constructor() {
  }

  ngOnInit() {
    this.descriptions = ['CMR/AWB/BL', 'Packing list', 'Invoice / Proforma invoice', 'Customs declaration', 'Freight invoice']
  }
}
