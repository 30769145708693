
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input} from "@angular/core";
import {Issue, Company, TimeZone, CountryCode, IssueDelivery} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-deliveries-form',
  templateUrl: 'issue-deliveries-form.component.html',
  styleUrls: ['issue-deliveries-form.component.scss']
})
export class IssueDeliveriesFormComponent extends IssueFormComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  timeZones: Array<TimeZone>;
  countries: Array<CountryCode>;
  deliveries: Observable<Array<Company>>;
  form: FormGroup;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private ajaxService: AjaxService,
              private issueService: IssueService,
              private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.deliveries = this.ajaxService.findDeliveries(this.issue.referenceNo);

    observableForkJoin([
      this.ajaxService.findTimeZones().pipe(map(timeZones => this.timeZones = timeZones)),
      this.ajaxService.findCountries().pipe(map(countries => this.countries = countries))
    ]).subscribe(() => {
      this.initForm();
    });
  }

  refreshDeliveries() {
    this.deliveries = this.ajaxService.findDeliveries(this.issue.referenceNo);
  }

  initForm() {
    this.form = new FormGroup({
      deliveries: new FormArray(this.initSubForms())
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms() {
    let subForms = [];
    if (this.issue.deliveries && this.issue.deliveries.length > 0) {
      this.issue.deliveries.forEach(delivery => subForms.push(this.initSubForm(delivery)));
    }
    return subForms;
  }

  initSubForm(delivery: IssueDelivery) {
    if (delivery && delivery.id) {
      return this.initExisting(delivery);
    } else {
      return this.initNew();
    }
  }

  initExisting(delivery: IssueDelivery) {
    return new FormGroup({
      isOpen: new FormControl({value: false, disabled: this.editDisabled}),
      id: new FormControl({value: delivery && delivery.id || null, disabled: this.editDisabled}),
      earliestDeliveryDate: new FormControl({value: delivery && delivery.earliestDeliveryDate || null, disabled: this.editDisabled}),
      earliestDeliveryTime: new FormControl({value: delivery && delivery.earliestDeliveryTime || null, disabled: this.editDisabled}),
      earliestDeliveryTimeZoneId: new FormControl({value: delivery && delivery.earliestDeliveryTimeZone && delivery.earliestDeliveryTimeZone.id || null, disabled: this.editDisabled}),
      latestDeliveryDate: new FormControl({value: delivery && delivery.latestDeliveryDate || null, disabled: this.editDisabled}),
      latestDeliveryTime: new FormControl({value: delivery && delivery.latestDeliveryTime || null, disabled: this.editDisabled}),
      latestDeliveryTimeZoneId: new FormControl({value: delivery && delivery.latestDeliveryTimeZone && delivery.latestDeliveryTimeZone.id || null, disabled: this.editDisabled}),
      isDifferentFromReceiver: new FormControl({value: delivery && delivery.isDifferentFromReceiver || false, disabled: this.editDisabled}, { updateOn: 'change' }),
      companyId: new FormControl({value: delivery && delivery.company && delivery.company.id || null, disabled: this.editDisabled}),
      companyName: new FormControl({value: delivery && delivery.companyName || null, disabled: this.editDisabled}),
      countryCodeId: new FormControl({value: delivery && delivery.countryCode && delivery.countryCode.id || null, disabled: this.editDisabled}),
      streetAddress: new FormControl({value: delivery && delivery.streetAddress || null, disabled: this.editDisabled}),
      postalCode: new FormControl({value: delivery && delivery.postalCode || null, disabled: this.editDisabled}),
      city: new FormControl({value: delivery && delivery.city || null, disabled: this.editDisabled}),
      state: new FormControl({value: delivery && delivery.state || null, disabled: this.editDisabled}),
      notes: new FormControl({value: delivery && delivery.notes || null, disabled: this.editDisabled}),
      portId: new FormControl({value: delivery && delivery.port && delivery.port.id || null, disabled: this.editDisabled}),
      airportId: new FormControl({value: delivery && delivery.airport && delivery.airport.id || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  initNew() {
    return new FormGroup({
      isOpen: new FormControl({value: true, disabled: this.editDisabled}),
      id: new FormControl({value: null, disabled: this.editDisabled}),
      earliestDeliveryDate: new FormControl({value: null, disabled: this.editDisabled}),
      earliestDeliveryTime: new FormControl({value: null, disabled: this.editDisabled}),
      earliestDeliveryTimeZoneId: new FormControl({value: this.issue.receiver && this.issue.receiver.company && this.issue.receiver.company.timeZone && this.issue.receiver.company.timeZone.id || this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      latestDeliveryDate: new FormControl({value: null, disabled: this.editDisabled}),
      latestDeliveryTime: new FormControl({value: null, disabled: this.editDisabled}),
      latestDeliveryTimeZoneId: new FormControl({value: this.issue.receiver && this.issue.receiver.company && this.issue.receiver.company.timeZone && this.issue.receiver.company.timeZone.id || this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      isDifferentFromReceiver: new FormControl({value: false, disabled: this.editDisabled}, { updateOn: 'change' }),
      companyId: new FormControl({value: this.issue.receiver && this.issue.receiver.company && this.issue.receiver.company.id, disabled: this.editDisabled}),
      companyName: new FormControl({value: this.issue.receiver && this.issue.receiver.companyName, disabled: this.editDisabled}),
      countryCodeId: new FormControl({value: this.issue.receiver && this.issue.receiver.countryCode && this.issue.receiver.countryCode.id || this.ajaxService.defaultCountryCodeId(this.countries) || null, disabled: this.editDisabled}),
      streetAddress: new FormControl({value: this.issue.receiver && this.issue.receiver.streetAddress || null, disabled: this.editDisabled}),
      postalCode: new FormControl({value: this.issue.receiver && this.issue.receiver.postalCode || null, disabled: this.editDisabled}),
      city: new FormControl({value: this.issue.receiver && this.issue.receiver.city || null, disabled: this.editDisabled}),
      state: new FormControl({value: this.issue.receiver && this.issue.receiver.state || null, disabled: this.editDisabled}),
      notes: new FormControl({value: this.issue.receiver && this.issue.receiver.notes || null, disabled: this.editDisabled}),
      portId: new FormControl({value: null, disabled: this.editDisabled}),
      airportId: new FormControl({value: null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['deliveries'];
    control.push(this.initNew());
    setTimeout(() => {
      this.expansionPanels.last.open();
    });
  }

  toggleIsOpen(formGroup: FormGroup) {
    formGroup.patchValue({isOpen: !formGroup.value.isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['deliveries'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    const control = <FormArray>this.form.controls['deliveries'];
    control.controls.forEach((subForm: FormGroup) => {
      if (isNaN(subForm.value.companyId)) {
        subForm.value.companyId = null;
        subForm.patchValue({id: null}, {emitEvent: false});
      }
    });

    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      control.controls.forEach((subForm: FormGroup, index: number) => {
        subForm.patchValue({id: issue.deliveries[index].id}, {emitEvent: false});
      });
      this.changeEmitter.emit(issue);
    });
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
    this.refreshDeliveries();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
