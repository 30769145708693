<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h2 i18n>Pickup schedules and locations</h2>

    <button *ngIf="!editDisabled" mat-button color="accent" (click)="addSubForm()">
      <h2 fxHide.lt-lg fxShow.gt-md i18n>Add pickup details</h2>
      <mat-icon fxHide.gt-md fxShow.lt-lg>add_circle</mat-icon>
    </button>
  </div>

  <form *ngIf="issue && form" novalidate [formGroup]="form">
    <div formArrayName="pickups">
      <mat-accordion [multi]="false" displayMode="default">
        <mat-expansion-panel *ngIf="form.controls.pickups?.length === 0" [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="text-small" i18n>There are no pickup schedules and locations</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let subForm of form.controls.pickups.controls; let i = index; trackBy: trackById" [expanded]="i === 0">
          <mat-expansion-panel-header>
            <mat-panel-title fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxLayout="column" fxFlex="60px">
                <span class="text-small" i18n>Pickup #{{i+1}}</span>
              </div>
              <div fxLayout="column">
                <span class="text-small">
                  <strong *ngIf="subForm.value.companyName">{{subForm.value.companyName}}</strong>
                </span>
                <span class="text-small" fxLayoutGap="2px">
                  <span *ngIf="subForm.value.streetAddress">{{subForm.value.streetAddress}}</span>
                  <span *ngIf="subForm.value.postalCode">{{subForm.value.postalCode}}</span>
                  <span *ngIf="subForm.value.city">{{subForm.value.city}}</span>
                  <span *ngIf="subForm.value.state">{{subForm.value.state}}</span>
                </span>
                <span class="text-small color-light" i18n *ngIf="subForm.value.earliestPickupDate && subForm.value.latestPickupDate" i18n>
                  Between {{subForm.value.earliestPickupDate | date:'dd.MM.yyyy'}} {{subForm.value.earliestPickupTime | time}} and {{subForm.value.latestPickupDate | date:'dd.MM.yyyy'}} {{subForm.value.latestPickupTime | time}}
                </span>
                <i class="text-small color-light">{{subForm.value.notes}}</i>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <mat-divider></mat-divider>
            <app-issue-pickup-form [issue]="issue" [form]="subForm" [timeZones]="timeZones" [pickups]="pickups" [countries]="countries" [editDisabled]="editDisabled"></app-issue-pickup-form>
            <mat-divider *ngIf="!editDisabled"></mat-divider>
            <button *ngIf="!editDisabled" mat-button color="warn" (click)="removeSubForm(i)">
              <span i18n>DELETE PICKUP</span>
            </button>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>

</div>
