
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter} from "@angular/core";
import {Issue, ExceptionClaim} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormControl} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-exception-form',
  templateUrl: 'issue-exception-form.component.html',
  styleUrls: ['issue-exception-form.component.scss']
})
export class IssueExceptionFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  exceptionClaims: Array<ExceptionClaim>;
  form: FormGroup;

  constructor(private ajaxService: AjaxService, private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    // INITIALIZE EMPTY FORM
    this.form = new FormGroup({
      exceptionClaimId: new FormControl()
    }, { updateOn: 'blur' });

    observableForkJoin([
      this.ajaxService.findExceptionClaims().pipe(map(claims => this.exceptionClaims = claims))
    ]).subscribe(any => {
      this.initForm();
    });
  }

  initForm() {
    let exception = this.issue && this.issue.exception;
    this.form = new FormGroup({
      exceptionClaimId: new FormControl(exception && exception.exceptionClaim && exception.exceptionClaim.id || null)
    }, { updateOn: 'blur' });
    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {exception: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
