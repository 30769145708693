import {Component, EventEmitter, Input, Output, ChangeDetectionStrategy} from "@angular/core";
import {Formatter} from "../formatter/formatter";
import {isNumeric} from "rxjs/internal-compatibility";


@Component({
  selector: 'integerinput',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'integerinput.html'
})
export class IntegerInputComponent {
  @Input() value: any;
  @Input() placeholder: string;
  @Input() suffix: string;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter();

  public inputValue: any = '';

  constructor(public formatter : Formatter) {
  }

  ngOnChanges(...args: any[]) {
    if (args.length > 0 && args[0].value) {
      if (isNumeric(args[0].value.currentValue)) {
        this.inputValue = args[0].value.currentValue;
      } else {
        this.inputValue = '';
      }
    }
  }

  valueChanged(event: any) {
    this.valueChange.emit(event.currentTarget.value);
  }

}
