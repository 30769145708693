
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input} from "@angular/core";
import {Issue, Company, TimeZone, CountryCode, IssuePickup} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {AjaxService} from "../../../ajax/ajax.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-pickups-form',
  templateUrl: 'issue-pickups-form.component.html',
  styleUrls: ['issue-pickups-form.component.scss']
})
export class IssuePickupsFormComponent extends IssueFormComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() editDisabled: boolean;
  timeZones: Array<TimeZone>;
  countries: Array<CountryCode>;
  pickups: Observable<Array<Company>>;
  form: FormGroup;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private ajaxService: AjaxService,
              private issueService: IssueService,
              private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.pickups = this.ajaxService.findPickups(this.issue.referenceNo);

    observableForkJoin([
      this.ajaxService.findTimeZones().pipe(map(timeZones => this.timeZones = timeZones)),
      this.ajaxService.findCountries().pipe(map(countries => this.countries = countries))
    ]).subscribe(() => {
      this.initForm();
    });
  }

  refreshPickups() {
    this.pickups = this.ajaxService.findPickups(this.issue.referenceNo);
  }

  initForm() {
    this.form = new FormGroup({
      pickups: new FormArray(this.initSubForms())
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  initSubForms() {
    let subForms = [];
    if (this.issue.pickups && this.issue.pickups.length > 0) {
      this.issue.pickups.forEach(pickup => subForms.push(this.initSubForm(pickup)));
    }
    return subForms;
  }

  initSubForm(pickup: IssuePickup) {
    if (pickup && pickup.id) {
      return this.initExisting(pickup)
    } else {
      return this.initNew();
    }
  }

  initExisting(pickup: IssuePickup) {
    return new FormGroup({
      isOpen: new FormControl({value: false, disabled: this.editDisabled}),
      id: new FormControl({value: pickup && pickup.id || null, disabled: this.editDisabled}),
      earliestPickupDate: new FormControl({value: pickup && pickup.earliestPickupDate || null, disabled: this.editDisabled}),
      earliestPickupTime: new FormControl({value: pickup && pickup.earliestPickupTime || null, disabled: this.editDisabled}),
      earliestPickupTimeZoneId: new FormControl({value: pickup && pickup.earliestPickupTimeZone && pickup.earliestPickupTimeZone.id || null, disabled: this.editDisabled}),
      latestPickupDate: new FormControl({value: pickup && pickup.latestPickupDate || null, disabled: this.editDisabled}),
      latestPickupTime: new FormControl({value: pickup && pickup.latestPickupTime || null, disabled: this.editDisabled}),
      latestPickupTimeZoneId: new FormControl({value: pickup && pickup.latestPickupTimeZone && pickup.latestPickupTimeZone.id || null, disabled: this.editDisabled}),
      isDifferentFromSender: new FormControl({value: pickup && pickup.isDifferentFromSender || false, disabled: this.editDisabled}, { updateOn: 'change' }),
      companyId: new FormControl({value: pickup && pickup.company && pickup.company.id || null, disabled: this.editDisabled}),
      companyName: new FormControl({value: pickup && pickup.companyName || null, disabled: this.editDisabled}),
      countryCodeId: new FormControl({value: pickup && pickup.countryCode && pickup.countryCode.id || null, disabled: this.editDisabled}),
      streetAddress: new FormControl({value: pickup && pickup.streetAddress || null, disabled: this.editDisabled}),
      postalCode: new FormControl({value: pickup && pickup.postalCode || null, disabled: this.editDisabled}),
      city: new FormControl({value: pickup && pickup.city || null, disabled: this.editDisabled}),
      state: new FormControl({value: pickup && pickup.state || null, disabled: this.editDisabled}),
      notes: new FormControl({value: pickup && pickup.notes || null, disabled: this.editDisabled}),
      portId: new FormControl({value: pickup && pickup.port && pickup.port.id || null, disabled: this.editDisabled}),
      airportId: new FormControl({value: pickup && pickup.airport && pickup.airport.id || null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  initNew() {
    return new FormGroup({
      isOpen: new FormControl({value: true, disabled: this.editDisabled}),
      id: new FormControl({value: null, disabled: this.editDisabled}),
      earliestPickupDate: new FormControl({value: null, disabled: this.editDisabled}),
      earliestPickupTime: new FormControl({value: null, disabled: this.editDisabled}),
      earliestPickupTimeZoneId: new FormControl({value: this.issue.sender && this.issue.sender.company && this.issue.sender.company.timeZone && this.issue.sender.company.timeZone.id || this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      latestPickupDate: new FormControl({value: null, disabled: this.editDisabled}),
      latestPickupTime: new FormControl({value: null, disabled: this.editDisabled}),
      latestPickupTimeZoneId: new FormControl({value: this.issue.sender && this.issue.sender.company && this.issue.sender.company.timeZone && this.issue.sender.company.timeZone.id || this.ajaxService.defaultTimeZoneId(this.timeZones) || null, disabled: this.editDisabled}),
      isDifferentFromSender: new FormControl({value: false, disabled: this.editDisabled}, { updateOn: 'change' }),
      companyId: new FormControl({value: this.issue.sender && this.issue.sender.company && this.issue.sender.company.id || null, disabled: this.editDisabled}),
      companyName: new FormControl({value: this.issue.sender && this.issue.sender.companyName || null, disabled: this.editDisabled}),
      countryCodeId: new FormControl({value: this.issue.sender && this.issue.sender.countryCode && this.issue.sender.countryCode.id || this.ajaxService.defaultCountryCodeId(this.countries) || null, disabled: this.editDisabled}),
      streetAddress: new FormControl({value: this.issue.sender && this.issue.sender.streetAddress || null, disabled: this.editDisabled}),
      postalCode: new FormControl({value: this.issue.sender && this.issue.sender.postalCode || null, disabled: this.editDisabled}),
      city: new FormControl({value: this.issue.sender && this.issue.sender.city || null, disabled: this.editDisabled}),
      state: new FormControl({value: this.issue.sender && this.issue.sender.state || null, disabled: this.editDisabled}),
      notes: new FormControl({value: this.issue.sender && this.issue.sender.notes || null, disabled: this.editDisabled}),
      portId: new FormControl({value: null, disabled: this.editDisabled}),
      airportId: new FormControl({value: null, disabled: this.editDisabled})
    }, { updateOn: 'blur' });
  }

  addSubForm() {
    const control = <FormArray>this.form.controls['pickups'];
    control.push(this.initNew());
    setTimeout(() => {
      this.expansionPanels.last.open();
    });
  }

  toggleIsOpen(formGroup: FormGroup) {
    formGroup.patchValue({isOpen: !formGroup.value.isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['pickups'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
    });
  }

  update() {
    const control = <FormArray>this.form.controls['pickups'];
    control.controls.forEach((subForm: FormGroup) => {
      if (isNaN(subForm.value.companyId)) {
        subForm.value.companyId = null;
        subForm.patchValue({id: null}, {emitEvent: false});
      }
    });

    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      control.controls.forEach((subForm: FormGroup, index: number) => {
        let pickup = issue.pickups[index];
        subForm.patchValue({id: pickup.id || null}, {emitEvent: false});
      });
      this.changeEmitter.emit(issue)
    });
  }

  refreshStatus(issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
    this.refreshPickups();
  }

  trackById = (index, item) => {
    return item.id;
  }
}
