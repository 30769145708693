
import {distinctUntilChanged, debounceTime, map, startWith} from 'rxjs/operators';
import {Component, Input, Output, EventEmitter, Inject, ViewChild, ElementRef, OnInit} from "@angular/core";
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import {FormService} from "../../form/form.service";
import {Data} from "../../../shared/data/data";
import {COMMA, ENTER, SEMICOLON, SPACE} from "@angular/cdk/keycodes";
import {Environment} from "../../environment/environment";
import {Issue} from "../../../shared/issue/issue.interface";
import {Email} from "../email";
import {IssueService} from "../../../shared/issue/issue.service";
import {RemoveDialog} from "../../../shared/remove/remove-dialog.component";
import {AuthGuardService} from "../../auth/auth-guard.service";
import {Observable} from "rxjs";
import {AjaxService} from "../../../shared/ajax/ajax.service";
import {QuillEditorComponent} from "ngx-quill";
// noinspection TypeScriptCheckImport
import * as Quill from "quill";

@Component({
  selector: 'mod-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {
  searchForm: FormGroup;
  emailForm: FormGroup;
  @Input() email: Email;
  @Input() issue: Issue;
  @Input() isEditable: boolean;
  @Input() isDeletable: boolean;
  @Input() isLinkable: boolean;
  @Input() isAttachable: boolean;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLink: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAttach: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReset: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput: ElementRef;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, SEMICOLON];
  domain: string;
  issues: Array<Issue>;
  isShowCc: boolean = false;
  isShowBcc: boolean = false;

  filteredAddresses: Observable<string[]>;
  addresses: string[];
  addressCtrl: FormControl = new FormControl();

  @ViewChild('toInput') toInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccInput') ccInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccInput') bccInput: ElementRef<HTMLInputElement>;
  @ViewChild('toAuto') toAuto: MatAutocomplete;
  @ViewChild('ccAuto') ccAuto: MatAutocomplete;
  @ViewChild('bccAuto') bccAuto: MatAutocomplete;

  @ViewChild('editor') editor: QuillEditorComponent;

  /**
   * Configuration of available modules for Quill Editor
   */
  quillModules;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private formService: FormService,
              private ajaxService: AjaxService,
              private issueService: IssueService,
              private data: Data,
              public authGuard: AuthGuardService,
              @Inject('APP_ENV') private env: Environment) {
    this.addresses = [];
    this.domain = this.env.domain;
    this.initQuillEditorConfiguration();
  }

  /**
   * Overrides for Quill editor configuration
   */
  initQuillEditorConfiguration() {
    // module configuration
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                        // remove formatting button

        ['link']                                          // link and image, video
      ]
    };

    // override p with div tag
    const Parchment = Quill.import('parchment');
    let Block = Parchment.query('block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
  }

  ngOnInit() {
    if (this.email.sent || this.email.received || this.email.error || !this.isDraft()) {
      this.isEditable = false;
    }

    this.ajaxService.findEmailAddresses().subscribe(addresses => this.addresses = addresses);
    this.filteredAddresses = this.addressCtrl.valueChanges
      .pipe(
        startWith(null),
        map((value: string | null) => value ? this.filterAddresses(value) : this.addresses.slice())
      );

    this.initForm();
    this.initSearchForm();
  }

  initForm() {
    this.emailForm = this.fb.group({
      uuid: this.formService.initIdFormControl(this.email && this.email.uuid, false),
      referenceNo: this.formService.initStringFormControl(this.email && this.email.referenceNo || this.issue && this.issue.referenceNo, [], this.isEditable),
      sender: this.formService.initStringFormControl(this.email && this.email.sender || this.defaultSender(this.issue && this.issue.referenceNo || null), [Validators.required], false),
      to: this.formService.initStringFormArray(this.email && this.email.to, [], this.isEditable),
      cc: this.formService.initStringFormArray(this.email && this.email.cc, [], this.isEditable),
      bcc: this.formService.initStringFormArray(this.email && this.email.bcc, [], this.isEditable),
      subject: this.formService.initStringFormControl(this.email && this.email.subject || this.defaultSubject(), [Validators.required], this.isEditable),
      text: this.formService.initStringFormControl(this.email && this.email.text || '', [], this.isEditable),
      html: this.formService.initStringFormControl(this.email && this.email.html || this.defaultBody(true, true), [Validators.required], this.isEditable)
    });

    this.toggleShowCcAndBcc();
  }

  toggleShowCcAndBcc() {
    let formValue = this.emailForm.getRawValue();
    if (formValue.cc.length > 0) {
      this.isShowCc = true;
    }
    if (formValue.bcc.length > 0) {
      this.isShowBcc = true;
    }
  }

  private filterAddresses(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.addresses.filter(address => address.toLowerCase().indexOf(filterValue) === 0);
  }

  initSearchForm() {
    this.searchForm = new FormGroup({
      searchTerm: new FormControl()
    }, { updateOn: 'change' });

    this.searchForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.find());
  }

  resetForm() {
    if (this.onReset.observers.length > 0) {
      this.onReset.next();
    } else {
      this.initForm();
    }
  }

  isResetDisabled() {
    return false;
  }

  saveDraft() {
    let email = Object.assign(this.email, this.emailForm.getRawValue());
    email.draft = new Date();
    this.onUpdate.next(email);
    this.toggleShowCcAndBcc();
  }

  delete() {
    let dialogRef = this.dialog.open(RemoveDialog);
    dialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove){
        this.onDelete.next({ uuid: this.email.uuid });
      }
    });
  }

  defaultSender(referenceNo?): string {
    if (referenceNo == null) {
      return `Leafhill+247@${this.domain}`
    } else {
      return `${referenceNo}@${this.domain}`
    }
  }

  defaultSubject(): string {
    return this.isDraft() ? `Leafhill 247` : '';
  }

  defaultBody(isWithSignature: boolean = true, isHtml: boolean = false) {
    if (this.isDraft()) {
      return isWithSignature ? `${this.defaultSignature()}` : '';
    } else {
      return '';
    }
  }

  defaultSignature(): string {
    return `<br><br>With best regards,
            <br><br>${this.data.me.name || ''}
            <br>${this.data.me.title || ''}
            <br><br>Leafhill Solutions Oy
            <br>Lauttakylänkatu 15
            <br>FI-32700 Huittinen, Finland
            <br>Phone <a href="tel:${this.data.me.phone || ''}">${this.data.me.phone || ''}</a>
            <br>Mobile <a href="tel:${this.data.me.mobile || ''}">${this.data.me.mobile || ''}</a>
            <br>e-mail <a href="mailto:${this.data.me.email || ''}">${this.data.me.email || ''}</a>
            <br>Web <a href="https://www.leafhill.fi">www.leafhill.fi</a>`;
  }

  add(arr: FormArray, event: any) {
    if (!(this.toAuto && this.toAuto.isOpen) && !(this.ccAuto && this.ccAuto.isOpen) && !(this.bccAuto && this.bccAuto.isOpen)) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        arr.push(this.formService.initStringFormControl(value.trim(), [Validators.required], this.isEditable));
      }
      if (input) {
        input.value = '';
      }
      this.addressCtrl.setValue(null);
    }
  }

  select(arr: FormArray, event: any) {
    arr.push(this.formService.initStringFormControl(event.value, [Validators.required], this.isEditable));
    if (this.toInput && this.toAuto.isOpen) {
      this.toInput.nativeElement.value = '';
    }
    if (this.ccInput && this.ccAuto.isOpen) {
      this.ccInput.nativeElement.value = '';
    }
    if (this.bccInput && this.bccAuto.isOpen) {
      this.bccInput.nativeElement.value = '';
    }

    this.addressCtrl.setValue(null);
  }



  remove(arr: FormArray, recipient: string) {
    const index = arr.value.indexOf(recipient);
    if (index >= 0) {
      arr.removeAt(index);
    }
  }

  find() {
    this.issueService.find(this.searchForm.value.searchTerm).subscribe(issues => this.issues = issues);
  }

  linkIssue(referenceNo) {
    this.emailForm.patchValue({referenceNo: referenceNo});
    this.searchForm.patchValue({searchTerm: ''});
    if (this.isDraft()) {
      this.updateSender(referenceNo);
    }
    if (!this.isDraft()) {
      let email = Object.assign(this.email, this.emailForm.getRawValue());
      this.onLink.next(email);
    }
  }

  unlinkIssue() {
    this.emailForm.patchValue({referenceNo: null});
    if (this.isDraft()) {
      this.updateSender(null);
    }
    if (!this.isDraft()) {
      let email = Object.assign(this.email, this.emailForm.getRawValue());
      this.onLink.next(email);
    }
  }

  updateSender(referenceNo?) {
    this.emailForm.patchValue({sender: this.defaultSender(referenceNo)});
  }

  /**
   * Email is draft if it has a draft timestamp
   */
  isDraft() {
    return this.email.draft;
  }

  /**
   * Email is pending being sent when it doesn't have both draft, sent, error, or received timestamps
   */
  isBeingSent() {
    return !this.email.sent && !this.email.draft && !this.email.error && !this.email.received;
  }

  /**
   * Email is ready for sending if it has
   * 1. valid sender
   * 2. valid recipient (either to, cc, bcc)
   * 3. valid subject
   * 4. valid body (text or html)
   */
  isReady() {
    let formValue = this.emailForm.getRawValue();
    let isReady = this.emailForm.valid;
    if (!formValue.sender) {
      isReady = false;
    }
    if (formValue.to.length <= 0 && formValue.cc.length <= 0 && formValue.bcc.length <= 0) {
      isReady = false;
    }
    if (formValue.subject.trim().length <= 0) {
      isReady = false;
    }
    if (formValue.text.trim().length <= 0 && formValue.html.trim().length <= 0) {
      isReady = false;
    }
    return isReady;
  }

  send() {
    let email = Object.assign(this.email, this.emailForm.getRawValue());
    delete email.draft;
    this.onUpdate.next(email);
    this.toggleShowCcAndBcc();
  }

  undoSend() {
    let email = Object.assign(this.email, this.emailForm.getRawValue());
    email.draft = new Date();
    this.onUpdate.next(email);
    this.toggleShowCcAndBcc();
  }

  /**
   * Email is read if it has a read timestamp
   */
  isRead() {
    return this.email.read;
  }

  addAttachment(event: any) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.onAttach.next(file);
    }
  }

  removeAttachment(attachment) {
    let dialogRef = this.dialog.open(RemoveDialog);
    dialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove){
        this.onRemove.next(attachment);
      }
    });
  }

  download(attachment) {
    this.onDownload.next(attachment);
  }

  onContentChanged(change) {
    this.emailForm.patchValue({text: change.text}, {emitEvent: false, onlySelf: true});
  }
}
