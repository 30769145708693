<form novalidate [formGroup]="form">
  <mat-card class="app-card inner">
    <mat-card-content fxLayout="column">

      <input type="hidden" formControlName="id"/>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="unClassificationId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of unClassifications; trackBy: trackById" [value]="option.id">{{option.code}} {{option.classification}}, {{option.properShippingName}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>UN number, class</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>ADR number</mat-placeholder>
          <input matInput type="text" formControlName="adrNumber">
        </mat-form-field>

        <mat-form-field fxFlex="150px">
          <mat-select formControlName="packingGroup">
            <mat-option *ngFor="let option of ['', 'I', 'II', 'III']; trackBy: trackById" [value]="option">{{option}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Packing group</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex="150px">
          <mat-placeholder i18n>Dangerous goods</mat-placeholder>
          <input matInput type="text" formControlName="dangerousGoods">
        </mat-form-field>

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.temperatureMin"
          (valueChange)="form.patchValue({ temperatureMin: $event})"
          placeholder="Temperature min"
          suffix="&#8451;"
          [disabled]="editDisabled">
        </integerinput>

        <integerinput
          fxFlex="150px"
          [(value)]="form.value.temperatureMax"
          (valueChange)="form.patchValue({ temperatureMax: $event})"
          placeholder="Temperature max"
          suffix="&#8451;"
          [disabled]="editDisabled">
        </integerinput>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxLayoutAlign="150px">
          <mat-select formControlName="paymentTermId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of paymentTerms trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Payment terms</mat-placeholder>
        </mat-form-field>

        <div fxLayoutAlign="center center">
          <mat-checkbox type="text" formControlName="isProtectFromMoisture" color="primary">
            <mat-placeholder i18n>Protect from moisture</mat-placeholder>
          </mat-checkbox>
        </div>
      </span>

      <span fxLayout="row">
        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Other</mat-placeholder>
          <textarea matInput matTextareaAutosize [matAutosizeMinRows]="3" type="text" formControlName="other"></textarea>
        </mat-form-field>
      </span>
    </mat-card-content>
  </mat-card>
</form>
