import {Audit} from "../audit/audit";

export interface Email extends Audit {
  uuid: string
  referenceNo: string
  sender: string
  to: string[]
  cc: string[]
  bcc: string[]
  subject: string
  text: string
  html: string
  raw: string
  draft: Date
  sent: Date
  received: Date
  read: Date
  starred: Date
  error: Date
  attachments: EmailAttachment[]
}

export class Email implements Email {
  uuid: string;
  referenceNo: string;
  sender: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  text: string;
  html: string;
  raw: string;
  draft: Date;
  sent: Date;
  received: Date;
  read: Date;
  starred: Date;
  error: Date;
  attachments: EmailAttachment[];
}

export interface EmailAttachment extends Audit {
  id: number;
  filename: string;
  fileType: string;
  filePath: string;
  description: string;
}
