<form novalidate [formGroup]="form">
  <mat-card class="app-card">
    <mat-card-header fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Deadline</h2>
        <h2 *ngIf="issue?.subject">
          <span class="text-small color-light" i18n>Last modified by {{issue?.subject?.lastModifiedBy?.name}} {{issue?.subject?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>

    <mat-card-content fxLayout="row" fxLayoutGap="20px">

      <datepicker fxFlex="150px" [(date)]="form.value.date"
                  (dateChange)="form.patchValue({ date: $event })"
                  placeholder="Date" format="DD.MM.YYYY"></datepicker>

      <timepicker fxFlex="180px" [(time)]="form.value.time"
                  (timeChange)="form.patchValue({ time: $event })"
                  placeholder="Time" format="HH:mm"></timepicker>

    </mat-card-content>
  </mat-card>
</form>
