import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginForm} from "./login-form.interface";
import {AuthService} from "../../modules/auth/auth.service";

@Component({
  selector: 'app-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    let rememberme = localStorage.getItem('LHP.rememberme');
    this.form = new FormGroup({
      username: new FormControl('', { validators: Validators.required }),
      password: new FormControl('', { validators: Validators.required }),
      rememberme: new FormControl(rememberme && rememberme === 'true' ? true : '')
    }, { updateOn: 'change' });
  }

  onSubmit({value, valid}: { value: LoginForm, valid: boolean }) {
    this.authService.login(value.username, value.password, value.rememberme);
  }
}
