import {NgModule} from "@angular/core";
import {LoginComponent} from "./login.component";
import {LoginRoutingModule} from "./login-routing.module";
import {SharedModule} from "../shared/shared.module";
import {LoginFormComponent} from "./form/login-form.component";

@NgModule({
  imports: [
    SharedModule,
    LoginRoutingModule
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent
  ]
})

export class LoginModule {
}
