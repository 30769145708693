export const userColumnDefinitions = [
  { colId: '0',
    field: 'id',
    cellRenderer: 'userCellRenderer',
    headerName: 'User ID',
    sortable: true,
    filter: true,
    resizable: true,
    checkboxSelection: true,
    editable: false,
    columnGroupShow: 'everything else',
    hide: false,
    pinned: 'left',
    disabled: true
  },
  { colId: '1', field: 'username', headerName: 'Username', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '2', field: 'userRole', headerName: 'User role', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '3', field: 'firstName', headerName: 'First name', editable: true, sortable: true, filter: true, resizable: true, hide: false },
  { colId: '4', field: 'lastName', headerName: 'Last name', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '5', field: 'name', headerName: 'Name', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '6', field: 'title', headerName: 'Title', editable: true, sortable: true, filter: true, resizable: true, hide: false },
  { colId: '7', field: 'company', headerName: 'Company', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '8', field: 'address1', headerName: 'Address 1', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '9', field: 'address2', headerName: 'Address 2', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '10', field: 'address3', headerName: 'Address 3', editable: true, sortable: true, filter: true, resizable: true, hide: false },
  { colId: '11', field: 'postalCode', headerName: 'Postal code', editable: true, sortable: true, filter: true, resizable: true, hide: false },
  { colId: '12', field: 'city', headerName: 'City', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '13', field: 'state', headerName: 'State', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '14', field: 'countryCodeId', headerName: 'Country', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '15', field: 'phone', headerName: 'Phone', editable: true, sortable: true, filter: true, resizable: true, hide: false },
  { colId: '16', field: 'mobile', headerName: 'Mobile', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '17', field: 'email', headerName: 'Email', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '18', field: 'extId', headerName: 'External ID', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '19', field: 'notes', headerName: 'Notes', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '20', field: 'validDateStart', headerName: 'Valid from', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '21', field: 'validDateEnd', headerName: 'Valid until', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  { colId: '22', field: 'isActive', headerName: 'Active', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
] as const;
