<form novalidate [formGroup]="form">
  <mat-card class="app-card" [ngClass]="{'inner': inner}">
    <mat-card-header fxLayoutAlign="space-between" *ngIf="!displayMode || displayMode == 'transport' && !inner">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Transport management</h2>
        <h2 *ngIf="issue?.transportation">
          <span class="text-small color-light" i18n>Last modified by {{issue?.transportation?.lastModifiedBy?.name}} {{issue?.transportation?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row" fxLayoutGap="20px" *ngIf="!displayMode || displayMode == 'transport'">
      <mat-form-field fxFlex="200px">
        <input type="text"
          placeholder="Transport mode"
          matInput
          [formControl]="transportModeControl"
          autocomplete="off"
          (blur)="delayedBlur(checkTransportModeField.bind(this))"
          [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTransportMode.bind(this)" (optionSelected)="selectTransportMode($event.option.value)">
            <mat-option *ngFor="let option of filteredTransportModes; trackBy: trackById" [value]="option">{{option.code}} {{option.name}}</mat-option>
          </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex="200px">
        <mat-select formControlName="serviceLevelId">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let option of serviceLevels; trackBy: trackById" [value]="option.id">{{option.code}} {{option.name}}</mat-option>
        </mat-select>
        <mat-placeholder i18n>Service level</mat-placeholder>
      </mat-form-field>

    </mat-card-content>
    <mat-card-content fxLayout="column" *ngIf="!displayMode || displayMode == 'transport'">
      <strong *ngIf="!displayMode" i18n>Delivery window</strong>
      <div fxLayout="row" fxLayoutGap="20px">
        <datepicker fxFlex="150px" [(date)]="form.value.actualPickupDate" [disabled]="editDisabled"
                    (dateChange)="form.patchValue({ actualPickupDate: $event })"
                    placeholder="Actual pickup date" format="DD.MM.YYYY"></datepicker>

        <timepicker fxFlex="180px" [(time)]="form.value.actualPickupTime" [disabled]="editDisabled"
                    (timeChange)="form.patchValue({ actualPickupTime: $event })"
                    placeholder="Time" format="HH:mm"></timepicker>

        <mat-form-field fxFlex="100px">
          <mat-select formControlName="actualPickupTimeZoneId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of timeZones; trackBy: trackById" [value]="option.id">{{option.offset}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Time zone</mat-placeholder>
        </mat-form-field>

      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <datepicker fxFlex="150px" [(date)]="form.value.actualDeliveryDate" [disabled]="editDisabled"
                    (dateChange)="form.patchValue({ actualDeliveryDate: $event })"
                    placeholder="Actual delivery date" format="DD.MM.YYYY"></datepicker>

        <timepicker fxFlex="180px" [(time)]="form.value.actualDeliveryTime" [disabled]="editDisabled"
                    (timeChange)="form.patchValue({ actualDeliveryTime: $event })"
                    placeholder="Time" format="HH:mm"></timepicker>

        <mat-form-field fxFlex="100px">
          <mat-select formControlName="actualDeliveryTimeZoneId">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let option of timeZones; trackBy: trackById" [value]="option.id">{{option.offset}} {{option.name}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Time zone</mat-placeholder>
        </mat-form-field>
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="displayMode == 'trackingNo' || displayMode == 'transportation'">
      <span fxLayout="row">

        <mat-form-field fxFlex="100">
          <mat-placeholder i18n>Tracking number</mat-placeholder>
          <input matInput type="text" formControlName="trackingNo">
        </mat-form-field>

      </span>
    </mat-card-content>

    <mat-card-header fxLayoutAlign="space-between" *ngIf="!displayMode || displayMode == 'invoice' && !inner">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>Invoice management</h2>
      </div>
    </mat-card-header>

    <mat-card-content *ngIf="!displayMode || displayMode == 'invoice'">
      <span fxLayout="row">
        <mat-form-field fxFlex="200px">
          <mat-placeholder i18n>Logistic service provider</mat-placeholder>
          <input type="text" matInput [formControl]="logisticsServiceProvider" [matAutocomplete]="logisticServiceProvider" (change)="resetLSP()">
        </mat-form-field>
        <mat-autocomplete #logisticServiceProvider="matAutocomplete" [displayWith]="displaySP">
          <mat-option *ngFor="let option of filteredProviders | async; trackBy: trackById" [value]="option.name" (onSelectionChange)="updateLSP(option.id, $event)">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">
        <decimalinput fxFlex="150px" [(value)]="form.value.logisticsCostWithoutVat" [disabled]="editDisabled"
                      (valueChange)="form.patchValue({ logisticsCostWithoutVat: $event })"
                      placeholder="Logistics cost (VAT 0 %)"></decimalinput>

        <decimalinput fxFlex="150px" [(value)]="form.value.logisticsCostVatRate" [disabled]="editDisabled"
                      (valueChange)="form.patchValue({ logisticsCostVatRate: $event })"
                      placeholder="Logistics cost VAT %"
                      suffix=" %"></decimalinput>

        <mat-form-field fxFlex="80px">
          <mat-select formControlName="logisticsCostWithoutVatCurrencyId">
            <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id">{{option.code}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Currency</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="150px">
          <input matInput type="text" [value]="form.value.logisticsCostVat ? (form.value.logisticsCostVat | decimal:2) : null" disabled/>
          <mat-placeholder class="mat-placeholder" i18n>Logistics cost (VAT {{form.value.logisticsCostVatRate ? (form.value.logisticsCostVatRate | decimal:2) : null}} %)</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="80px">
          <mat-select formControlName="logisticsCostVatCurrencyId">
            <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id" disabled>{{option.code}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Currency</mat-placeholder>
        </mat-form-field>
      </span>

      <span fxLayout="row" fxLayoutGap="20px">

        <mat-form-field fxFlex="150px">
          <input matInput type="text" [value]="form.value.logisticsCostWithVat ? (form.value.logisticsCostWithVat | decimal:2) : null" disabled/>
          <mat-placeholder i18n>Logistics cost incl. VAT</mat-placeholder>
        </mat-form-field>

        <mat-form-field fxFlex="80px">
          <mat-select formControlName="logisticsCostWithVatCurrencyId">
            <mat-option *ngFor="let option of currencies; trackBy: trackById" [value]="option.id" disabled>{{option.code}}</mat-option>
          </mat-select>
          <mat-placeholder i18n>Currency</mat-placeholder>
        </mat-form-field>
      </span>

      <div *ngIf="authGuard.hasAnyAuthority(leafhillRoles)">
        <span fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="150px">
            <mat-placeholder i18n>LSPs invoice number</mat-placeholder>
            <input matInput type="text" formControlName="lspInvoiceNumber">
          </mat-form-field>

          <datepicker fxFlex="140px" [(date)]="form.value.lspInvoiceDate" [disabled]="editDisabled"
                      (dateChange)="form.patchValue({ lspInvoiceDate: $event })"
                      placeholder="LSP invoice date" format="DD.MM.YYYY"></datepicker>

          <datepicker fxFlex="140px" [(date)]="form.value.lspInvoiceDueDate" [disabled]="editDisabled"
                      (dateChange)="form.patchValue({ lspInvoiceDueDate: $event })"
                      placeholder="LSP invoice due date" format="DD.MM.YYYY"></datepicker>
        </span>

        <span fxLayout="row">
          <mat-checkbox type="text" formControlName="isLspInvoiceAudit" color="primary">
            <mat-placeholder i18n>LSPs invoice audit</mat-placeholder>
          </mat-checkbox>
        </span>

        <span fxLayout="row">
          <mat-checkbox type="text" formControlName="isInvoicedToCustomer" color="primary">
            <mat-placeholder i18n>Invoiced to customer</mat-placeholder>
          </mat-checkbox>
        </span>

        <span fxLayout="row">
          <mat-checkbox type="text" formControlName="isReverseCharge" color="primary">
            <mat-placeholder i18n>Reverse charge</mat-placeholder>
          </mat-checkbox>
        </span>

        <span fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex="150px">
            <mat-placeholder i18n>Invoice No to customer</mat-placeholder>
            <input matInput type="text" formControlName="invoiceNumberToCustomer">
          </mat-form-field>

          <datepicker fxFlex="140px" [(date)]="form.value.invoiceDate" [disabled]="editDisabled"
                      (dateChange)="form.patchValue({ invoiceDate: $event })"
                      placeholder="Invoice date" format="DD.MM.YYYY"></datepicker>

          <datepicker fxFlex="140px" [(date)]="form.value.invoiceDueDate" [disabled]="editDisabled"
                      (dateChange)="form.patchValue({ invoiceDueDate: $event })"
                      placeholder="Invoice due date" format="DD.MM.YYYY"></datepicker>
        </span>

        <span fxLayout="row" fxLayoutGap="20px">
          <datepicker fxFlex="150px" [(date)]="form.value.customerPaidDate" [disabled]="editDisabled"
                      (dateChange)="form.patchValue({ customerPaidDate: $event })"
                      placeholder="Customer paid date" format="DD.MM.YYYY"></datepicker>
        </span>
      </div>

    </mat-card-content>
  </mat-card>
</form>
