import {Component, OnInit, Input, Host} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IssueNotesFormComponent} from "../../notes/form/issue-notes-form.component";
import {AuthService} from "../../../../modules/auth/auth.service";
import {AuthGuardService} from "../../../../modules/auth/auth-guard.service";
import {superRoles, leafhillRoles, editorRoles} from "../../../../modules/auth/roles";

@Component({
  selector: 'app-issue-note-form',
  templateUrl: 'issue-note-form.component.html',
  styleUrls: ['issue-note-form.component.scss']
})
export class IssueNoteFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() index: number;
  @Input() inner: boolean;
  superRoles = superRoles;
  leafhillRoles = leafhillRoles;
  editorRoles = editorRoles;

  constructor(@Host() public parent: IssueNotesFormComponent,
              public authGuard: AuthGuardService,
              private authService: AuthService) {
  }

  ngOnInit() {
  }

  isOwner() {
    if (this.authGuard.hasAnyAuthority(editorRoles)) {
      return this.form.value.createdByEmail == this.authService.userEmail();
    }
    return false;

  }
}
