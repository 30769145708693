<app-title></app-title>

<div class="app-toolbar primary-background-color" fxLayoutAlign="space-between center" fxLayoutGap="10px">

  <span fxLayout="row" style="align-items: baseline;">
    <span>
      {{title}}
      <ng-container *ngIf="issue">&nbsp;&gt;&nbsp;{{issue.referenceNo}}</ng-container>
      <ng-container *ngIf="email && !issue">&nbsp;&gt;&nbsp;{{email.subject}}</ng-container>
    </span>
    <app-now fxHide.lt-md fxShow.gt-sm *ngIf="!issue" style="margin-left: 10px" format="dd.MM.yyyy HH:mm:ss" class="text-small"></app-now>

    <span fxHide.lt-md fxShow.gt-sm style="margin-left: 20px" fxLayoutGap="2px" class="text-small" *ngIf="issue">
      <span i18n>Created by</span>
      <span>{{issue.createdBy?.name}}</span>
      <span>{{issue.createdDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
    </span>

    <span fxHide.lt-md fxShow.gt-sm style="margin-left: 20px" fxLayoutGap="2px" class="text-small" *ngIf="email && !issue">
      <span i18n>Created by</span>
      <span>{{email.createdBy?.name}}</span>
      <span>{{email.createdDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
    </span>
  </span>

  <span fxFlex></span>

  <span fxHide.lt-md fxShow.gt-sm *ngIf="authGuard.hasAnyAuthority(portalRoles)">
    <form  id="searchForm" novalidate autocomplete="off" [formGroup]="form">
      <mat-form-field (click)="focusOnSearchInput()" [class.app-input-no-underline]="!form.value.searchTerm">
        <input #searchInput matInput type="text" formControlName="searchTerm" [matAutocomplete]="search">

        <mat-autocomplete #search="matAutocomplete" class="app-toolbar-autocomplete">
          <mat-option *ngFor="let issue of issues; let isLast = last" [routerLink]="['/browse', issue.referenceNo]" class="link">

            <div fxLayout="row" fxLayoutAlign="space-between top">
              <div fxLayout="column">
                <span class="text-small">
                  <span i18n>LH ref: </span>
                  <strong [innerHtml]="issue.referenceNo | highlight:form.value.searchTerm"></strong>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.customerReference">
                  <span i18n>Customer ref: </span>
                  <span [innerHtml]="issue.reference.customerReference | highlight:form.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.projectNumber">
                  <span i18n>Project no: </span>
                  <span [innerHtml]="issue.reference.projectNumber | highlight:form.value.searchTerm"></span>
                </span>
              </div>
              <div fxLayout="column">
                <span class="text-small" *ngIf="authGuard.hasAnyAuthority(superRoles)">{{issue?.owner?.name}}</span>
                <span class="text-small" *ngIf="issue?.reference?.pickupReference">
                  <span i18n>Pickup ref: </span>
                  <span [innerHtml]="issue.reference.pickupReference | highlight:form.value.searchTerm"></span>
                </span>
                <span class="text-small" *ngIf="issue?.reference?.otherReference">
                  <span i18n>Other ref: </span>
                  <span [innerHtml]="issue.reference.otherReference | highlight:form.value.searchTerm"></span>
                </span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>

        <button matSuffix mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </span>

  <app-me></app-me>

</div>
