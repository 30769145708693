import {Injectable} from "@angular/core";
import {Me, MeCompany} from "../toolbar/me/me.interface";
import {SafeUrl} from "@angular/platform-browser";

@Injectable()
export class Data {

  public me: Me;

  public meCompanies: MeCompany[];

  public companyLogo: SafeUrl;
}
