import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      this.authService.logout('Authentication was expired, you have been logged out. You can now login again.');
    }
    return isAuthenticated;
  }

  hasAuthority(authority: string): boolean {
    return this.authService.hasAnyAuthority(authority)
  }

  hasAnyAuthority(authorities: string[]): boolean {
    return this.authService.hasAnyAuthority(...authorities);
  }
}
