
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {IssueService} from "../issue/issue.service";
import {Issue} from "../issue/issue.interface";
import {DialogsService} from "../dialog/dialog.service";
import {AuthGuardService} from "../../modules/auth/auth-guard.service";
import {Email} from "../../modules/email/email";
import {portalRoles, superRoles} from '../../modules/auth/roles';

@Component({
  selector: 'app-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  issues: Array<Issue>;
  @Input() issue: Issue;
  @Input() email: Email;
  title: string;
  @ViewChild('searchInput') searchInput: ElementRef;
  form: FormGroup;
  superRoles = superRoles;
  portalRoles = portalRoles;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private issueService: IssueService,
              private dialogService: DialogsService,
              private viewContainerRef: ViewContainerRef,
              public authGuard: AuthGuardService) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      searchTerm: new FormControl()
    }, { updateOn: 'change' });

    this.route.data.subscribe((data) => {
      this.title = data.title;
    });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.find());
  }

  focusOnSearchInput() {
    if (this.searchInput)
      this.searchInput.nativeElement.focus();
  }

  find() {
    this.issueService.find(this.form.value.searchTerm).subscribe(issues => this.issues = issues);
  }

  openSettingsDialog() {
    this.dialogService
      .settings(this.viewContainerRef)
      .subscribe();
  }
}
