<form novalidate [formGroup]="form">
  <mat-card class="app-card" [ngClass]="{'inner': inner}">
    <mat-card-header fxLayoutAlign="space-between" *ngIf="!inner">
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <h2 i18n>References</h2>
        <h2 *ngIf="issue?.reference">
          <span class="text-small color-light" i18n>Last modified by {{issue?.reference?.lastModifiedBy?.name}} {{issue?.reference?.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </h2>
      </div>
    </mat-card-header>
    <mat-card-content fxLayout="row" fxLayoutGap="20px">
      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Customer reference</mat-placeholder>
        <input matInput type="text" formControlName="customerReference">
      </mat-form-field>

      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Pickup reference</mat-placeholder>
        <input matInput type="text" formControlName="pickupReference">
      </mat-form-field>
    </mat-card-content>

    <mat-card-content fxLayout="row" fxLayoutGap="20px">
      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Project number</mat-placeholder>
        <input matInput type="text" formControlName="projectNumber">
      </mat-form-field>

      <mat-form-field fxFlex="45">
        <mat-placeholder i18n>Other reference</mat-placeholder>
        <input matInput type="text" formControlName="otherReference">
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>
