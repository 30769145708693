
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, OnInit, Output, EventEmitter} from "@angular/core";
import {Issue} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormControl, FormGroup} from "@angular/forms";
import {IssueFormComponent} from "../../issue-form.component";

@Component({
  selector: 'app-issue-subject-form',
  templateUrl: 'issue-subject-form.component.html',
  styleUrls: ['issue-subject-form.component.scss']
})
export class IssueSubjectFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  form: FormGroup;

  constructor(private issueService: IssueService) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    let issue = this.issue;
    this.form = new FormGroup({
      subject: new FormControl(issue.subject && issue.subject.subject || null),
      note: new FormControl(issue.subject && issue.subject.note || null)
    }, { updateOn: 'blur' });

    this.form.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe(any => this.update());
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, {subject: this.form.value}).subscribe((issue: Issue) => this.changeEmitter.emit(issue));
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }
}
