import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-issue-type-dot',
  templateUrl: 'issue-type-dot.component.html',
  styleUrls: ['issue-type-dot.component.scss']
})
export class IssueTypeDotComponent implements OnInit {
  @Input() issueType: string;
  issueColor: string;

  constructor() {
  }

  ngOnInit() {
    let color = '';
    switch (this.issueType) {
      case 'INDICATION_BOOKING':
        color = 'primary';
        break;
      case 'HELP_DESK':
        color = 'accent';
        break;
      case 'EXCEPTION_CLAIM':
        color = 'warn';
        break;
    }
    this.issueColor = color;
  }
}
