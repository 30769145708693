<mat-card class="app-card" fxLayout="column">
  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
      <h2 i18n>Activity</h2>
      <h2>
        <a color="accent" routerLink="/browse" i18n>See all activity</a>
      </h2>
    </div>
    <form *ngIf="isShowFilter" novalidate [formGroup]="form">
      <mat-form-field>
        <mat-select formControlName="timespan">
          <mat-option *ngFor="let option of filterOptions" [value]="option">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-header>

  <mat-card-content fxLayout="column" *ngFor="let activity of activities;">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span fxLayoutGap="5px" class="text-small app-title">
        <span>{{activity.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        <span>{{activity.lastModifiedBy?.name}}</span>
      </span>
      <span class="text-small color-light" *ngIf="authGuard.hasAnyAuthority(superRoles)">{{activity.companyName}}</span>
    </div>
    <div fxLayout="row" class="app-subtitle text-small" fxLayoutGap="2px">
      <strong>{{activity.activity}}</strong>
      <span *ngIf="activity.createdDate == activity.lastModifiedDate" i18n>created</span>
      <span *ngIf="activity.createdDate != activity.lastModifiedDate" i18n>updated</span>
      <span fxFlex></span>
      <a [routerLink]="['/browse', activity.referenceNo]">{{activity.referenceNo}}</a>
    </div>
  </mat-card-content>
</mat-card>
