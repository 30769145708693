<mat-card class="app-card" fxLayout="column">
  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
      <h2 i18n>Newest issues</h2>
      <h2>
        <a color="accent" routerLink="/browse" i18n>See all issues</a>
      </h2>
    </div>
  </mat-card-header>

  <mat-card-content fxLayout="column" *ngFor="let issue of issues;">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span fxLayoutGap="5px" class="text-small app-title">
        <span>{{issue.createdDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        <span>{{issue.createdBy?.name}}</span>
      </span>
      <span class="text-small color-light" *ngIf="authGuard.hasAnyAuthority(superRoles)">{{issue.owner.name}}</span>
    </div>
    <div fxLayout="row" class="app-subtitle text-small" fxLayoutGap="2px">
      <span i18n>Created</span>
      <strong>{{enumService.getIssueType(issue.type)?.name}}</strong>
      <span fxFlex></span>
      <a [routerLink]="['/browse', issue.referenceNo]">{{issue.referenceNo}}</a>
    </div>
    <div fxLayout="row" class="app-subtitle">
      <span class="text-small">{{issue.status.activeStatusName}}</span>
    </div>
  </mat-card-content>
</mat-card>

