import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {Issue, IssueNote} from "../../issue.interface";
import {IssueService} from "../../issue.service";
import {FormGroup, FormArray, FormControl} from "@angular/forms";
import * as _ from "lodash";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {RemoveDialog} from "../../../remove/remove-dialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {IssueFormComponent} from "../../issue-form.component";
import {AuthService} from "../../../../modules/auth/auth.service";
import {AuthGuardService} from "../../../../modules/auth/auth-guard.service";
import {leafhillRoles} from "../../../../modules/auth/roles";

@Component({
  selector: 'app-issue-notes-form',
  templateUrl: 'issue-notes-form.component.html',
  styleUrls: ['issue-notes-form.component.scss']
})
export class IssueNotesFormComponent extends IssueFormComponent implements OnInit {
  @Output() changeEmitter: EventEmitter<Issue> = new EventEmitter<Issue>();
  @Input() inner: boolean;
  form: FormGroup;
  leafhillRoles = leafhillRoles;

  removeDialogRef: MatDialogRef<RemoveDialog>;

  constructor(private sanitizer: DomSanitizer,
              private issueService: IssueService,
              private authService: AuthService,
              public authGuard: AuthGuardService,
              private MatDialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      notes: new FormArray(this.initSubForms(this.issue))
    }, { updateOn: 'blur' });
  }

  initSubForms(issue) {
    let subForms = [];
    if (issue.notes && issue.notes.length > 0) {
      issue.notes.forEach(note => subForms.push(this.initSubForm(note)));
    }
    return subForms;
  }

  initSubForm(note: IssueNote) {
    let subForm = new FormGroup({
      isOpen: new FormControl(note && note.id ? false : true),
      id: new FormControl(note && note.id || null),
      note: new FormControl(note && note.note || null),
      isImportant: new FormControl(note && note.isImportant || null),
      createdDate: new FormControl(note && note.createdDate || null),
      createdByEmail: new FormControl(note && note.createdBy && note.createdBy.email || null),
      createdByName: new FormControl(note && note.createdBy && note.createdBy.name || null),
      lastModifiedDate: new FormControl(note && note.lastModifiedDate || null),
      lastModifiedByEmail: new FormControl(note && note.lastModifiedBy && note.lastModifiedBy.email || null),
      lastModifiedByName: new FormControl(note && note.lastModifiedBy && note.lastModifiedBy.name || null)
    }, { updateOn: 'blur' });
    return subForm;
  }

  addSubForm() {
    //noinspection TypeScriptUnresolvedVariable
    const control = <FormArray>this.form.controls['notes'];
    control.push(this.initSubForm(<IssueNote>{createdBy: {email: this.authService.userEmail()}}));
  }

  toggleIsOpen(formGroup: FormGroup) {
    let isOpen = formGroup.value.isOpen;
    // we are closing, thus save changes
    if (isOpen) {
      this.update();
    }
    formGroup.patchValue({isOpen: !isOpen}, {emitEvent: false});
  }

  removeSubForm(index: number) {
    this.removeDialogRef = this.MatDialog.open(RemoveDialog);

    this.removeDialogRef.afterClosed().subscribe(isRemove => {
      if (isRemove) {
        const control = <FormArray>this.form.controls['notes'];
        control.removeAt(index);
      }
      this.removeDialogRef = null;
      this.update();
    });
  }

  update() {
    this.issueService.patch(this.issue.referenceNo, this.form.value).subscribe((issue: Issue) => {
      const control = <FormArray>this.form.controls['notes'];
      control.controls.forEach((subForm: FormGroup, index: number) => {
        let form = this.initSubForm(issue.notes[index]);
        let isOpen = subForm.value.isOpen;
        subForm.patchValue(form.value, {emitEvent: false});
        subForm.patchValue({isOpen: isOpen}, {emitEvent: false})
      });
      this.changeEmitter.emit(issue);
    });
  }

  findPropertyById(collection: any, id: number, property: string) {
    let object = _.find(collection, {id: Number(id)});
    return object && object.hasOwnProperty(property) ? object[property] : null;
  }

  refreshStatus(issue: Issue) {
    this.issue = Object.assign(this.issue, issue);
    this.initForm();
  }
}
