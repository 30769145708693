<div fxLayout="column">
  <div fxLayout="row" style="margin: 0 20px;" *ngIf="!inner">
    <h2 i18n>Messages</h2>
  </div>

  <div *ngIf="inner && authGuard.hasAnyAuthority(leafhillRoles)" fxLayout="row" fxLayoutAlign="end" style="margin-top: -34px;">
    <button mat-button color="accent" (click)="addSubForm()" i18n>Write message</button>
  </div>

  <form *ngIf="issue" novalidate [formGroup]="form">
    <div formArrayName="notes">
      <ng-container *ngFor="let subForm of form.controls.notes.controls; let i = index">
        <mat-card fxLayout="row" class="app-card inner">
          <span fxFlex>
            <div class="text-small" [formGroupName]="i">
              <app-issue-note-form [form]="subForm" [index]="i" [inner]="inner" [editDisabled]="authGuard.hasAnyAuthority(leafhillRoles)"></app-issue-note-form>
            </div>
          </span>
        </mat-card>
      </ng-container>
    </div>
  </form>

  <div fxLayout="row" style="margin: 0 20px;" *ngIf="!inner && authGuard.hasAnyAuthority(leafhillRoles)">
    <button mat-button color="accent" (click)="addSubForm()" i18n>Write message</button>
  </div>
</div>
