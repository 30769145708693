
import {of as observableOf, Observable} from 'rxjs';
import {tap} from 'rxjs/operators'
import {Injectable} from "@angular/core";
import {
  BookingStatus,
  DeliveryTerm,
  Company,
  CountryCode,
  TransportMode,
  ServiceLevel,
  Currency,
  TimeZone,
  CustomsNumber,
  IntrastatSupplementaryUnit,
  Measure,
  IntrastatTransactionNature,
  IntrastatTransportMean,
  UnClassification,
  PaymentTerm,
  PackageType,
  Equipment,
  HelpDeskStatus,
  ExceptionClaim,
  PostalCode,
  Port,
  Airport
} from "../issue/issue.interface";
import {SnackBarService} from "../snackbar/snackbar.service";
import {NotificationService} from "../notification/notification.service";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable()
export class AjaxService {

  private requestCache: { [key: string]: { value: any, observable: Observable<any> } } = {};

  private defaults = {
    countryCode: 'FI',
    currencyCode: 'EUR',
    timeZone: 'UTC+02:00',
    lengthUnit: 'cm',
    massUnit: 'kg',
    volumeUnit: 'm3',
    vatRate: 24.00
  };

  constructor(private http: HttpClient,
              public snackBarService: SnackBarService,
              private notificationService: NotificationService) {
    this.notificationService.getMessageReceived().subscribe((msg) => {
      if (msg.notificationType === 'BATCH_LOADED') {
        // Delete all keys from cache that start with notificationData
        // (e.g. ajax_measures => ajax_measures_length&ajax_measures_weight)
        const keys = Object.keys(this.requestCache);
        let matches = keys.filter((key) => {
          return key.startsWith(msg.notificationData);
        });
        for (let deleteKey in matches) {
          delete this.requestCache[matches[deleteKey]];
        }
      }
    });
  }

  defaultVatRate(): number {
    return this.defaults.vatRate;
  }

  defaultCurrencyId(currencies): number {
    let defaultCC = currencies && currencies.find((cc) => {
        return cc.code == this.defaults.currencyCode;
      });
    return defaultCC && defaultCC.id || currencies && currencies[0].id;
  }

  defaultCountryCodeId(countryCodes): number {
    let defaultCC = countryCodes && countryCodes.find((cc) => {
        return cc.code == this.defaults.countryCode;
      });
    return defaultCC && defaultCC.id || countryCodes && countryCodes[0].id;
  }

  defaultTimeZoneId(timeZones): number {
    let defaultTZ = timeZones && timeZones.find((tz) => {
        return tz.offset == this.defaults.timeZone;
      });
    return defaultTZ && defaultTZ.id || timeZones && timeZones[0].id;
  }

  defaultLengthUnitId(units): number {
    let defaultUnit = units && units.find((un) => {
        return un.unit == this.defaults.lengthUnit;
      });
    return defaultUnit && defaultUnit.id || units && units[0].id;
  }

  defaultMassUnitId(units): number {
    let defaultUnit = units && units.find((un) => {
        return un.unit == this.defaults.massUnit;
      });
    return defaultUnit && defaultUnit.id || units && units[0].id;
  }

  defaultVolumeUnitId(units): number {
    let defaultUnit = units && units.find((un) => {
        return un.unit == this.defaults.volumeUnit;
      });
    return defaultUnit && defaultUnit.id || units && units[0].id;
  }

  cachedRequest<T>(key: string, path: string, options: any = undefined): Observable<Array<T>> {
    if (this.requestCache[key] === undefined) {
      this.requestCache[key] = {observable: undefined, value: undefined};
    }

    if (this.requestCache[key].observable === undefined) {
      this.requestCache[key].observable = this.http.get(path, options)
        .pipe(
          tap(data => this.requestCache[key].value = data, error => this.snackBarService.error(error))
        );
    }

    if (this.requestCache[key].value === undefined) {
      return this.requestCache[key].observable;
    }

    return observableOf(this.requestCache[key].value);
  }

  findBookingStatuses(): Observable<Array<BookingStatus>> {
    return this.cachedRequest<BookingStatus>("ajax_bookingstatuses", '/api/ajax/booking-statuses');
  }

  findHelpDeskStatuses(): Observable<Array<HelpDeskStatus>> {
    return this.cachedRequest<HelpDeskStatus>('ajax_helpdeskstatuses', '/api/ajax/help-desk-statuses');
  }

  findExceptionClaims(): Observable<Array<ExceptionClaim>> {
    // Sort by code
    return this.cachedRequest<ExceptionClaim>('ajax_exceptionclaims', '/api/ajax/exception-claims').pipe(
      tap(results => results.sort((a, b) => (a.code > b.code) ? 1 : -1))
    );
  }

  findDeliveryTerms(): Observable<Array<DeliveryTerm>> {
    return this.cachedRequest<DeliveryTerm>('ajax_deliveryterms', '/api/ajax/delivery-terms');
  }

  findSenders(): Observable<any> {
    return this.http.get('/api/ajax/senders');
  }

  findPickups(referenceNo: string): Observable<any> {
    const params = new HttpParams()
      .set('referenceNo', referenceNo);

    return this.http.get('/api/ajax/pickups', {params});
  }

  findReceivers(): Observable<any> {
    return this.http.get('/api/ajax/receivers');
  }

  findDeliveries(referenceNo: string): Observable<any> {
    const params = new HttpParams()
      .set('referenceNo', referenceNo);
    return this.http.get('/api/ajax/deliveries', {params});
  }

  findPorts(countryCodeId: number): Observable<Array<Port>> {
    const params = new HttpParams()
      .set('countryCodeId', countryCodeId ? countryCodeId.toString() : '');
    const key = "ajax_ports" + (countryCodeId ? countryCodeId.toString() : '');
    return this.cachedRequest<Port>(key, '/api/ajax/ports');
  }

  findAirports(countryCodeId: number): Observable<Array<Airport>> {
    const params = new HttpParams()
      .set('countryCodeId', countryCodeId ? countryCodeId.toString() : '');
    const key = "ajax_airports" + (countryCodeId ? countryCodeId.toString() : '');
    return this.cachedRequest<Airport>(key, '/api/ajax/airports');
  }

  findCountries(): Observable<Array<CountryCode>> {
    // Sort by code
    return this.cachedRequest<CountryCode>('countries', '/api/ajax/countries').pipe(
      tap(results => results.sort((a, b) => (a.code > b.code) ? 1 : -1))
    );
  }

  findTransportModes(): Observable<Array<TransportMode>> {
    return this.cachedRequest<TransportMode>('ajax_transportmodes', '/api/ajax/transport-modes');
  }

  findServiceLevels(): Observable<Array<ServiceLevel>> {
    return this.cachedRequest<ServiceLevel>('ajax_servicelevels', '/api/ajax/service-levels');
  }

  findTimeZones(): Observable<Array<TimeZone>> {
    return this.cachedRequest<TimeZone>('ajax_timezones', '/api/ajax/time-zones');
  }

  findCurrencies(): Observable<Array<Currency>> {
    return this.cachedRequest<Currency>('ajax_currencies', '/api/ajax/currencies');
  }

  findLogisticsServiceProviders(): Observable<Array<Company>> {
    return this.cachedRequest<Company>('logisticsServiceProviders', '/api/ajax/logistics-service-providers');
  }

  findCustomsNumbers(searchTerm?: string): Observable<any> {
    const params = new HttpParams();
    if(searchTerm) {
      params.set('searchTerm', searchTerm);
    }

    return this.http.get('/api/ajax/customs-numbers', {params});
  }

  findMeasures(category: string): Observable<Array<Measure>> {
    const params = new HttpParams()
      .set('category', category);

    return this.cachedRequest<Measure>('ajax_measures_', '/api/ajax/measures', {params});
  }

  findIntrastatTransactionNatures(): Observable<Array<IntrastatTransactionNature>> {
    return this.cachedRequest<IntrastatTransactionNature>('ajax_intrastattransactionnatures', '/api/ajax/intrastat-transaction-natures');
  }

  findIntrastatTransportMeans(): Observable<Array<IntrastatTransportMean>> {
    return this.cachedRequest<IntrastatTransportMean>('ajax_intrastattransportmeans', '/api/ajax/intrastat-transport-means');
  }

  findIntrastatSupplementaryUnits(): Observable<Array<IntrastatSupplementaryUnit>> {
    return this.cachedRequest<IntrastatSupplementaryUnit>('ajax_intrastatsupplementaryunits', '/api/ajax/intrastat-supplementary-units');
  }

  findUnClassifications(): Observable<Array<UnClassification>> {
    return this.cachedRequest<UnClassification>('ajax_unclassifications', '/api/ajax/un-classifications');
  }

  findPaymentTerms(): Observable<Array<PaymentTerm>> {
    return this.cachedRequest<PaymentTerm>('ajax_paymentterms', '/api/ajax/payment-terms');
  }

  findPackageTypes(): Observable<Array<PackageType>> {
    return this.cachedRequest<PackageType>('ajax_packagetypes', '/api/ajax/package-types');
  }

  findEquipments(): Observable<Array<Equipment>> {
    return this.cachedRequest<Equipment>('ajax_equipment', '/api/ajax/equipments');
  }

  findPostalCodes(countryCodeId?: number, postalCode?: string, state?: string, city?: string): Observable<any> {
    const params = new HttpParams()
      .set('countryCodeId', countryCodeId ? countryCodeId.toString() : '')
      .set('postalCode', postalCode)
      .set('state', state)
      .set('city', city);
    return this.http.get('/api/ajax/postal-codes', {params});
  }

  findEmailAddresses(): Observable<any> {
    return this.http.get('/api/ajax/email-addresses');
  }

  findCompanies(): Observable<Array<Company>> {
    // NOTE: endpoint returns only: id, code and name of the company.
    // TODO: another interface?
    return this.cachedRequest<Company>('ajax_companies', '/api/companies');
  }
}
