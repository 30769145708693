import {Component, OnDestroy, OnInit} from "@angular/core";
import {MeService} from "./me.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {AuthService} from "../../../modules/auth/auth.service";
import {Data} from "../../data/data";
import {Me, MeCompany} from "./me.interface";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-me',
  templateUrl: 'me.component.html',
  styleUrls: ['me.component.scss']
})
export class MeComponent implements OnInit, OnDestroy {

  private onAuthUpdated: Subscription;

  constructor(private router: Router,
              private authService: AuthService,
              public data: Data,
              private meService: MeService,
              private sanitizer: DomSanitizer) {
    this.init();
  }

  ngOnInit() {
    this.onAuthUpdated = this.authService.onAuthUpdated.subscribe(() => this.authService.isAuthenticated() && this.init());
  }

  ngOnDestroy() {
    if (this.onAuthUpdated)
      this.onAuthUpdated.unsubscribe();
  }

  init() {
    this.meService.getMe().subscribe((me: Me) => {
      this.data.me = me;
      this.data.companyLogo = this.sanitizer.bypassSecurityTrustUrl(`${me.companyLogoType},${me.companyLogoData}`);
    });

    this.meService.getMeCompanies().subscribe((meCompanies: MeCompany[]) => {
      this.data.meCompanies = meCompanies
    });
  }

  logout(){
    this.authService.logout();
  }
}
