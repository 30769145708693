import {Component, OnInit} from "@angular/core";
import {IssueFormComponent} from "./../issue-form.component";

@Component({
  selector: 'app-issue-audit',
  templateUrl: 'issue-audit.component.html',
  styleUrls: ['issue-audit.component.scss']
})
export class IssueAuditComponent extends IssueFormComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
  }
}
