import {Component, OnInit} from "@angular/core";
import {IssueFormComponent} from "../issue-form.component";

@Component({
  selector: 'app-issue-reference',
  templateUrl: 'issue-reference.component.html',
  styleUrls: ['issue-reference.component.scss']
})
export class IssueReferenceComponent extends IssueFormComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
