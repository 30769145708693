<mat-card>
  <mat-card-content>
    <h1 matDialogTitle i18n>Remove entry?</h1>
    <span mat-dialog-content class="text-small" i18n>This action cannot be undone.</span>

    <mat-dialog-actions>
      <button mat-button (click)="dialogRef.close(true)">Yes</button>
      <button mat-button matDialogClose>No</button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>

