import {Injectable} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Injectable()
export class FormService {

  constructor(private fb: FormBuilder) {
  }

  initIdFormControl(value = '', isEditable): FormControl {
    return this.initStringFormControl(value, [], isEditable);
  }

  initBooleanFormControl(value = false, validators, isEditable): FormControl {
    return this.fb.control({value: value, disabled: !isEditable}, validators);
  }

  initStringFormControl(value = '', validators, isEditable): FormControl {
    return this.fb.control({value: value, disabled: !isEditable}, validators);
  }

  initStringFormArray(values = [], validators, isEditable?): FormArray {
    let array = [];
    if (values) {
      values.forEach(value => {
        array.push(this.initStringFormControl(value, validators, isEditable));
      });
    }
    return this.fb.array(array);
  }

  initFileFormArray(values = [], validators, isEditable?): FormArray {
    let array = [];
    if (values) {
      values.forEach(value => {
        array.push(this.initFileFormGroup(value, validators, isEditable));
      });
    }
    return this.fb.array(array);
  }

  initFileFormGroup(value = {} as any, validators, isEditable?): FormGroup {
    return this.fb.group({
      id: this.initIdFormControl(value && value.id, isEditable),
      filename: this.initStringFormControl(value && value.filename, validators, isEditable),
      fileType: this.initStringFormControl(value && value.fileType, validators, isEditable),
      filePath: this.initStringFormControl(value && value.filePath, validators, isEditable),
      description: this.initStringFormControl(value && value.description, validators, isEditable)
    });
  }

  swap(arr: FormArray, a: number, b: number) {
    if (a < 0 || a >= arr.controls.length || b < 0 || b >= arr.controls.length) {
      return;
    }
    const temp = arr.controls[a];
    arr.controls[a] = arr.controls[b];
    arr.controls[b] = temp;
  }
}
