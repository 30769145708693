import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NowComponent} from "./toolbar/now/now.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from '@angular/flex-layout';
import {TitleComponent} from "./toolbar/title/title.component";
import {ToolbarComponent} from "./toolbar/toolbar.component";
import {ApiModule} from "./api/api.module";
import {MeComponent} from "./toolbar/me/me.component";
import {MeService} from "./toolbar/me/me.service";
import {SidenavComponent} from "./sidenav/sidenav.component";
import {NewestActivityComponent} from "./newest/activity/newest-activity.component";
import {IssueService} from "./issue/issue.service";
import {UserService} from "./users/user.service";
import {RouterModule} from "@angular/router";
import {IssueFormComponent} from "./issue/issue-form.component";
import {IssueStatusFormComponent} from "./issue/status/form/issue-status-form.component";
import {IssueAuditComponent} from "./issue/audit/issue-audit.component";
import {IssueTermFormComponent} from "./issue/term/form/issue-term-form.component";
import {IssueNoteFormComponent} from "./issue/note/form/issue-note-form.component";
import {IssueReferenceFormComponent} from "./issue/reference/form/issue-reference-form.component";
import {IssueReferenceComponent} from "./issue/reference/issue-reference.component";
import {IssueSenderFormComponent} from "./issue/sender/form/issue-sender-form.component";
import {IssueTransportationFormComponent} from "./issue/transportation/form/issue-transportation-form.component";
import {IssueReceiverFormComponent} from "./issue/receiver/form/issue-receiver-form.component";
import {IssuePickupsFormComponent} from "./issue/pickups/form/issue-pickups-form.component";
import {IssuePickupFormComponent} from "./issue/pickup/form/issue-pickup-form.component";
import {IssueDeliveriesFormComponent} from "./issue/deliveries/form/issue-deliveries-form.component";
import {IssueDeliveryFormComponent} from "./issue/delivery/form/issue-delivery-form.component";
import {IssueProductsFormComponent} from "./issue/products/form/issue-products-form.component";
import {IssueProductFormComponent} from "./issue/product/form/issue-product-form.component";
import {IssueIntrastatFormComponent} from "./issue/intrastat/form/issue-intrastat-form.component";
import {RemoveDialog} from "./remove/remove-dialog.component";
import {IssueSpecialRequirementFormComponent} from "./issue/special-requirement/form/issue-special-requirement-form.component";
import {IssuePackagesFormComponent} from "./issue/packages/form/issue-packages-form.component";
import {IssuePackageFormComponent} from "./issue/package/form/issue-package-form.component";
import {IssueUploadsFormComponent} from "./issue/uploads/form/issue-uploads-form.component";
import {IssueUploadFormComponent} from "./issue/upload/form/issue-upload-form.component";
import {IssueNotesFormComponent} from "./issue/notes/form/issue-notes-form.component";
import {IssueEmailsComponent} from "./issue/emails/issue-emails.component";
import {HighlightPipe} from "./highlight/highlight.pipe";
import {IssueStatusDialog} from "./issue/status/issue-status-dialog.component";
import {IssueDialogsService} from "./issue/issue-dialogs.service";
import {IssueTransportationDialog} from "./issue/transportation/issue-transportation-dialog.component";
import {IssueCostsFormComponent} from "./issue/costs/form/issue-costs-form.component";
import {IssueCostFormComponent} from "./issue/cost/form/issue-cost-form.component";
import {IssueSubjectFormComponent} from "./issue/subject/form/issue-subject-form.component";
import {IssueDeadlineFormComponent} from "./issue/deadline/form/issue-deadline-form.component";
import {IssueExceptionFormComponent} from "./issue/exception/form/issue-exception-form.component";
import {IssueClaimFormComponent} from "./issue/claim/form/issue-claim-form.component";
import {IssueLinksFormComponent} from "./issue/links/form/issue-links-form.component";
import {IssueLinkFormComponent} from "./issue/link/form/issue-link-form.component";
import {IssueReportFormComponent} from "./issue/report/form/issue-report-form.component";
import {IssueTypeComponent} from "./issue/type/issue-type.component";
import {DialogsService} from "./dialog/dialog.service";
import {SettingsDialog} from "./settings/settings-dialog.component";
import {DropzoneComponent} from "./dropzone/dropzone.component";
import {SettingsComponent} from "./settings/settings.component";
import {TextMaskModule} from "angular2-text-mask";
import {Formatter} from "./formatter/formatter";
import {DatepickerComponent} from "./datepicker/datepicker";
import {DecimalInputComponent} from "./decimalinput/decimalinput";
import {IntegerInputComponent} from "./integerinput/integerinput";
import {Decimal} from "./decimal/decimal";
import {IssueReportParametersDialog} from "./issue/report/issue-report-parameters-dialog";
import {LocalDateAdapter} from "./date/local-date-adapter";
import {GPlaces} from "./gplaces/gplaces";
import {IssueClonedDialog} from "./issue/issue-cloned-dialog.component";
import {AddressService} from "./address/address.service";
import {HttpClientModule} from "@angular/common/http";
import {CdkTableModule} from "@angular/cdk/table";
import {TimepickerModule} from "./timepicker/timepicker.module";
import {SearchComponent} from "./search/search.component";
import {SpeechRecognitionService} from "./speech/speech-recognition.service";
import {NewestIssuesComponent} from "./newest/issues/newest-issues.component";
import {NewestService} from "./newest/newest.service";
import {RowDataService} from "./grid/row-data/row-data.service";
import {ColumnDefinitionsService} from "./grid/column-definitions/column-definitions.service";
import {EnumService} from "./enum/enum.service";
import {NewestIssueNotesComponent} from "./newest/notes/newest-issue-notes.component";
import {IssueLinksComponent} from "./issue/links/issue-links.component";
import {TimePipe} from "./time/time.pipe";
import {IssueTypeDotComponent} from "./issue/type/dot/issue-type-dot.component";
import {IssueDetailFormComponent} from "./issue/detail/form/issue-detail-form.component";
import {S3UploadComponent} from './s3-upload/s3-upload.component';
import {GridModalComponent} from './grid/grid-modal/grid-modal.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {EmailModule} from "../modules/email/email.module";
import {UserFormComponent} from './users/forms/user-form/user-form.component'
import { CompanyRoleSelectionComponent } from "./users/forms/user-form/company-role-selection/company-role-selection.component";

const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
  }
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdkTableModule,

    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatExpansionModule,

    MatNativeDateModule,

    EmailModule,
    TimepickerModule,
    ApiModule,
    TextMaskModule,
    FlexLayoutModule,
    ClickOutsideModule
  ],
  declarations: [
    TitleComponent,
    NowComponent,
    MeComponent,
    ToolbarComponent,
    SidenavComponent,
    NewestActivityComponent,
    IssueFormComponent,
    IssueDetailFormComponent,
    IssueStatusFormComponent,
    IssueExceptionFormComponent,
    IssueSubjectFormComponent,
    IssueDeadlineFormComponent,
    IssueClaimFormComponent,
    IssueTermFormComponent,
    IssueReferenceFormComponent,
    IssueSenderFormComponent,
    IssuePickupsFormComponent,
    IssuePickupFormComponent,
    IssueReceiverFormComponent,
    IssueDeliveriesFormComponent,
    IssueDeliveryFormComponent,
    IssueProductsFormComponent,
    IssueProductFormComponent,
    IssuePackagesFormComponent,
    IssuePackageFormComponent,
    IssueIntrastatFormComponent,
    IssueSpecialRequirementFormComponent,
    IssueTransportationFormComponent,
    IssueAuditComponent,
    IssueReferenceComponent,
    IssueTypeComponent,
    IssueTypeDotComponent,
    IssueUploadsFormComponent,
    IssueUploadFormComponent,
    IssueCostsFormComponent,
    IssueCostFormComponent,
    IssueEmailsComponent,
    IssueNotesFormComponent,
    IssueNoteFormComponent,
    IssueLinksComponent,
    IssueLinksFormComponent,
    IssueLinkFormComponent,
    IssueReportFormComponent,
    IssueReportParametersDialog,
    RemoveDialog,
    IssueClonedDialog,
    IssueStatusDialog,
    IssueTransportationDialog,
    SettingsComponent,
    SettingsDialog,
    HighlightPipe,
    TimePipe,
    DropzoneComponent,
    DatepickerComponent,
    DecimalInputComponent,
    IntegerInputComponent,
    Decimal,
    SearchComponent,
    NewestIssuesComponent,
    NewestIssueNotesComponent,
    S3UploadComponent,
    GridModalComponent,
    UserFormComponent,
    CompanyRoleSelectionComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdkTableModule,

    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatExpansionModule,

    TimepickerModule,
    TextMaskModule,
    FlexLayoutModule,

    ToolbarComponent,
    SidenavComponent,
    NewestActivityComponent,
    IssueDetailFormComponent,
    IssueStatusFormComponent,
    IssueExceptionFormComponent,
    IssueSubjectFormComponent,
    IssueDeadlineFormComponent,
    IssueClaimFormComponent,
    IssueTermFormComponent,
    IssueReferenceFormComponent,
    IssueSenderFormComponent,
    IssuePickupsFormComponent,
    IssuePickupFormComponent,
    IssueReceiverFormComponent,
    IssueDeliveriesFormComponent,
    IssueDeliveryFormComponent,
    IssueProductsFormComponent,
    IssueProductFormComponent,
    IssuePackagesFormComponent,
    IssuePackageFormComponent,
    IssueIntrastatFormComponent,
    IssueSpecialRequirementFormComponent,
    IssueTransportationFormComponent,
    IssueAuditComponent,
    IssueReferenceComponent,
    IssueTypeComponent,
    IssueTypeDotComponent,
    IssueUploadsFormComponent,
    IssueUploadFormComponent,
    IssueCostsFormComponent,
    IssueCostFormComponent,
    IssueEmailsComponent,
    IssueNotesFormComponent,
    IssueNoteFormComponent,
    IssueLinksComponent,
    IssueLinksFormComponent,
    IssueLinkFormComponent,
    IssueReportFormComponent,
    HighlightPipe,
    TimePipe,
    Decimal,
    SettingsComponent,
    DropzoneComponent,
    DatepickerComponent,
    SearchComponent,
    NewestIssuesComponent,
    NewestIssueNotesComponent,
    S3UploadComponent,
    GridModalComponent,
    UserFormComponent
  ],
  providers: [
    MeService,
    NewestService,
    RowDataService,
    ColumnDefinitionsService,
    IssueService,
    IssueDialogsService,
    DialogsService,
    Formatter,
    GPlaces,
    AddressService,
    SpeechRecognitionService,
    EnumService,
    UserService,
    {provide: DateAdapter, useClass: LocalDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class SharedModule {
}
