export const issueColumnDefinitions = [
  { colId: '0',
    field: 'referenceNo',
    cellRenderer: 'issueCellRenderer',
    headerName: 'LH Reference',
    sortable: true,
    filter: true,
    resizable: true,
    checkboxSelection: true,
    editable: false,
    columnGroupShow: 'everything else',
    hide: false,
    pinned: 'left',
    disabled: true
  },
  { colId: '1', field: 'customer', headerName: 'Customer', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '2', field: 'type', headerName: 'Type', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '3', field: 'status.status', headerName: 'Status', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
  {
    colId: '4',
    headerName: 'Created',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '5-1', field: 'created.createdBy', headerName: 'Created by', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '5-2', field: 'created.createdDate', headerName: 'Date created', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '5',
    headerName: 'References',
    marryChildren: true,
    resizable: true,
    openByDefault: true,
    children: [
      { colId: '5-1', field: 'reference.customerReference', headerName: 'Customer reference', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '5-2', field: 'reference.pickupReference', headerName: 'Pickup / loading reference', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '5-3', field: 'reference.projectNumber', headerName: 'Project reference', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '5-4', field: 'reference?.container', headerName: 'Truck / container number', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '5-5', field: 'reference?.seal', headerName: 'Seal', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '5-6', field: 'reference?.tracking', headerName: 'Tracking number / link', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '5-7', field: 'reference.otherReference', headerName: 'Other reference', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '6',
    headerName: 'Description',
    marryChildren: true,
    resizable: true,
    openByDefault: true,
    children: [
      { colId: '6-1', field: 'subject.subject', headerName: 'Subject', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '6-2', field: 'subject.note', headerName: 'Description', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '6-3', field: 'status.deadline_date', headerName: 'Date', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '6-4', field: 'status.deadline_time', headerName: 'Time', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '7',
    headerName: 'Claim',
    marryChildren: true,
    resizable: true,
    openByDefault: true,
    children: [
      { colId: '7-1', field: 'claim.relatedParty', headerName: 'Related Party', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '7-2', field: 'claim.owner', headerName: 'Owner', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '7-3', field: 'claim.amount', headerName: 'Amount', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '7-4', field: 'claim.amountCurrencyId', headerName: 'Currency', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  // TODO: add access to list items through button in cell, which opens a modal
  // { colId: '8', field: 'linkedIssues', headerName: 'Linked Issues', sortable: false, filter: false, resizable: true, hide: false },
  // { colId: '9', field: '', headerName: 'Uploaded Files', sortable: false, filter: false, resizable: true, hide: false },
  // { colId: '10', field: '', headerName: 'Emails', sortable: false, filter: false, resizable: true, hide: false },

  // OTHER THAN SUPPORT-RELATED BELOW:
  {
    colId: '11',
    headerName: 'Incoterms',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '11-1',
        field: 'term.code',
        headerName: 'Code',
        sortable: true,
        filter: true,
        resizable: true,
        hide: false,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [1, 2, 3, 4]
        },
        columnGroupShow: 'everything else' },
      { colId: '11-2', field: 'term.name', headerName: 'Incoterm', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '11-3', field: 'term.location', headerName: 'Location', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '11-4', field: 'term.notes', headerName: 'Notes', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '12',
    headerName: 'Sender',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '12-1', field: 'sender.companyName', headerName: 'Company', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '12-2', field: 'sender.postalCode', headerName: 'Postal code', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '12-3', field: 'sender.city', headerName: 'City', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '12-4', field: 'sender.countryCode', headerName: 'Country code', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '12-5', field: 'sender.contact', headerName: 'Contact person', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '12-6', field: 'sender.contact_phone', headerName: 'Contact phone number', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '12-7', field: 'sender.contact_email', headerName: 'Contact email', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '12-8', field: 'sender.notes', headerName: 'Notes', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '13',
    headerName: 'Receiver',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '13-1', field: 'receiver_company', headerName: 'Company', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '13-2', field: 'receiver_postal-code', headerName: 'Postal code', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '13-3', field: 'receiver_city', headerName: 'City', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '13-4', field: 'receiver_country_code', headerName: 'Country code', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '13-5', field: 'receiver.contact', headerName: 'Contact person', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '13-6', field: 'receiver.contact_phone', headerName: 'Contact phone number', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      // { colId: '13-7', field: 'receiver.contact_email', headerName: 'Contact email', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '13-8', field: 'receiver.notes', headerName: 'Notes', editable: true, sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '14',
    headerName: 'Pickup',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '14-1', field: 'actual_pickup', headerName: 'Actual pickup', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '14-2', field: 'delivery_pickup', headerName: 'Pickup earliest', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '14-3', field: 'delivery_pickup', headerName: 'Pickup latest', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  {
    colId: '15',
    headerName: 'Delivery',
    marryChildren: true,
    openByDefault: true,
    children: [
      { colId: '15-1', field: 'actualDeliveryDate', headerName: 'Actual delivery', sortable: true, filter: true, resizable: true, columnGroupShow: 'everything else', hide: false },
      { colId: '15-2', field: 'delivery_earliest', headerName: 'Delivery earliest', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false },
      { colId: '15-3', field: 'delivery_latest', headerName: 'Delivery latest', sortable: true, filter: true, resizable: true, columnGroupShow: 'open', hide: false }
    ]
  },
  { colId: '16', field: 'transportMode', headerName: 'Transport mode', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '17', field: 'serviceLevel', headerName: 'Service level', sortable: true, filter: true, resizable: true, hide: false },
  { colId: '18', field: 'logisticsServiceProvider', headerName: 'LSP', sortable: true, filter: true, resizable: true, hide: false }
] as const;
