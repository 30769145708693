<form novalidate [formGroup]="form" fxLayout="row">
  <mat-card fxFlex="100" class="app-card" [ngClass]="{'inner': inner, 'owner': isOwner()}">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutGap="4px">
        <strong>{{form.value.lastModifiedByName || form.value.createdByEmail}}</strong>
        <div fxLayout="row" fxLayoutAlign="space-between">
        <span fxLayoutGap="2px" class="text-small">
          <span>{{form.value.lastModifiedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
          <span *ngIf="form.value.lastModifiedDate != form.value.createdDate" i18n>(Edited)</span>
          <span *ngIf="!form.value.lastModifiedDate && !form.value.createdDate">(Draft)</span>
        </span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="4px" *ngIf="inner">
        <button *ngIf="form.value.isOpen && (authGuard.hasAnyAuthority(superRoles) || isOwner())"mat-icon-button (click)="parent?.removeSubForm(index)">
          <mat-icon>delete_forever</mat-icon>
        </button>

        <button mat-icon-button [disabled]="!form.value.isOpen" (click)="form.patchValue({isImportant: !form.value.isImportant})">
          <mat-icon color="primary" *ngIf="form.value.isImportant" matTooltip="Is important">star</mat-icon>
          <mat-icon color="primary" *ngIf="!form.value.isImportant" matTooltip="Is not important">star_border</mat-icon>
        </button>

        <button mat-icon-button (click)="parent?.toggleIsOpen(form)" *ngIf="authGuard.hasAnyAuthority(superRoles) || isOwner()">
          <mat-icon *ngIf="!form.value.isOpen">mode_edit</mat-icon>
          <mat-icon *ngIf="form.value.isOpen">done</mat-icon>
        </button>
      </div>
    </mat-card-content>

    <mat-card-content fxLayout="row">
      <input type="hidden" formControlName="id"/>

      <mat-form-field *ngIf="form.value.isOpen" fxFlex="100">
        <textarea fxFlex="100" matInput matTextareaAutosize [matAutosizeMinRows]="3" [attr.disabled]="!form.value.isOpen" type="text" formControlName="note"></textarea>
      </mat-form-field>

      <span fxFlex="100" style="min-height: 62.5px" *ngIf="!form.value.isOpen">{{form.value.note}}</span>
    </mat-card-content>

    <mat-card-content *ngIf="!inner">
      <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
        <button mat-icon-button (click)="parent?.removeSubForm(index) && (authGuard.hasAnyAuthority(superRoles) || isOwner())" *ngIf="form.value.isOpen">
          <mat-icon>delete_forever</mat-icon>
        </button>

        <button mat-icon-button [disabled]="!form.value.isOpen" (click)="form.patchValue({isImportant: !form.value.isImportant})">
          <mat-icon color="primary" *ngIf="form.value.isImportant" matTooltip="Is important">star</mat-icon>
          <mat-icon color="primary" *ngIf="!form.value.isImportant" matTooltip="Is not important">star_border</mat-icon>
        </button>

        <button mat-icon-button (click)="parent?.toggleIsOpen(form)" *ngIf="authGuard.hasAnyAuthority(leafhillRoles) || isOwner()">
          <mat-icon *ngIf="!form.value.isOpen">mode_edit</mat-icon>
          <mat-icon *ngIf="form.value.isOpen">done</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
